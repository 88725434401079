import React, { useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Container } from '@mui/material';
// hooks
import useResponsive from './useResponsive';
// sections
import LoginForm from './LoginForm';
import loginStartImage from './login_start_1.png';
import loginSuccessfulImage from './login_start_1.png';
import logo from './RMPlogo1.png';
import { useEffect } from 'react';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme, backgroundImage }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
  background: `url(${backgroundImage}) no-repeat left center/cover`,
  transition: 'background-image 1s ease-in-out',
  height: '100vh',
  width: '100vw',
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 410,
  margin: 'auto',
  minHeight: '50vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const LogoStyle = styled('img')(({ theme }) => ({
  height: '25vh',
  width: '25vh',
  padding: '10px',
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  marginBottom: '30px',
}));

// ----------------------------------------------------------------------

export default function Login({ setLoginSuccess }) {
  const [backgroundImage, setBackgroundImage] = useState(loginStartImage);
  const [otpverify, setOtpverify] = useState(false);
  const smUp = useResponsive('up', 'sm');
  const mdUp = useResponsive('up', 'md');

  useEffect(()=>{
    if(otpverify){
      setBackgroundImage(loginSuccessfulImage);
      setTimeout(() => {
      }, 3000); // 3 second delay
    }
    else if(!otpverify){
      setBackgroundImage(loginStartImage);
    }
  },[otpverify])

  // Handle successful login
  // const handleLoginSuccess = () => {
  //   // setLoginSuccess(true);
  //   setTimeout(() => {
  //     setBackgroundImage(loginSuccessfulImage);
  //   }, 3000); // 3 second delay
  // };

  return (
    <RootStyle backgroundImage={backgroundImage}>
      <Container maxWidth="sm">
        <ContentStyle>
          <LogoStyle src={logo} alt="Logo" />
          <LoginForm setLoginSuccess={setLoginSuccess} setOtpverify = {setOtpverify} />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
