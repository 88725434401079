import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import { Modal } from 'react-bootstrap';
import UpdateForm from './UpdateForm';

export default function AccountLocations() {

  const [lgShow, setLgShow] = useState(false);


  return (
    <>

      <div className="row">

        <div className="row my-3 ">
          <div className="col-md-6">
            {/* <Typography className="mx-3" variant='h6' sx={{ pb: 3 }}>
             Location
            </Typography> */}
            <Typography className="mx-3" variant='h6' sx={{ pb: 3 }} style = {{marginTop:'5px', fontSize:'20px'}}>
           <b>Account Locations</b>
          </Typography>
          </div>
          <div className="col-md-6 d-flex justify-content-end"   >

            <button type="button" className="btn btn-danger" onClick={() => setLgShow(true)} style={{ height: "40px" }}>Help</button>
            <Modal
              size="lg"
              show={lgShow}
              onHide={() => setLgShow(false)}
              aria-labelledby="example-modal-sizes-title-lg"

            >

              <Modal.Header closeButton style={{ color: '#FFFFFF', backgroundColor: '#C62828' }}>
                <Modal.Title id="example-modal-sizes-title-lg" >
                  Instructions to Add/Edit Account Locations
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h5>Add New Location</h5>
                <ol>
                  <li>Select the account name from the dropdown {'>'} "maximum number of locations allowed" fields are displayed {'>'} Click on add new location.</li>
                  <li>Enter Location name {'>'} Select country {'>'} Select State {'>'} Select City {'>'} Select Location Type {'>'} Click on submit</li>
                  <li>New Location is added {'>'} Location ID is generated automatically (Six digits are company ID and then branch name ex: RCRL-YYZ)</li>
                </ol>

                <ol>
                  <li><strong>Note: </strong>Company ID and Maximum number of location allowed is not editable</li>
                  <li>If Maximum number of location limit is increased you will not be allowed to create new location - it will show a pop up
              that  You've exceeded the maximum allowed locations you can add. Please contact support@caledontechnologies.com to increase your limit.</li>
                </ol>

                <h5>View/Edit Company Location</h5>
                <ol>
                  <li>Account Location {'>'} Select Account name from the drop down {'>'} Existing Locations details are generated automatically {'>'} View/edit {'>'} Click on submit button</li>
                  <li>To activate/deactivate any Location click on activate/deactivate from drop down</li>
                </ol>
              </Modal.Body>
            </Modal>

          </div>
        </div>

        <div className="row">
          <div className="col-8 justify-content-end px-4">

          </div>
        </div>
        {/* <div className="col-4 d-flex justify-content-end px-5">
        
          
         
        </div> */}

      </div>


      <UpdateForm />



    </>
  );
}
