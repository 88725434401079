import React, { useState, useEffect, useRef } from 'react'
import { Alert } from "@mui/material";
import Select from "react-select";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import Swal from 'sweetalert2';
import { baseURL } from '../backend_url';
import axios from 'axios';


export default function UpdateForm({ accessType }) {

    const [newSippCode, setNewSippCode] = useState('')
    const [listOfExistingCarClasses, setListOfExistingCarClasses] = useState([])
    const [listOfExistingFleetSetUp, setListOfExistingFleetSetUp] = useState([])
    const [validationMsg, setValidationMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const [selectedSippCode, setSelectedSippCode] = useState("");
    const [loggedInUserDetail, setLoggedInUserDetail] = useState({});
    const [companyName, setCompanyName] = useState("");
    const [companyDetail, setcompanyDetail] = useState([{}]);
    const [company_id, setcompany_id] = useState("");
    const [locationId, setLocationId] = useState("");
    const [locationName, setLocationName] = useState("");
    const [locationDetail, setLocationDetail] = useState([{}]);

    const companyNameOptions = companyDetail.map((value) => (
        { value: value.company_name, label: value.company_name }
    ));

    const locationNameOptions = locationDetail.map((value) => (
        { value: value.location_name, label: value.location_name === undefined ? value.location_name : value.location_name + " - " + value.location_id.toString().trim().split("-")[1] }
    ));

    const sippCodeOptions = listOfExistingCarClasses.map((value) => (
        { value: value.SIPP_CODE, label: value.SIPP_CODE === undefined ? value.SIPP_CODE : value.SIPP_CODE }

        // { value: value.SIPP_CODE, label: SIPP_CODE.company_name }
    ));

    const handleInputChangeCompanyName = characterEntered => {
        setCompanyName(characterEntered.value);
    };
    const handleInputChangeLocationName = (characterEntered) => {
        setLocationName(characterEntered.value);
    };

    const handleInputChangeSippCode = (characterEntered) => {
        setSelectedSippCode(characterEntered.value);
    };
    
    const formatDate = (date) => {
        return [
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
            date.getFullYear(),
        ].join('-');
    }

    const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    }

    useEffect(() => {
        const loggedInUser = sessionStorage.getItem("user_token");
        const foundUser = JSON.parse(loggedInUser);
        setLoggedInUserDetail(foundUser);
        if (foundUser.role === 'SuperAdmin') {
            axios.get(baseURL + "/get_company_details_for_users")
              .then((res) => {
                setcompanyDetail(res.data);
              });
          }else{
            console.log("im heree")
            fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['FleetSetUp_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
              .then((res) => res.json())
              .then((data) => {
                setcompanyDetail(data);
              });
          }
          fetch(baseURL + `/get_existing_car_classes_standard`)
          .then((res) => res.json())
          .then((data) => {
              if (data.length > 0) {
                  setListOfExistingCarClasses(data)
              }
              else {
                  setListOfExistingCarClasses([])
              }
          })
    }, []);

    useEffect(() => {
        if (companyDetail && companyName) {
    
          let obj_company_detail = companyDetail.filter(
            (value) => value.company_name === companyName
          )[0];
          let temp_company_id = obj_company_detail._id;
          let company_type = obj_company_detail.account_type;
    
          setcompany_id(temp_company_id);
          setLocationName('')
          setLocationId('')
          setLocationDetail([{}])
          const requestOptions = {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          };
    
          fetch(baseURL + "/get_company_location/" + temp_company_id, requestOptions)
            .then((res) => res.json())
            .then((data) => {
    
              let allTemp = data.res;
              console.log("allTemp");
              console.log(allTemp);
              if (loggedInUserDetail.role === 'SuperAdmin') {
                if (allTemp.length > 0) {
                  setLocationDetail(allTemp);
                }
              }
              else if (company_type === 'Affiliate' && loggedInUserDetail.account_type.account_type === 'Corporate') {
    
                if (allTemp.length > 0) {
                  setLocationDetail(allTemp);
                }
              }
              else {
                axios.post(baseURL + "/get_individual_user_details", { 'user_email': loggedInUserDetail.userEmail })
                  .then((data) => {
                    console.log("data");
                    console.log(data);
                    if (allTemp.length > 0) {
                      allTemp = allTemp.filter((val) => val !== undefined && data.data[0].location_allocated[val.location_id]);
                      setLocationDetail(allTemp);
                    }
                  }
                  )
              }
            });
        }
      }, [companyName]);

    useEffect(() => {
        let temp_location_id = ''
        if (locationName && locationDetail) {
            temp_location_id = locationDetail.filter((loc) => (loc.location_name === locationName)).map((val) => val.location_id)
            setLocationId(temp_location_id);
        }
       

        setSelectedSippCode('')
        fetch(baseURL + `/get_existing_fleet_setup/${temp_location_id}`)
            .then((res) => res.json())
            .then((data) => {
                if (data.length > 0) {
                    setListOfExistingFleetSetUp(data)
                }
                else {
                    setListOfExistingFleetSetUp([])
                }
            })
     }, [locationName])
  

    // submit data
    const submitHandler = (e) => {
        e.preventDefault();
        setSuccessMsg("");
        setValidationMsg("");

        if (companyName === '') {
            Swal.fire('Please select Account Name to proceed!')
            return;
            // setValidationMsg('Please select Account Name to proceed!')
            // window.scrollTo(0, 0);
        }

        else if (locationName === '') {
            Swal.fire('Please select Location Name to proceed!')
            return;
            // setValidationMsg('Please select Location Name to proceed!')
            // window.scrollTo(0, 0);
        }

        else if (selectedSippCode === '') {
            Swal.fire('Please select SIPP code to proceed!')
            return;
            // setValidationMsg('Please select SIPP code to proceed!')
            // window.scrollTo(0, 0);
        }

        else {
            fetch(baseURL + '/save_fleet_setup', {
                method: 'POST', headers: { 'Content-type': 'application/json' },
                body: JSON.stringify({
                    "account_id": company_id.toString(),
                    "location_id": locationId.toString(),
                    "location_name": locationName.toString(),
                    "newSippCode": selectedSippCode,
                    "last_updated_by": loggedInUserDetail.userName,
                    "last_updated_date": new Date()
                })
            })
                .then(resp => resp.json())
                .then(data => {
                    console.log("data")
                    console.log(data)
                    if (data === "Duplicate") {
                        Swal.fire('SIPP CODE already present')
                    }
                    else {
                        // setSuccessMsg("Fleet SetUp saved successfully!")
                        Swal.fire('Fleet SetUp saved successfully')
                        setListOfExistingFleetSetUp(data);
                        setSelectedSippCode('')
                        // window.scrollTo(0, 0)
                    }
                })
                .catch(error => console.log(error))
        }
    }

    const deleteCarClasses = (i) => {
        Swal.fire({
            title: 'Delete this Car Class?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.isConfirmed) {
                let ID = ''
                listOfExistingFleetSetUp.map((arr1d, index) => {
                    if (i === index) {
                        ID = arr1d['_id']
                    }
                })

                fetch(baseURL + `/delete_car_class_fleet_setup/${ID + "___" + loggedInUserDetail.userName}`)
                    .then((res) => res.json())
                    .then((data) => {
                        if (data.length > 0) {
                            setListOfExistingFleetSetUp(data)
                        }
                        else {
                            setListOfExistingFleetSetUp([])
                        }
                    })
            }
            else if (result.isDenied) {
                Swal.fire('Car Class not deleted!');
            }
        })
    }


    return (
        <>
        <div className="row" style={{fontSize:'12px', color:'black', fontWeight:'600'}}>
            <div className="row pt-2" style={{ marginLeft: '50px' }}>
                <div className="col-md-2 mb-3">
                    <label htmlFor="company_name" className="form-label" style={{ marginTop: 5 }}>
                    Account Name
                    </label>
                </div>
                <div className="col-md-3 mb-3">
                    <Select
                    value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                    inputId="company_name"
                    name="company_name"
                    options={companyNameOptions}
                    onChange={handleInputChangeCompanyName}
                    />
                </div>
            </div>
            <div className="row pt-2" style={{ marginLeft: '50px' }}>
                <div className="col-md-2 mb-3">
                    <label htmlFor="locationName" className="form-label" style={{ marginTop: 5 }}>
                    Location Name
                    </label>
                </div>
                <div className="col-md-3 mb-3">
                    <Select
                    value={{ value: locationName, label: (locationName ? locationName : "Select Location Name") }}
                    inputId="locationName"
                    name="locationName"
                    options={locationNameOptions}
                    onChange={handleInputChangeLocationName}
                    />
                </div>
            </div>
            <form>
                <div className="row pt-2" style={{ marginLeft: '50px' }}>
                    <div className="col-md-10">
                        {
                            validationMsg ?
                                (<Alert severity="error">{validationMsg}!</Alert>) : ""
                        }

                        {successMsg ?
                            (
                                <div className="alert alert-success alert-dismissible fade show" role="alert">
                                    {successMsg}
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setSuccessMsg('')}></button>
                                </div>
                            )
                            : ""
                        }
                        <div className="row pt-2" >
                            <div className="col-md-2 mb-3">
                                <label htmlFor="new_sipp" className="form-label" style={{ marginTop: 5 }}>
                                    SIPP CODE
                                </label>
                            </div>
                            <div className="col-md-3 mb-3" style={{marginLeft:'43px', width: "31%" }}>
                                <Select
                                        value={{ value: selectedSippCode, label: (selectedSippCode ? selectedSippCode : "Select") }}
                                        inputId="sipp_code"
                                        name="sipp_code"
                                        options={sippCodeOptions}
                                        onChange={handleInputChangeSippCode}
                                    />
                            </div>
                        </div>
                    </div>
                </div>
                {accessType === 'View' ? "" :
                    <>
                    <div className="row pt-3" style={{ marginLeft: '70px' }}>
                        <div className="col-md-2"></div>
                        <div className="col-md-2" style={{marginLeft : '-1%'}}>
                            <button type="submit" style={{fontSize:'12px'}}  className="btn btn-success" onClick={submitHandler}>
                                Submit
                            </button>
                        </div>
                    </div>
                    </>
                }
            </form>
        </div>

            {listOfExistingFleetSetUp.length > 0 ?
                <>
                    <div className="row pt-2 mx-2">
                        <div className="col-md-11">
                            <hr style={{ height: '3px' }} />
                        </div>
                    </div>

                    <div className="row pt-2 mx-2" style={{ marginLeft: '40px', fontSize:'12px', color:'black', fontWeight:'600'}}>
                        <div className="col-md-8">
                            <h5 className=" mb-1" style={{ marginLeft: '10px' }}>Existing Fleet :</h5>
                            <table className="table table-hover" style={{ marginLeft: '40px', marginTop: '15px' }}>
                                <thead>
                                    <tr>
                                        <th style={{backgroundColor:'orange'}} scope="col">SIPP Code</th>
                                        <th style={{backgroundColor:'orange'}} scope="col">Updated By</th>
                                        <th style={{backgroundColor:'orange'}} scope="col">Updated On</th>
                                        <th style={{backgroundColor:'orange'}} scope="col">Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listOfExistingFleetSetUp.map((val, i) => (
                                            <tr>
                                                <td>{val.SIPP_CODE}</td>
                                                <td>{val.last_updated_by}</td>
                                                <td>{formatDate(new Date(val.last_updated_date))}</td>
                                                <td><button className="btn success" disabled={accessType === "View"}  onClick={(e) => deleteCarClasses(i)}> <DeleteForeverIcon /></button></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </> : ''
            }
        </>
    );
}
