import React, { useState, useEffect } from 'react'
import { Button, Typography } from '@mui/material';
import { Modal } from 'react-bootstrap'
import UpdateForm from './UpdateForm'
import NewForm from './NewForm'

export default function User() {

  const [createNewFlag, setCreateNewFlag] = useState(false)
  const [lgShow, setLgShow] = useState(false);
  const [accessType, setAccessType] = useState('')

  //API call
  useEffect(() => {

    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);

    if (foundUser.role === 'SuperAdmin') {

      setAccessType('Edit');

    }
    else {

      setAccessType(foundUser.access['User_Access Specifier']);
    }

  }, []);


  return (
    <>

      <div className="row">

        <div className="row my-3 ">
          <div className="col-md-6">
            {/* <Typography className="mx-3" variant='h6' sx={{ pb: 3 }}>
              User / {accessType === 'View' ? "View Only Mode" : createNewFlag ? ('Add') : ('Edit')}
            </Typography> */}

            <Typography className="mx-3" variant='h6' sx={{ pb: 3 }} style = {{marginTop:'5px', fontSize:'25px'}}>
             <b> User / {accessType === 'View' ? "View Only Mode" : createNewFlag ? ('Add') : ('Edit')} </b>
            </Typography>
          </div>
          <div className="col-md-6 d-flex justify-content-end" >

            <button type="button" className="btn btn-danger" onClick={() => setLgShow(true)} style={{ height: "40px" }}  >Help</button>
            <Modal
              size="lg"
              show={lgShow}
              onHide={() => setLgShow(false)}
              aria-labelledby="example-modal-sizes-title-lg"

            >
              <Modal.Header closeButton style={{ color: '#FFFFFF', backgroundColor: '#C62828' }}>
                <Modal.Title id="example-modal-sizes-title-lg" >
                  Instructions to Add/Edit User
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h5>To Create a New user -</h5>
                <ol>
                  <li>Select the account name from the dropdown > Max Number of Users and Existing Number of Users will reflect automatically once account name is selected.  </li>
                  <li>Enter User Email,Contact No.,User Name, role to be assigned and create a password in password field or you can click on Generate Password button so that random password will be created
                  </li>
                  <li>Enter all the mandatory user details > Submit > New user with unique ID has been created.
                  </li>
                </ol>

                <h5>View/Edit Existing user - </h5>
                <ol>
                  <li>To view/edit existing user, select Account name and User Email from drop down > view/edit the user details > submit.
</li>
                  <li><strong>Note:</strong> Company name and user ID fields are non-editable</li>
                </ol>

                <h5>To deactivate the User</h5>
                <ol>
                  <li>User Setup {'>'} Select company name and User Email from drop down.</li>
                  <li>Go to the edit user ID page.</li>
                  <li>Click on deactivate</li>
                </ol>
              </Modal.Body>
            </Modal>

          </div>
        </div>

        <div className="row">
          <div className="col-8 justify-content-end px-4">
            {accessType === 'View' ? "" : createNewFlag ? (
              <Button variant="contained" onClick={() => setCreateNewFlag(false)} >Edit User</Button>
            ) : (
              <Button variant="contained" onClick={() => setCreateNewFlag(true)} >+ New User</Button>
            )

            }
          </div>
        </div>
        {/* <div className="col-4 d-flex justify-content-end px-5">
        
         
        </div> */}

      </div>

      {accessType === 'View' ? <UpdateForm accessType={accessType} /> : createNewFlag ? (<NewForm />) : <UpdateForm />}

    </>
  );
}
