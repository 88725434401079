import React, { useState, useEffect, useRef } from 'react'
import { Alert } from "@mui/material";
import Select from "react-select";
import Swal from 'sweetalert2';
import { baseURL } from '../backend_url';
import axios from 'axios';


export default function UpdateForm({ accessType }) {

    const [companyDetail, setcompanyDetail] = useState([{}]);
    const [companyName, setCompanyName] = useState("");
    const [company_id, setcompany_id] = useState("");
    const [columnData, setColumnData] = useState([]);
    const [recordUpdateFlag, setRecordUpdateFlag] = useState(false);
    const [automationDetails, setAutomationDetails] = useState([]);

    const [shoppingHorizonsB, setShoppingHorizonsB] = useState([
        { start: 0, end: 6 },
        { start: 7, end: 14 },
        { start: 15, end: 28 },
        { start: 29, end: 45 },
        { start: 46, end: 60 },
        { start: 61, end: 90 },
        { start: 91, end: 120 },
        { start: 121, end: 180 },
    ]);
    const [frequencyPermittedB, setFrequencyPermittedB] = useState(['', '', '', '', '', '', '', '']);
    const handleChangeShoppingHorizonB = (index, start, end) => {
        const updatedShoppingHorizonsB = [...shoppingHorizonsB];
        updatedShoppingHorizonsB[index] = { start, end };
        setShoppingHorizonsB(updatedShoppingHorizonsB);
    };
    
    const handleChangeFrequencyPermittedB = (index, value) => {
        setFrequencyPermittedB((prevFrequencyPermittedB) => {
        const updatedFrequencyPermittedB = [...prevFrequencyPermittedB];
        updatedFrequencyPermittedB[index] = value;
        return updatedFrequencyPermittedB;
        });
    };

    const [shoppingHorizonsA, setShoppingHorizonsA] = useState([
        { start: 0, end: 6 },
        { start: 7, end: 14 },
        { start: 15, end: 28 },
        { start: 29, end: 45 },
        { start: 46, end: 60 },
        { start: 61, end: 90 },
        { start: 91, end: 120 },
        { start: 121, end: 180 },
    ]);
    const [frequencyPermittedA, setFrequencyPermittedA] = useState(['', '', '', '', '', '', '', '']);
    const handleChangeShoppingHorizonA = (index, start, end) => {
        const updatedShoppingHorizonsA = [...shoppingHorizonsA];
        updatedShoppingHorizonsA[index] = { start, end };
        setShoppingHorizonsA(updatedShoppingHorizonsA);
    };
    
    const handleChangeFrequencyPermittedA = (index, value) => {
        setFrequencyPermittedA((prevFrequencyPermittedA) => {
        const updatedFrequencyPermittedA = [...prevFrequencyPermittedA];
        updatedFrequencyPermittedA[index] = value;
        return updatedFrequencyPermittedA;
        });
    };

    const [shoppingHorizonsP, setShoppingHorizonsP] = useState([
        { start: 0, end: 6 },
        { start: 7, end: 14 },
        { start: 15, end: 28 },
        { start: 29, end: 45 },
        { start: 46, end: 60 },
        { start: 61, end: 90 },
        { start: 91, end: 120 },
        { start: 121, end: 180 },
    ]);
    const [frequencyPermittedP, setFrequencyPermittedP] = useState(['', '', '', '', '', '', '', '']);
    const handleChangeShoppingHorizonP = (index, start, end) => {
        const startInt = parseInt(start, 10);
        const endInt = parseInt(end, 10);
        const updatedShoppingHorizonsP = [...shoppingHorizonsP];
        updatedShoppingHorizonsP[index] = { start: startInt, end: endInt };
        // updatedShoppingHorizonsP[index] = { startInt, endInt };
        setShoppingHorizonsP(updatedShoppingHorizonsP);
    };
    
    const handleChangeFrequencyPermittedP = (index, value) => {
        setFrequencyPermittedP((prevFrequencyPermittedP) => {
        const updatedFrequencyPermittedP = [...prevFrequencyPermittedP];
        updatedFrequencyPermittedP[index] = value;
        return updatedFrequencyPermittedP;
        });
    };
    
      
        // const handleRemoveShoppingHorizon = (index) => {
        //   setShoppingHorizons((prevShoppingHorizons) =>
        //     prevShoppingHorizons.filter((_, i) => i !== index)
        //   );
        //   setFrequencyPermitted((prevFrequencyPermitted) =>
        //     prevFrequencyPermitted.filter((_, i) => i !== index)
        //   );
        // };

    const divStyle = {
        width: '100%', 
        height: '2px',
        border: '1px dotted #a7bceb',
        margin: '10px 0',
      };


    const [locationDetail, setLocationDetail] = useState([{}]);
    const [locationName, setLocationName] = useState("");
    const [locationId, setLocationId] = useState("");
    const [table, setTable] = useState([])
    const [isDisabled, setIsDisabled] = useState(true);
    
    const [fromDate, setFromDate] = useState(new Date())
    const [toDate, setToDate] = useState(new Date())

    const [validationMsg, setValidationMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const [loggedInUserDetail, setLoggedInUserDetail] = useState({});


    
    const [automationTypeB, setAutomationTypeB] = useState({ "User-Initiated": false, "System-Driven": false  })
    const [rateSourceB, setRateSourceB] = useState({ "Expedia": false, "PriceLine": false  })
    const [automationLorsB, setAutomationLorsB] = useState({ "1": false, "2": false, '3': false, '4': false, '5': false, '6': false, 'Weekly': false, '14days': false, '21days': false, 'Monthly': false })
    const [pricingStrategyB, setPricingStrategyB] = useState({ "Competes with same car class": false, "Competes with different car class": false, "Competes with 3 car classes": false, "Lowest rental across all car classes": false  })
    
    const [automationTypeA, setAutomationTypeA] = useState({ "User-Initiated": false, "System-Driven": false  })
    const [rateSourceA, setRateSourceA] = useState({ "Expedia": false, "PriceLine": false  })
    const [automationLorsA, setAutomationLorsA] = useState({ "1": false, "2": false, '3': false, '4': false, '5': false, '6': false, 'Weekly': false, '14days': false, '21days': false, 'Monthly': false })
    const [pricingStrategyA, setPricingStrategyA] = useState({ "Competes with same car class": false, "Competes with different car class": false, "Competes with 3 car classes": false, "Lowest rental across all car classes": false  })

    const [automationTypeP, setAutomationTypeP] = useState({ "User-Initiated": false, "System-Driven": false  })
    const [rateSourceP, setRateSourceP] = useState({ "Expedia": false, "PriceLine": false  })
    const [automationLorsP, setAutomationLorsP] = useState({ "1": false, "2": false, '3': false, '4': false, '5': false, '6': false, 'Weekly': false, '14days': false, '21days': false, 'Monthly': false })
    const [pricingStrategyP, setPricingStrategyP] = useState({ "Competes with same car class": false, "Competes with different car class": false, "Competes with 3 car classes": false, "Lowest rental across all car classes": false  })
    
    const handleAutomationPlansSubmit = (e) => {
        e.preventDefault();
        if (companyName === "") {
            Swal.fire('Please select Account Name');
            return;
        }
        if (locationName === "") {
            Swal.fire('Please select a Location to proceed');
            return;
        }
        const hasTrueValueForAutomationTypeB = Object.values(automationTypeB).some((value) => value === true);
        if (!hasTrueValueForAutomationTypeB) {
            Swal.fire("Please check atleast one Automation Type - Basic");
            return;
        }
        const hasTrueValueForAutomationLorsB = Object.values(automationLorsB).some((value) => value === true);
        if (!hasTrueValueForAutomationLorsB) {
            Swal.fire("Please check atleast one LOR's Type - Basic");
            return;
        }
        const hasTrueValueForPricingStrategyB = Object.values(pricingStrategyB).some((value) => value === true);
        if (!hasTrueValueForPricingStrategyB) {
            Swal.fire("Please check atleast one Pricing Strategy Type - Basic");
            return;
        }
        const invalidValuesB = [];
        shoppingHorizonsB.forEach((obj, index) => {
            if (
                isNaN(obj.start) ||
                isNaN(obj.end) ||
                obj.start === '' ||
                obj.end === '' ||
                typeof obj.start !== 'number' ||
                typeof obj.end !== 'number'
            ) {
                invalidValuesB.push(index);
            }
        });
        const invalidStartGreaterB = shoppingHorizonsB.reduce((acc, obj, index) => {
            if (obj.start >= obj.end) {
              acc.push(index);
            }
            return acc;
        }, []);

        if (invalidStartGreaterB.length > 0) {
            const incrementedArrayB = invalidStartGreaterB.map(value => value + 1);
            const invalidStartGreaterStringB = incrementedArrayB.join(', ');
            Swal.fire('Start values greater than or equal to End values found at row: ' + invalidStartGreaterStringB + ' - on Shopping Horizons Basic');
            return;
        }  
        if (invalidValuesB.length > 0 ) {
            const indicesStringArrayB = invalidValuesB.map(value => value + 1);
            const indicesStringB = indicesStringArrayB.join(', '); 
            Swal.fire('Invalid values found for (Start/End) at indices : ' + indicesStringB + ' - on Shopping Horizons Basic');
            return;
        }
        const emptyIndicesB = frequencyPermittedB.reduce((acc, currentValue, index) => {
            if (currentValue.trim() === '') {
              acc.push(index);
            }
            return acc;
          }, []);
        if (emptyIndicesB.length > 0) {
            const indicesStringsB = emptyIndicesB.map(value => value + 1);
            const indicesStringB = indicesStringsB.join(', '); 
            Swal.fire('Please select Frequency Permitted for rows : ' + indicesStringB + ' - on Shopping Horizons Basic');
            return;
        }
        const hasTrueValueForAutomationTypeA = Object.values(automationTypeA).some((value) => value === true);
        if (!hasTrueValueForAutomationTypeA) {
            Swal.fire("Please check atleast one Automation Type - Advanced");
            return;
        }
        const hasTrueValueForAutomationLorsA = Object.values(automationLorsA).some((value) => value === true);
        if (!hasTrueValueForAutomationLorsA) {
            Swal.fire("Please check atleast one LOR's Type - Advanced");
            return;
        }
        const hasTrueValueForPricingStrategyA = Object.values(pricingStrategyA).some((value) => value === true);
        if (!hasTrueValueForPricingStrategyA) {
            Swal.fire("Please check atleast one Pricing Strategy Type - Advanced");
            return;
        }
        const invalidValuesA = [];
        shoppingHorizonsA.forEach((obj, index) => {
            if (
                isNaN(obj.start) ||
                isNaN(obj.end) ||
                obj.start === '' ||
                obj.end === '' ||
                typeof obj.start !== 'number' ||
                typeof obj.end !== 'number'
            ) {
                invalidValuesA.push(index);
            }
        });
        const invalidStartGreaterA = shoppingHorizonsA.reduce((acc, obj, index) => {
            if (obj.start >= obj.end) {
              acc.push(index);
            }
            return acc;
        }, []);

        if (invalidStartGreaterA.length > 0) {
            const incrementedArrayA = invalidStartGreaterA.map(value => value + 1);
            const invalidStartGreaterStringA = incrementedArrayA.join(', ');
            Swal.fire('Start values greater than or equal to End values found at row: ' + invalidStartGreaterStringA + ' - on Shopping Horizons Advanced');
            return;
        }  
        if (invalidValuesA.length > 0 ) {
            const indicesStringArrayA = invalidValuesA.map(value => value + 1);
            const indicesStringA = indicesStringArrayA.join(', '); 
            Swal.fire('Invalid values found for (Start/End) at indices : ' + indicesStringA + ' - on Shopping Horizons Advanced');
            return;
        }
        const emptyIndicesA = frequencyPermittedA.reduce((acc, currentValue, index) => {
            if (currentValue.trim() === '') {
              acc.push(index);
            }
            return acc;
          }, []);
          
        if (emptyIndicesA.length > 0) {
            const indicesStringsA = emptyIndicesA.map(value => value + 1);
            const indicesStringA = indicesStringsA.join(', '); 
            Swal.fire('Please select Frequency Permitted for rows : ' + indicesStringA + ' - on Shopping Horizons Advanced');
            return;
        }
        const hasTrueValueForAutomationTypeP = Object.values(automationTypeP).some((value) => value === true);
        if (!hasTrueValueForAutomationTypeP) {
            Swal.fire("Please check atleast one Automation Type - Premium");
            return;
        }
        const hasTrueValueForAutomationLorsP = Object.values(automationLorsP).some((value) => value === true);
        if (!hasTrueValueForAutomationLorsP) {
            Swal.fire("Please check atleast one LOR's Type - Premium");
            return;
        }
        const hasTrueValueForPricingStrategyP = Object.values(pricingStrategyP).some((value) => value === true);
        if (!hasTrueValueForPricingStrategyP) {
            Swal.fire("Please check atleast one Pricing Strategy Type - Premium");
            return;
        }
        const invalidValuesP = [];
        shoppingHorizonsP.forEach((obj, index) => {
            if (
                isNaN(obj.start) ||
                isNaN(obj.end) ||
                obj.start === '' ||
                obj.end === '' ||
                typeof obj.start !== 'number' ||
                typeof obj.end !== 'number'
            ) {
                invalidValuesP.push(index);
            }
        });
        const invalidStartGreaterP = shoppingHorizonsP.reduce((acc, obj, index) => {
            if (obj.start >= obj.end) {
              acc.push(index);
            }
            return acc;
        }, []);
        if (invalidStartGreaterP.length > 0) {
            const incrementedArrayP = invalidStartGreaterP.map(value => value + 1);
            const invalidStartGreaterStringP = incrementedArrayP.join(', ');
            Swal.fire('Start values greater than or equal to End values found at row: ' + invalidStartGreaterStringP + ' - on Shopping Horizons Premium');
            return;
        }  
        if (invalidValuesP.length > 0 ) {
            const indicesStringArray = invalidValuesP.map(value => value + 1);
            const indicesStringP = indicesStringArray.join(', '); 
            Swal.fire('Invalid values found for (Start/End) at indices : ' + indicesStringP + ' - on Shopping Horizons Premium');
            return;
        }
        const emptyIndicesP = frequencyPermittedP.reduce((acc, currentValue, index) => {
            if (currentValue.trim() === '') {
              acc.push(index);
            }
            return acc;
        }, []); 
        if (emptyIndicesP.length > 0) {
            const indicesStringsP = emptyIndicesP.map(value => value + 1);
            const indicesStringP = indicesStringsP.join(', '); 
            Swal.fire('Please select Frequency Permitted for rows : ' + indicesStringP + ' - on Shopping Horizons Premium');
            return;
        }
        const body_payload = 
        {
            location_name: locationName,
            account_id: company_id.toString(),
            location_id: locationId.toString(),
            automationTypeB: automationTypeB,
            automationLorsB: automationLorsB,
            pricingStrategyB: pricingStrategyB,
            shoppingHorizonsB: shoppingHorizonsB,
            frequencyPermittedB: frequencyPermittedB,
            automationTypeA: automationTypeA,
            automationLorsA: automationLorsA,
            pricingStrategyA: pricingStrategyA,
            shoppingHorizonsA: shoppingHorizonsA,
            frequencyPermittedA: frequencyPermittedA,
            automationTypeP: automationTypeP,
            automationLorsP: automationLorsP,
            pricingStrategyP: pricingStrategyP,
            shoppingHorizonsP: shoppingHorizonsP,
            frequencyPermittedP: frequencyPermittedP,
            from_date:new Date(fromDate).toDateString(),
            to_date: new Date(toDate).toDateString(),
            created_by: loggedInUserDetail.userEmail,
            updated_by: loggedInUserDetail.userEmail,
            created_date: new Date(),
            updated_date: new Date()
        };
        console.log(body_payload)
        return "jhgfd"
        axios
          .post(baseURL + "/save_automation_plans_info", body_payload)
          .then((res) => {
            Swal.fire({
              position: "middle",
              icon: "success",
              title: "Automation Plan Added Successfully",
              showConfirmButton: false,
              timer: 3000
            });
          }).catch((e) => { });
    };

    const handleSelectAllTypeB = () => {
        const areAllSelected = Object.values(automationTypeB).every((value) => value);
        const updatedSelectionB = {};
        for (const key in automationTypeB) {
            updatedSelectionB[key] = !areAllSelected;
        }
        setAutomationTypeB(updatedSelectionB);
    };

    const handleAutomationTypeB = (autoTypesB) => {
        setAutomationTypeB({
            ...automationTypeB,
            [autoTypesB]: !automationTypeB[autoTypesB], // Toggle the value for the clicked number
        });
    };

    const handleRateSourceB = (sourceB) => {
        setRateSourceB({
            ...rateSourceB,
            [sourceB]: !rateSourceB[sourceB], // Toggle the value for the clicked number
        });
    };

    const handleSelectAllTypeA = () => {
        const areAllSelected = Object.values(automationTypeA).every((value) => value);
        const updatedSelectionA = {};
        for (const key in automationTypeA) {
            updatedSelectionA[key] = !areAllSelected;
        }
        setAutomationTypeA(updatedSelectionA);
    };

    const handleAutomationTypeA = (autoTypesA) => {
        setAutomationTypeA({
            ...automationTypeA,
            [autoTypesA]: !automationTypeA[autoTypesA], // Toggle the value for the clicked number
        });
    };

    const handleSelectAllTypeP = () => {
        const areAllSelected = Object.values(automationTypeP).every((value) => value);
        const updatedSelectionP = {};
        for (const key in automationTypeP) {
            updatedSelectionP[key] = !areAllSelected;
        }
        setAutomationTypeP(updatedSelectionP);
    };

    const handleAutomationTypeP = (autoTypesP) => {
        setAutomationTypeP({
            ...automationTypeP,
            [autoTypesP]: !automationTypeP[autoTypesP], 
        });
    }; 

    const handleSelectAllLorB = () => {
        const areAllSelectedB = Object.values(automationLorsB).every((value) => value);
        const updatedSelectionB = {};
        for (const key in automationLorsB) {
            updatedSelectionB[key] = !areAllSelectedB;
        }
        setAutomationLorsB(updatedSelectionB);
    };

    const handleSelectAllRateSourceB = () => {
        const areAllSelectedB = Object.values(rateSourceB).every((value) => value);
        const updatedSelectionB = {};
        for (const key in rateSourceB) {
            updatedSelectionB[key] = !areAllSelectedB;
        }
        setRateSourceB(updatedSelectionB);
    };

    const handleAutomationLorB = (autoLorsB) => {
        setAutomationLorsB({
            ...automationLorsB,
            [autoLorsB]: !automationLorsB[autoLorsB],
        });
    };

    const handleSelectAllLorA = () => {
        const areAllSelectedA = Object.values(automationLorsA).every((value) => value);
        const updatedSelectionA = {};
        for (const key in automationLorsA) {
            updatedSelectionA[key] = !areAllSelectedA;
        }
        setAutomationLorsA(updatedSelectionA);
    };

    const handleAutomationLorA = (autoLorsA) => {
        setAutomationLorsA({
            ...automationLorsA,
            [autoLorsA]: !automationLorsA[autoLorsA], 
        });
    };

    const handleSelectAllLorP = () => {
        const areAllSelectedP = Object.values(automationLorsP).every((value) => value);
        const updatedSelectionP = {};
        for (const key in automationLorsP) {
            updatedSelectionP[key] = !areAllSelectedP;
        }
        setAutomationLorsP(updatedSelectionP);
    };

    const handleSelectAllRateSourceA = () => {
        const areAllSelectedA = Object.values(rateSourceA).every((value) => value);
        const updatedSelectionA = {};
        for (const key in rateSourceA) {
            updatedSelectionA[key] = !areAllSelectedA;
        }
        setRateSourceA(updatedSelectionA);
    };

    const handleRateSourceA = (sourceA) => {
        setRateSourceA({
            ...rateSourceA,
            [sourceA]: !rateSourceA[sourceA], // Toggle the value for the clicked number
        });
    };

    const handleAutomationLorP = (autoLorsP) => {
        setAutomationLorsP({
            ...automationLorsP,
            [autoLorsP]: !automationLorsP[autoLorsP], 
        });
    };

    const handleSelectAllStrategyB = () => {
        const areAllSelected = Object.values(pricingStrategyB).every((value) => value);
        const updatedStrategyB = {};
        for (const key in pricingStrategyB) {
            updatedStrategyB[key] = !areAllSelected;
        }
        setPricingStrategyB(updatedStrategyB);
    };

    const handleAutomationStrategyB = (autoPricingB) => {
        setPricingStrategyB({
            ...pricingStrategyB,
            [autoPricingB]: !pricingStrategyB[autoPricingB],
        });
    };

    const handleSelectAllStrategyA = () => {
        const areAllSelected = Object.values(pricingStrategyA).every((value) => value);
        const updatedStrategyA = {};
        for (const key in pricingStrategyA) {
            updatedStrategyA[key] = !areAllSelected;
        }
        setPricingStrategyA(updatedStrategyA);
    };


    const handleSelectAllRateSourceP = () => {
        const areAllSelectedP = Object.values(rateSourceP).every((value) => value);
        const updatedSelectionP = {};
        for (const key in rateSourceP) {
            updatedSelectionP[key] = !areAllSelectedP;
        }
        setRateSourceP(updatedSelectionP);
    };

    const handleRateSourceP = (sourceP) => {
        setRateSourceP({
            ...rateSourceP,
            [sourceP]: !rateSourceP[sourceP], // Toggle the value for the clicked number
        });
    };

    const handleAutomationStrategyA = (autoPricingA) => {
        setPricingStrategyA({
            ...pricingStrategyA,
            [autoPricingA]: !pricingStrategyA[autoPricingA], 
        });
    };

    const handleSelectAllStrategyP = () => {
        const areAllSelected = Object.values(pricingStrategyP).every((value) => value);
        const updatedStrategyP = {};
        for (const key in pricingStrategyP) {
            updatedStrategyP[key] = !areAllSelected;
        }
        setPricingStrategyP(updatedStrategyP);
    };

    const handleAutomationStrategyP = (autoPricingP) => {
        setPricingStrategyP({
            ...pricingStrategyP,
            [autoPricingP]: !pricingStrategyP[autoPricingP], 
        });
    };

    useEffect(() => {
        const loggedInUser = sessionStorage.getItem("user_token");
        const foundUser = JSON.parse(loggedInUser);
        setLoggedInUserDetail(foundUser);
        if (foundUser.role === 'SuperAdmin') {
            // setRateCodeSelectionAccess("Yes");
            // setExcelUploadAccess("Yes");
            // setDaysApplicableAccess("Yes");
            // setCopyRateAccess("Yes");
        }
        else {
            // setRateCodeSelectionAccess(foundUser.access['RateUpdate_Rate Code Selection']);
            // setExcelUploadAccess(foundUser.access['RateUpdate_Excel Upload']);
            // setDaysApplicableAccess(foundUser.access['RateUpdate_Days Applicable Selection']);
            // setCopyRateAccess(foundUser.access['RateUpdate_Copy Rates']);
        }
        if (foundUser.role === 'SuperAdmin') {
            axios.get(baseURL + "/get_company_details_for_users")
                .then((res) => {
                    setcompanyDetail(res.data);
                });
        }
        else {
            fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['AutomationPlan_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
                .then((res) => res.json())
                .then((data) => {
                    setcompanyDetail(data);
                });
        }
    }, []);

    useEffect(() => {
        if (companyDetail && companyName) {
            let obj_company_detail = companyDetail.filter(
                (value) => value.company_name === companyName
            )[0];
            let temp_company_id = obj_company_detail._id;
            let company_type = obj_company_detail.account_type;
            setcompany_id(temp_company_id);
            setLocationName('')
            setLocationId('')
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            };

            fetch(baseURL + "/get_company_location/" + temp_company_id, requestOptions)
                .then((res) => res.json())
                .then((data) => {

                    let allTemp = data.res;
                    if (loggedInUserDetail.role === 'SuperAdmin') {
                        if (allTemp.length > 0) {
                            setLocationDetail(allTemp);
                        }
                    }
                    else if (company_type === 'Affiliate' && loggedInUserDetail.account_type.account_type === 'Corporate') {

                        if (allTemp.length > 0) {
                            setLocationDetail(allTemp);
                        }
                    }
                    else {
                        axios.post(baseURL + "/get_individual_user_details", { 'user_email': loggedInUserDetail.userEmail })
                            .then((data) => {
                                if (allTemp.length > 0) {
                                    allTemp = allTemp.filter((val) => val !== undefined && data.data[0].location_allocated[val.location_id]);
                                    setLocationDetail(allTemp);
                                }
                            }
                            )
                    }
                });
        }
    }, [companyName]);


    useEffect(() => {
        let temp_location_id = ''
        if (locationName && locationDetail) {
            temp_location_id = locationDetail.filter((loc) => (loc.location_name === locationName)).map((val) => val.location_id)
            setLocationId(temp_location_id);
            setFrequencyPermittedB([]);
            setFrequencyPermittedP([]);
            setFrequencyPermittedA([]);
            setAutomationTypeB({ "User-Initiated": false, "System-Driven": false  })
            setAutomationLorsB({ "1": false, "2": false, '3': false, '4': false, '5': false, '6': false, 'Weekly': false, '14days': false, '21days': false, 'Monthly': false })
            setPricingStrategyB({ "Competes with same car class": false, "Competes with different car class": false, "Competes with 3 car classes": false, "Lowest rental across all car classes": false  })
            setAutomationTypeA({ "User-Initiated": false, "System-Driven": false  })
            setAutomationLorsA({ "1": false, "2": false, '3': false, '4': false, '5': false, '6': false, 'Weekly': false, '14days': false, '21days': false, 'Monthly': false })
            setPricingStrategyA({ "Competes with same car class": false, "Competes with different car class": false, "Competes with 3 car classes": false, "Lowest rental across all car classes": false  })
            setAutomationTypeP({ "User-Initiated": false, "System-Driven": false  })
            setAutomationLorsP({ "1": false, "2": false, '3': false, '4': false, '5': false, '6': false, 'Weekly': false, '14days': false, '21days': false, 'Monthly': false })
            setPricingStrategyP({ "Competes with same car class": false, "Competes with different car class": false, "Competes with 3 car classes": false, "Lowest rental across all car classes": false  })
            axios
            .get(baseURL + "/get_automation_plans_info/" + locationName)
            .then((res) => {
                if(res.data !== null){
                    setFrequencyPermittedB(res.data.frequencyPermittedB);
                    setFrequencyPermittedA(res.data.frequencyPermittedA);
                    setFrequencyPermittedP(res.data.frequencyPermittedP);
                    setAutomationTypeB(res.data.automationTypeB);
                    setAutomationTypeA(res.data.automationTypeA);
                    setAutomationTypeP(res.data.automationTypeP);
                    setAutomationLorsB(res.data.automationLorsB);
                    setAutomationLorsA(res.data.automationLorsA);
                    setAutomationLorsP(res.data.automationLorsP);
                    setPricingStrategyB(res.data.pricingStrategyB);
                    setPricingStrategyA(res.data.pricingStrategyA);
                    setPricingStrategyP(res.data.pricingStrategyP);
                }
            });
    }
    }, [locationName])

    //Option for company name
    const companyNameOptions = companyDetail.map((value) => (
        { value: value.company_name, label: value.company_name }
    ));

    const handleInputChangeCompanyName = characterEntered => {
        setCompanyName(characterEntered.value);
    };

    //Option for Location name
    const locationNameOptions = locationDetail.map((value) => (
        { value: value.location_name, label: value.location_name === undefined ? value.location_name : value.location_name + " - " + value.location_id.toString().trim().split("-")[1] }
    ));

    const handleInputChangeLocationName = characterEntered => {
        setLocationName(characterEntered.value);
    };

    return (
        <>
            <div className="row pt-2">
                <div className="col-12 mx-3">
                    <span style={{ color: '#1E1E1E', fontSize: '21px' }}>Select Location for Automation Plan:</span>
                </div>
            </div>
            <div className="row pt-5 mx-2" style={{fontSize:'12px', color:'black', fontWeight:'600'}}>
                <div className="col-md-10">
                    {
                        validationMsg ?
                            (<Alert severity="error">{validationMsg}!</Alert>) : ""
                    }
                    {
                        successMsg ?
                            (
                                <div className="alert alert-success alert-dismissible fade show" role="alert">
                                    {successMsg}
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setSuccessMsg('')}></button>
                                </div>
                            )
                            : ""
                    }
                    <div className="row pt-3">
                        <div className="col-md-2 mb-3">
                            <label htmlFor="company_name" className="form-label" style={{ marginTop: 5 }}>
                               Account Name 
                            </label>
                        </div>
                        <div className="col-md-4 mb-3">
                            <Select
                                value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                                inputId="company_name"
                                name="company_name"
                                options={companyNameOptions}
                                onChange={handleInputChangeCompanyName}
                            />
                        </div>
                    </div>
                    <div className="row pt-2">
                        <div className="col-md-2 mb-3">
                            <label htmlFor="locationName" className="form-label" style={{ marginTop: 5 }}>
                                Location Name
                            </label>
                        </div>
                        <div className="col-md-4 mb-3">
                            <Select
                                value={{ value: locationName, label: (locationName ? locationName : "Select Location Name") }}
                                inputId="locationName"
                                name="locationName"
                                options={locationNameOptions}
                                onChange={handleInputChangeLocationName}
                            />
                        </div>
                        <div className="col-md-1 mb-3"></div>
                        <div className="col-md-2 mb-3">
                        </div>
                        <div className="col-md-2 mb-3">
                        </div>
                    </div>
                </div>
            </div>
            <div className="row pt-2 mx-2" style={{fontSize:'12px', color:'black', fontWeight:'600'}}>
                <div className="col-md-12">
                    <div className="row">
                        { accessType === 'View' ? "" :
                        <>
                        <div style={divStyle}>
                            {/* Content */}
                        </div>
                        <div className="row pt-2">
                            <b>Basic</b>
                        </div>
            
                        <div className="row pt-6" style={{marginLeft:'60px', marginTop: 10}}>
                            <div className="col-md-3 mb-4">
                                <label htmlFor="applyRatesToDays" className="form-label" style={{ marginTop: 5,  }}>
                                    Automation Type   -
                                </label>
                            </div>
                            <div className="col-md-6 mb-3" style={{marginLeft:'-90px'}}>
                                <table className="table table-borderless text-left">
                                    <tbody className="text-left">
                                        <tr>
                                            <td style={{backgroundColor:'#cfe2f3'}}><input type='checkbox' className='form-check-input' checked={automationTypeB && Object.values(automationTypeB).every((value) => value)}
                                                onChange={handleSelectAllTypeB} /></td>
                                            <td style={{backgroundColor:'#cfe2f3'}}>Select All</td>
                                            {Object.entries(automationTypeB).map(([number, selected]) => (
                                                <React.Fragment key={number}>

                                                    <td style={{backgroundColor:'#cfe2f3'}} ><input type='checkbox' className='form-check-input' checked={selected}
                                                        onChange={() => handleAutomationTypeB(number)} /></td>
                                                    <td style={{backgroundColor:'#cfe2f3'}}>{number}</td>
                                                    <td style={{backgroundColor:'#cfe2f3'}}>&nbsp;&nbsp;</td>
                                                </React.Fragment>
                                            ))}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row pt-6" style={{marginLeft:'60px', marginTop: 5}}>
                            <div className="col-md-3 mb-4">
                                <label htmlFor="applyRatesToDays" className="form-label" style={{ marginTop: 5}}>
                                    LOR's Type   -
                                </label>
                            </div>
                            <div className="col-md-7 mb-3" style={{marginLeft:'-90px', width: "77%"}}>
                                <table className="table table-borderless text-left">
                                    <tbody className="text-left">
                                        <tr>
                                            <td style={{backgroundColor:'#cfe2f3'}}><input type='checkbox' className='form-check-input' checked={automationLorsB && Object.values(automationLorsB).every((value) => value)}
                                                onChange={handleSelectAllLorB} /></td>
                                            <td style={{backgroundColor:'#cfe2f3'}}>Select All</td>
                                            {Object.entries(automationLorsB).map(([number, selected]) => (
                                                <React.Fragment key={number}>
                                                    <td style={{backgroundColor:'#cfe2f3'}}><input type='checkbox' className='form-check-input' checked={selected}
                                                        onChange={() => handleAutomationLorB(number)} /></td>
                                                    <td style={{backgroundColor:'#cfe2f3'}}>{number}</td>
                                                    <td style={{backgroundColor:'#cfe2f3'}}>&nbsp;&nbsp;</td>
                                                </React.Fragment>
                                            ))}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row pt-6" style={{marginLeft:'60px', marginTop: 5}}>
                            <div className="col-md-3 mb-4">
                                <label htmlFor="applyRatesToDays" className="form-label" style={{ marginTop: 5 }}>
                                    Pricing Strategy Type   -
                                </label>
                            </div>
                            <div className="col-md-7 mb-3" style={{marginLeft:'-90px', width: "77%"}}>
                                <table className="table table-borderless text-left">
                                    <tbody className="text-left">
                                        <tr>
                                            <td style={{ width: '5px', backgroundColor:'#cfe2f3' }}>
                                                <input type='checkbox' className='form-check-input' checked={pricingStrategyB && Object.values(pricingStrategyB).every((value) => value)}
                                                    onChange={handleSelectAllStrategyB} />
                                            </td>
                                            <td style={{backgroundColor:'#cfe2f3'}}>
                                                <label>Select All</label>
                                            </td>
                                            {Object.entries(pricingStrategyB).map(([number, selected]) => (
                                                <React.Fragment key={number}>
                                                    <td style={{ width: '30px', backgroundColor:'#cfe2f3' }}>
                                                        <input type='checkbox' className='form-check-input' checked={selected}
                                                            onChange={() => handleAutomationStrategyB(number)} />
                                                    </td>
                                                    <td style={{backgroundColor:'#cfe2f3'}}>
                                                        <label>{number}</label>
                                                    </td>
                                                </React.Fragment>
                                            ))}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row pt-6" style={{marginLeft:'60px', marginTop: 10}}>
                            <div className="col-md-3 mb-4">
                                <label htmlFor="applyRatesToDays" className="form-label" style={{ marginTop: 5 }}>
                                    Rate Source   -
                                </label>
                            </div>
                            <div className="col-md-6 mb-3" style={{marginLeft:'-90px'}}>
                                <table className="table table-borderless text-left">
                                    <tbody className="text-left">
                                        <tr>
                                            <td style={{backgroundColor:'#cfe2f3'}}><input type='checkbox' className='form-check-input' checked={rateSourceB && Object.values(rateSourceB).every((value) => value)}
                                                onChange={handleSelectAllRateSourceB} /></td>
                                            <td style={{backgroundColor:'#cfe2f3'}}>Select All</td>
                                            {Object.entries(rateSourceB).map(([number, selected]) => (
                                                <React.Fragment key={number}>

                                                    <td style={{backgroundColor:'#cfe2f3'}}><input type='checkbox' className='form-check-input' checked={selected}
                                                        onChange={() => handleRateSourceB(number)} /></td>
                                                    <td style={{backgroundColor:'#cfe2f3'}}>{number}</td>
                                                    <td style={{backgroundColor:'#cfe2f3'}}>&nbsp;&nbsp;</td>
                                                </React.Fragment>
                                            ))}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ flex: '1', marginLeft:'70px', marginTop: 5 }}>
                                <label htmlFor="applyRatesToDays" className="form-label" style={{ marginTop: 5 }}>
                                    Shopping Horizons -
                                </label>
                            </div>
                            <div style={{ flex: '2', marginLeft:'-220px', marginTop: 5, marginBottom : 20 }}>
                            <table style={{ width: '100%' }}>
                                <tbody>
                                    {shoppingHorizonsB.map((shoppingHorizonB, index) => (
                                    <tr key={index}>
                                        <td style={{backgroundColor:'#cfe2f3'}}> 
                                        <input
                                            type="number"
                                            min="0"
                                            max="120"
                                            value={shoppingHorizonB.start}
                                            style={{ textAlign: 'center' }}
                                            disabled={isDisabled}
                                            onChange={(e) =>
                                            handleChangeShoppingHorizonB(index, e.target.value, shoppingHorizonB.end)
                                            }
                                        />
                                        <span> - </span>
                                        <input
                                            type="number"
                                            min="0"
                                            max="120"
                                            value={shoppingHorizonB.end}
                                            disabled={isDisabled}
                                            onChange={(e) =>
                                            handleChangeShoppingHorizonB(index, shoppingHorizonB.start, e.target.value)
                                            }
                                            style={{ marginRight: '30px', textAlign: 'center' }}
                                        />
                                        <select
                                            value={frequencyPermittedB[index] || ''}
                                            onChange={(e) => handleChangeFrequencyPermittedB(index, e.target.value)}
                                            style={{
                                                height: '120%', 
                                                padding: '0',    
                                                border: 'bold',
                                                marginTop: -2
                                                }}
                                        >
                                            <option value="0">Select Frequency Permitted</option>
                                            <option value="1x daily">1x daily</option>
                                            <option value="2x daily">2x daily</option>
                                            <option value="3x daily">3x daily</option>
                                            <option value="4x daily">4x daily</option>
                                            <option value="5x daily">5x daily</option>
                                            <option value="6x daily">6x daily</option>
                                            <option value="1x weekly">1x weekly</option>
                                            <option value="2x weekly">2x weekly</option>
                                            <option value="3x weekly">3x weekly</option>
                                            <option value="4x weekly">4x weekly</option>
                                            <option value="5x weekly">5x weekly</option>
                                            <option value="6x weekly">6x weekly</option>
                                            <option value="7x weekly">7x weekly</option>
                                            <option value="8x weekly">8x weekly</option>
                                        </select>
                                        </td>
                                    </tr>
                                    ))}
                                </tbody>
                                </table>
                            </div>
                        </div>
                        <div style={divStyle}>
                            {/* Content */}
                        </div>
                        <div className="row pt-1">
                            <b>Advanced</b>
                        </div>
                        <div className="row pt-6" style={{marginLeft:'60px', marginTop: 10}}>
                            <div className="col-md-3 mb-4">
                                <label htmlFor="applyRatesToDays" className="form-label" style={{ marginTop: 5 }}>
                                    Automation Type   -
                                </label>
                            </div>
                            <div className="col-md-6 mb-3" style={{marginLeft:'-90px'}}>
                                <table className="table table-borderless text-left">
                                    <tbody className="text-left">
                                        <tr>
                                            <td style={{backgroundColor:'#cfe2f3'}}><input type='checkbox' className='form-check-input' checked={automationTypeA && Object.values(automationTypeA).every((value) => value)}
                                                onChange={handleSelectAllTypeA} /></td>
                                            <td style={{backgroundColor:'#cfe2f3'}}>Select All</td>
                                            {Object.entries(automationTypeA).map(([number, selected]) => (
                                                <React.Fragment key={number}>

                                                    <td style={{backgroundColor:'#cfe2f3'}}><input type='checkbox' className='form-check-input' checked={selected}
                                                        onChange={() => handleAutomationTypeA(number)} /></td>
                                                    <td style={{backgroundColor:'#cfe2f3'}}>{number}</td>
                                                    <td style={{backgroundColor:'#cfe2f3'}}>&nbsp;&nbsp;</td>
                                                </React.Fragment>
                                            ))}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row pt-6" style={{marginLeft:'60px', marginTop: 5}}>
                            <div className="col-md-3 mb-4">
                                <label htmlFor="applyRatesToDays" className="form-label" style={{ marginTop: 5}}>
                                    LOR's Type   -
                                </label>
                            </div>
                            <div className="col-md-6 mb-3" style={{marginLeft:'-90px'}}>
                                <table className="table table-borderless text-left">
                                    <tbody className="text-left">
                                        <tr>
                                            <td style={{backgroundColor:'#cfe2f3'}}><input type='checkbox' className='form-check-input' checked={automationLorsA && Object.values(automationLorsA).every((value) => value)}
                                                onChange={handleSelectAllLorA} /></td>
                                            <td style={{backgroundColor:'#cfe2f3'}}>Select All</td>
                                            {Object.entries(automationLorsA).map(([number, selected]) => (
                                                <React.Fragment key={number}>

                                                    <td style={{backgroundColor:'#cfe2f3'}}><input type='checkbox' className='form-check-input' checked={selected}
                                                        onChange={() => handleAutomationLorA(number)} /></td>
                                                    <td style={{backgroundColor:'#cfe2f3'}}>{number}</td>
                                                    <td style={{backgroundColor:'#cfe2f3'}}>&nbsp;&nbsp;</td>
                                                </React.Fragment>
                                            ))}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row pt-6" style={{marginLeft:'60px', marginTop: 5}}>
                            <div className="col-md-3 mb-4">
                                <label htmlFor="applyRatesToDays" className="form-label" style={{ marginTop: 5 }}>
                                    Pricing Strategy Type   -
                                </label>
                            </div>
                            <div className="col-md-7 mb-3" style={{marginLeft:'-90px', width: "77%"}}>
                                <table className="table table-borderless text-left">
                                    <tbody className="text-left">
                                        <tr>
                                            <td style={{ width: '5px',backgroundColor:'#cfe2f3' }}>
                                                <input type='checkbox' className='form-check-input' checked={pricingStrategyA && Object.values(pricingStrategyA).every((value) => value)}
                                                    onChange={handleSelectAllStrategyA} />
                                            </td>
                                            <td style={{backgroundColor:'#cfe2f3'}}>
                                                <label>Select All</label>
                                            </td>
                                            {Object.entries(pricingStrategyA).map(([number, selected]) => (
                                                <React.Fragment key={number}>
                                                    <td style={{ width: '30px', backgroundColor:'#cfe2f3' }}>
                                                        <input type='checkbox' className='form-check-input' checked={selected}
                                                            onChange={() => handleAutomationStrategyA(number)} />
                                                    </td>
                                                    <td style={{backgroundColor:'#cfe2f3'}}>
                                                        <label>{number}</label>
                                                    </td>
                                                </React.Fragment>
                                            ))}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row pt-6" style={{marginLeft:'60px', marginTop: 10}}>
                            <div className="col-md-3 mb-4">
                                <label htmlFor="applyRatesToDays" className="form-label" style={{ marginTop: 5 }}>
                                    Rate Source  -
                                </label>
                            </div>
                            <div className="col-md-6 mb-3" style={{marginLeft:'-90px'}}>
                                <table className="table table-borderless text-left">
                                    <tbody className="text-left">
                                        <tr>
                                            <td style={{backgroundColor:'#cfe2f3'}}><input type='checkbox' className='form-check-input' checked={rateSourceA && Object.values(rateSourceA).every((value) => value)}
                                                onChange={handleSelectAllRateSourceA} /></td>
                                            <td style={{backgroundColor:'#cfe2f3'}}>Select All</td>
                                            {Object.entries(rateSourceA).map(([number, selected]) => (
                                                <React.Fragment key={number}>

                                                    <td style={{backgroundColor:'#cfe2f3'}}><input type='checkbox' className='form-check-input' checked={selected}
                                                        onChange={() => handleRateSourceA(number)} /></td>
                                                    <td style={{backgroundColor:'#cfe2f3'}}>{number}</td>
                                                    <td style={{backgroundColor:'#cfe2f3'}}>&nbsp;&nbsp;</td>
                                                </React.Fragment>
                                            ))}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div style={{ display: 'flex'}}>
                            <div style={{ flex: '1', marginLeft:'70px', marginTop: 5 }}>
                                <label htmlFor="applyRatesToDays" className="form-label" style={{ marginTop: 5 }}>
                                    Shopping Horizons -
                                </label>
                            </div>
                            <div style={{ flex: '2', marginLeft:'-220px', marginTop: 5, marginBottom : 20 }}>
                            <table style={{ width: '100%' }}>
                                <tbody>
                                    {shoppingHorizonsA.map((shoppingHorizonA, index) => (
                                    <tr key={index}>
                                        <td style={{backgroundColor:'#cfe2f3'}}>
                                        <input
                                            type="number"
                                            min="0"
                                            max="120"
                                            value={shoppingHorizonA.start}
                                            style={{ textAlign: 'center' }}
                                            disabled={isDisabled}
                                            onChange={(e) =>
                                            handleChangeShoppingHorizonA(index, e.target.value, shoppingHorizonA.end)
                                            }
                                        />
                                        <span> - </span>
                                        <input
                                            type="number"
                                            min="0"
                                            max="120"
                                            value={shoppingHorizonA.end}
                                            disabled={isDisabled}
                                            onChange={(e) =>
                                            handleChangeShoppingHorizonA(index, shoppingHorizonA.start, e.target.value)
                                            }
                                            style={{ marginRight: '30px', textAlign: 'center' }}
                                        />
                                        <select
                                            value={frequencyPermittedA[index] || ''}
                                            onChange={(e) => handleChangeFrequencyPermittedA(index, e.target.value)}
                                            style={{
                                                height: '120%', 
                                                padding: '0',    
                                                border: 'bold',
                                                marginTop: -2
                                                }}
                                        >
                                            <option value="0">Select Frequency Permitted</option>
                                            <option value="1x daily">1x daily</option>
                                            <option value="2x daily">2x daily</option>
                                            <option value="3x daily">3x daily</option>
                                            <option value="4x daily">4x daily</option>
                                            <option value="5x daily">5x daily</option>
                                            <option value="6x daily">6x daily</option>
                                            <option value="1x weekly">1x weekly</option>
                                            <option value="2x weekly">2x weekly</option>
                                            <option value="3x weekly">3x weekly</option>
                                            <option value="4x weekly">4x weekly</option>
                                            <option value="5x weekly">5x weekly</option>
                                            <option value="6x weekly">6x weekly</option>
                                            <option value="7x weekly">7x weekly</option>
                                            <option value="8x weekly">8x weekly</option>
                                        </select>
                                        </td>
                                    </tr>
                                    ))}
                                </tbody>
                                </table>
                            </div>
                        </div>
                        <div style={divStyle}>
                            {/* Content */}
                        </div>
                        <div className="row pt-2">
                            <b>Premium</b>
                        </div>
            
                        <div className="row pt-6" style={{marginLeft:'60px', marginTop: 10}}>
                            <div className="col-md-3 mb-4">
                                <label htmlFor="applyRatesToDays" className="form-label" style={{ marginTop: 5 }}>
                                    Automation Type   -
                                </label>
                            </div>
                            <div className="col-md-6 mb-3" style={{marginLeft:'-90px'}}>
                                <table className="table table-borderless text-left">
                                    <tbody className="text-left">
                                        <tr>
                                            <td style={{backgroundColor:'#cfe2f3'}}><input type='checkbox' className='form-check-input' checked={automationTypeP && Object.values(automationTypeP).every((value) => value)}
                                                onChange={handleSelectAllTypeP} /></td>
                                            <td style={{backgroundColor:'#cfe2f3'}}>Select All</td>
                                            {Object.entries(automationTypeP).map(([number, selected]) => (
                                                <React.Fragment key={number}>

                                                    <td style={{backgroundColor:'#cfe2f3'}}><input type='checkbox' className='form-check-input' checked={selected}
                                                        onChange={() => handleAutomationTypeP(number)} /></td>
                                                    <td style={{backgroundColor:'#cfe2f3'}}>{number}</td>
                                                    <td style={{backgroundColor:'#cfe2f3'}}>&nbsp;&nbsp;</td>
                                                </React.Fragment>
                                            ))}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row pt-6" style={{marginLeft:'60px', marginTop: 5}}>
                            <div className="col-md-3 mb-4">
                                <label htmlFor="applyRatesToDays" className="form-label" style={{ marginTop: 5}}>
                                    LOR's Type   -
                                </label>
                            </div>
                            <div className="col-md-6 mb-3" style={{marginLeft:'-90px'}}>
                                <table className="table table-borderless text-left">
                                    <tbody className="text-left">
                                        <tr>
                                            <td style={{backgroundColor:'#cfe2f3'}}><input type='checkbox' className='form-check-input' checked={automationLorsP && Object.values(automationLorsP).every((value) => value)}
                                                onChange={handleSelectAllLorP} /></td>
                                            <td style={{backgroundColor:'#cfe2f3'}}>Select All</td>
                                            {Object.entries(automationLorsP).map(([number, selected]) => (
                                                <React.Fragment key={number}>

                                                    <td style={{backgroundColor:'#cfe2f3'}}><input type='checkbox' className='form-check-input' checked={selected}
                                                        onChange={() => handleAutomationLorP(number)} /></td>
                                                    <td style={{backgroundColor:'#cfe2f3'}}>{number}</td>
                                                    <td style={{backgroundColor:'#cfe2f3'}}>&nbsp;&nbsp;</td>
                                                </React.Fragment>
                                            ))}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row pt-6" style={{marginLeft:'60px', marginTop: 5}}>
                            <div className="col-md-3 mb-4">
                                <label htmlFor="applyRatesToDays" className="form-label" style={{ marginTop: 5 }}>
                                    Pricing Strategy Type   -
                                </label>
                            </div>
                            <div className="col-md-7 mb-3" style={{marginLeft:'-90px', width: "77%"}}>
                                <table className="table table-borderless text-left">
                                    <tbody className="text-left">
                                        <tr>
                                            <td style={{ width: '5px', backgroundColor:'#cfe2f3' }}>
                                                <input type='checkbox' className='form-check-input' checked={pricingStrategyP && Object.values(pricingStrategyP).every((value) => value)}
                                                    onChange={handleSelectAllStrategyP} />
                                            </td>
                                            <td style={{backgroundColor:'#cfe2f3'}}>
                                                <label>Select All</label>
                                            </td>
                                            {Object.entries(pricingStrategyP).map(([number, selected]) => (
                                                <React.Fragment key={number}>
                                                    <td style={{ width: '30px', backgroundColor:'#cfe2f3' }}>
                                                        <input type='checkbox' className='form-check-input' checked={selected}
                                                            onChange={() => handleAutomationStrategyP(number)} />
                                                    </td>
                                                    <td style={{backgroundColor:'#cfe2f3'}}>
                                                        <label>{number}</label>
                                                    </td>
                                                </React.Fragment>
                                            ))}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row pt-6" style={{marginLeft:'60px', marginTop: 10}}>
                            <div className="col-md-3 mb-4">
                                <label htmlFor="applyRatesToDays" className="form-label" style={{ marginTop: 5 }}>
                                    Rate Source   -
                                </label>
                            </div>
                            <div className="col-md-6 mb-3" style={{marginLeft:'-90px'}}>
                                <table className="table table-borderless text-left">
                                    <tbody className="text-left">
                                        <tr>
                                            <td style={{backgroundColor:'#cfe2f3'}}><input type='checkbox' className='form-check-input' checked={rateSourceP && Object.values(rateSourceP).every((value) => value)}
                                                onChange={handleSelectAllRateSourceP} /></td>
                                            <td style={{backgroundColor:'#cfe2f3'}}>Select All</td>
                                            {Object.entries(rateSourceP).map(([number, selected]) => (
                                                <React.Fragment key={number}>

                                                    <td style={{backgroundColor:'#cfe2f3'}}><input type='checkbox' className='form-check-input' checked={selected}
                                                         onChange={() => handleRateSourceP(number)} /></td>
                                                    <td style={{backgroundColor:'#cfe2f3'}}>{number}</td>
                                                    <td style={{backgroundColor:'#cfe2f3'}}>&nbsp;&nbsp;</td>
                                                </React.Fragment>
                                            ))}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div style={{ display: 'flex'}}>
                            <div style={{ flex: '1', marginLeft:'70px', marginTop: 5 }}>
                                <label htmlFor="applyRatesToDays" className="form-label" style={{ marginTop: 5 }}>
                                    Shopping Horizons -
                                </label>
                            </div>
                            <div style={{ flex: '2', marginLeft:'-220px', marginTop: 5, marginBottom : 20 }}>
                            <table style={{ width: '100%' }}>
                                <tbody>
                                    {shoppingHorizonsP.map((shoppingHorizonP, index) => (
                                    <tr key={index}>
                                        <td style={{backgroundColor:'#cfe2f3'}}>
                                        <input
                                            type="number"
                                            min="0"
                                            max="120"
                                            value={shoppingHorizonP.start}
                                            style={{ textAlign: 'center' }}
                                            disabled={isDisabled}
                                            onChange={(e) =>
                                            handleChangeShoppingHorizonP(index, e.target.value, shoppingHorizonP.end)
                                            }
                                        />
                                        <span> - </span>
                                        <input
                                            type="number"
                                            min="0"
                                            max="120"
                                            value={shoppingHorizonP.end}
                                            disabled={isDisabled}
                                            onChange={(e) =>
                                            handleChangeShoppingHorizonP(index, shoppingHorizonP.start, e.target.value)
                                            }
                                            style={{ marginRight: '30px', textAlign: 'center' }}
                                        />
                                        <select
                                            value={frequencyPermittedP[index] || ''}
                                            onChange={(e) => handleChangeFrequencyPermittedP(index, e.target.value)}
                                            style={{
                                                height: '120%', 
                                                padding: '0',    
                                                border: 'bold',
                                                marginTop: -2
                                                }}
                                        >
                                            <option value="0">Select Frequency Permitted</option>
                                            <option value="1x daily">1x daily</option>
                                            <option value="2x daily">2x daily</option>
                                            <option value="3x daily">3x daily</option>
                                            <option value="4x daily">4x daily</option>
                                            <option value="5x daily">5x daily</option>
                                            <option value="6x daily">6x daily</option>
                                            <option value="1x weekly">1x weekly</option>
                                            <option value="2x weekly">2x weekly</option>
                                            <option value="3x weekly">3x weekly</option>
                                            <option value="4x weekly">4x weekly</option>
                                            <option value="5x weekly">5x weekly</option>
                                            <option value="6x weekly">6x weekly</option>
                                            <option value="7x weekly">7x weekly</option>
                                            <option value="8x weekly">8x weekly</option>
                                        </select>
                                        </td>
                                    </tr>
                                    ))}
                                </tbody>
                                </table>
                            </div>
                        </div>
                        {accessType === 'View' ? "" :
                            <>
                                <div className = "row">
                                    <div className="col-md-5">
                                    </div>
                                    <div className="col-md-2">
                                        <button style={{width: '150px', marginBottom:'30px', fontSize:'12px'}} type="submit"  className="btn btn-success" onClick={handleAutomationPlansSubmit} >Submit </button>
                                    </div>
                                    <div className="col-md-2"></div>
                                </div>
                            </>
                        }
                        </>
            }
                    </div>
                </div>
            </div>  
        </>
    )
}