import React, { useState, useEffect } from 'react'
import { Button, Typography } from '@mui/material';
import { Modal } from 'react-bootstrap';
import Account from './Account';
import AccountLocation from './AccountLocation';
import Location from './Location';
import User from './User';
import OptionalServicesStandard from './OptionalServicesStandard';
import OptionalServices from './OptionalServices';
import BusinessHours from './BusinessHours';
import Tax from './Tax';
import RateRules from './RateRules';
import OneWayRental from './OneWayRental';
import ReservationSetupAuditLog from './ReservationSetupAuditLog';
import RateCode from './RateCode';
import AccountRateCode from './AccountRateCode';
import FleetStandardAudit from './FleetStandardAudit';
import FleetDescription from './FleetDescription';
import MinimumRate from './MinimumRate';
import RateID from './RateID';
import ErrorCode from './ErrorCode';
import UtilizationBasedAdjustment from './UtilizationBasedAdjustment';
import EuropCars from './EuropCars';
import Inventory from './Inventory';
import Adjustment from './Adjustment';
import Promotion from './Promotion';
import Blackout from './Blackout';
import NoShow from './NoShow';
import RateUpdate from './RateUpdate';
import CommissionScreen from './CommissionScreen';
import CapacityControl from './CapacityControl';
import Select from "react-select";
import axios from 'axios';
import { baseURL } from '../backend_url';

export default function EventLog() {

  const [lgShow, setLgShow] = useState(false);
  const [reportName, setReportName] = useState("");
  const [companyDetail, setcompanyDetail] = useState([{}]);
  const [companyName, setCompanyName] = useState("");
  const [company_id, setcompany_id] = useState("");
  const [loggedInUserDetail, setLoggedInUserDetail] = useState({});
  const [fetchedClicked, setFetchedClicked] = useState(false);
  const [locationDetail, setLocationDetail] = useState([{}]);
  const [locationName, setLocationName] = useState("");
  const [locationId, setLocationId] = useState("");


  //Option for Report name
  const reportNameOptions = [
    { value: "Account", label: "Account" },
    { value: "Account Location", label: "Account Location" },
    { value: "Location", label: "Location" },
    { value: "User", label: "User" },
    { value: "Tax", label: "Tax" },
    { value: "Optional Services Standard", label: "Optional Services Standard" },
    { value: "Optional Services", label: "Optional Services" },
    { value: "Business Hours", label: "Business Hours" },
    { value: "Account Rate Rules", label: "Account Rate Rules" },
    { value: "One Way Rental", label: "One Way Rental" },
    { value: "Reservation Source", label: "Reservation Source" },
    { value: "Rate Code", label: "Rate Code" },
    { value: "Account Rate Code", label: "Account Rate Code" },
    { value: "Fleet Standard", label: "Fleet Standard" },
    { value: "Fleet Description", label: "Fleet Description" },
    { value: "Minimum Rate", label: "Minimum Rate" },
    { value: "Rate ID", label: "Rate ID" },
    { value: "Error Code", label: "Error Code" },
    { value: "Utilization Based Adjustment", label: "Utilization Based Adjustment" },
    { value: "EuropCars", label: "EuropCars" },
    { value: "Update Inventory", label: "Update Inventory" },
    { value: "Adjustment", label: "Adjustment" },
    { value: "Promotion", label: "Promotion" },
    { value: "Blackout", label: "Blackout" },
    { value: "No Show", label: "No Show" },
    { value: "Rate Update", label: "Rate Update" },
    { value: "Commission Screen", label: "Commission Screen" },
    { value: "Capacity Control", label: "Capacity Control" },

  ];


  //API call
  useEffect(() => {

    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);
    setLoggedInUserDetail(foundUser)

    if (foundUser.role === 'SuperAdmin') {

      axios.get(baseURL + "/get_company_details_for_users")
        .then((res) => {
          setcompanyDetail(res.data);
        });
    }
    else {
      fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['EventLog_Account Access'] + "*"
        + foundUser.account_id + "*" + foundUser.account_type.account_type)
        .then((res) => res.json())
        .then((data) => {

          setcompanyDetail(data);

        });
      setFetchedClicked(false);
    }



  }, []);


  const handleInputChangeReportName = characterEntered => {
    setReportName(characterEntered.value);
  };


  //Trigger on Company Name Dropdown Changes , getting Company ID
  useEffect(() => {

    if (companyDetail && companyName) {

      setLocationDetail([]);
      setLocationName("");
      setLocationId("");
      let obj_company_detail = companyDetail.filter(
        (value) => value.company_name === companyName
      )[0];
      const company_id_temp = obj_company_detail._id;
      const company_type = obj_company_detail.account_type;
      setcompany_id(company_id_temp);
      setFetchedClicked(false);

      //API call for getting data from DB
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };

      fetch(baseURL + "/get_company_location/" + company_id_temp, requestOptions)
        .then((res) => res.json())
        .then((data) => {

          let allTemp = data.res;

          if (allTemp.length > 0) {
            setLocationDetail(allTemp);
            setLocationName("");
            setLocationId("");
          }

        })

    }//if condition ends



  }, [companyName]);



  //Option for company name
  const companyNameOptions = companyDetail.map((value) => (

    { value: value.company_name, label: value.company_name }
  ));


  //Option for Location name
  const locationNameOptions = locationDetail.map((value) => (

    { value: value.location_name, label: value.location_name }
  ));


  const handleInputChangeLocationName = characterEntered => {
    setLocationName(characterEntered.value);
  };


  const handleInputChangeCompanyName = characterEntered => {
    setCompanyName(characterEntered.value);
  };


  useEffect(() => {

    setFetchedClicked(false);
  }, [reportName])


  return (
    <>
      <div className="row my-3 ">
        <div className="col-md-6">
          <Typography className="mx-3" variant='h6' sx={{ pb: 3 }}>
            Audit/Event Log
          </Typography>
        </div>
        <div className="col-md-6 d-flex justify-content-end"   >

          <button type="button" className="btn btn-danger" onClick={() => setLgShow(true)} style={{ height: "40px" }}  >Help</button>
          <Modal
            size="lg"
            show={lgShow}
            onHide={() => setLgShow(false)}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton style={{ color: '#FFFFFF', backgroundColor: '#C62828' }}>
              <Modal.Title id="example-modal-sizes-title-lg" >
                Instructions to Add/Edit User
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5>Create New user</h5>
              <ol>
                <li>User Setup {'>'} Click on Add New User</li>
                <li>Select the company from company name dropdown {'>'} Company ID is generated</li>
                <li>Enter the User Email ID and Name {'>'} User ID is generated</li>
                <li>From Branch Access select Branch Name {'>'} Click on allocated check box {'>'} All module access is given to the user {'>'} Click on submit</li>
                <li>To give access to only few modules, click on radio button in the Branch Access {'>'} Module access is generated {'>'} Select modules {'>'} Allocate the access {'>'} Click on submit</li>
              </ol>

              <h5>View/Edit User</h5>
              <ol>
                <li>To view/edit user ID’s, select company name from drop down {'>'} view/edit the user details {'>'} submit.</li>
                <li><strong>Note:</strong> Company name and user ID fields are non-editable</li>
              </ol>

              <h5>To deactivate the User</h5>
              <ol>
                <li>User Setup {'>'} Select company name from drop down.</li>
                <li>Go to the edit user page.</li>
                <li>Click on deactivate</li>
              </ol>
            </Modal.Body>
          </Modal>

        </div>
      </div>


      {/* <div className="col-4 d-flex justify-content-end px-5">
        
          
         
        </div> */}

      <div className="row pt-2" >
        <div className="col-8 mx-3">
          <span style={{ color: '#1E1E1E', fontSize: '21px' }}>
            Select Account and Module to see Audit :
          </span>
        </div>
      </div>

      <div className="row pt-5">
        {/* <div className="col-1"></div> */}
        <div className="col-11 mx-3">

          {/* First Row */}
          <div className="row">
            <div className="col-md-2 mb-3">
              <label htmlFor="report_name" className="form-label" style={{ marginTop: 5 }}>
                Select Account*
              </label>
            </div>

            <div className="col-md-3 mb-3">

              <Select

                value={{ value: companyName, label: (companyName ? companyName : "Select Account name") }}
                inputId="company_name"
                name="company_name"
                options={companyNameOptions}
                onChange={handleInputChangeCompanyName}

              />

            </div>


            <div className="col-md-1 mb-3"></div>

            <div className="col-md-2 mb-3">
              <label htmlFor="audit_log" className="form-label" style={{ marginTop: 5 }}>
                Select Module*
              </label>
            </div>
            <div className="col-md-3 mb-3">
              <Select

                value={{ value: reportName, label: (reportName ? reportName : "Select Module") }}
                inputId="audit_log"
                name="audit_log"
                options={reportNameOptions}
                onChange={handleInputChangeReportName}

              />
            </div>

          </div>{/* first row wrapper ends */}


          {/* Second Row start */}
          {reportName === "Rate Update" &&
            <div className="row mt-3">
              <div className="col-md-2 mb-3">
                <label htmlFor="location_name" className="form-label" style={{ marginTop: 5 }}>
                  Select Location*
                </label>
              </div>

              <div className="col-md-3 mb-3">

                <Select

                  value={{ value: locationName, label: (locationName ? locationName : "Select Location Name") }}
                  inputId="location_name"
                  name="location_name"
                  options={locationNameOptions}
                  onChange={handleInputChangeLocationName}


                />
              </div>


              <div className="col-md-1 mb-3"></div>

              <div className="col-md-2 mb-3">
                {/* <label htmlFor="audit_log" className="form-label" style={{ marginTop: 5 }}>
                Select Module*
              </label> */}
              </div>
              <div className="col-md-3 mb-3">
                {/* <Select

                value={{ value: reportName, label: (reportName ? reportName : "Select Module") }}
                inputId="audit_log"
                name="audit_log"
                options={reportNameOptions}
                onChange={handleInputChangeReportName}

              /> */}
              </div>

            </div>
          }
          {/* Second Row ends */}

          <div className="row pt-4">
            <div className="col-md-7"></div>
            <div className="col-md-2 d-flex justify-content-end">
              {fetchedClicked &&
                <Button variant="outlined" color="error" onClick={() => {
                  setFetchedClicked(false); setReportName("");
                  setCompanyName(""); setLocationDetail([]); setLocationName("");
                }}>Reset</Button>
              }
            </div>
            <div className="col-md-2 d-flex justify-content-end">

              <Button variant="contained" onClick={() => { setFetchedClicked(true) }} disabled={reportName === '' || companyName === '' || (reportName === "Rate Update" && locationName == '') ? true : false}>Fetch</Button>

            </div>
          </div>
        </div>
      </div>


      {fetchedClicked && (reportName === 'Account' ? <Account company_id={company_id} /> :
        reportName === "Account Location" ? <AccountLocation company_id={company_id} /> :
          reportName === "User" ? <User company_id={company_id} /> :
            reportName === "Location" ? <Location companyName={companyName} /> :
              reportName === "Optional Services Standard" ? <OptionalServicesStandard company_id={company_id} /> :
                reportName === "Optional Services" ? <OptionalServices company_id={company_id} /> :
                  reportName === "Business Hours" ? <BusinessHours company_id={company_id} /> :
                    reportName === "Tax" ? <Tax company_id={company_id} /> :
                      reportName === "Account Rate Rules" ? <RateRules company_id={company_id} /> :
                        reportName === "One Way Rental" ? <OneWayRental company_id={company_id} /> :
                          reportName === "Reservation Source" ? <ReservationSetupAuditLog company_id={company_id} /> :
                            reportName === "Rate Code" ? <RateCode company_id={company_id} /> :
                              reportName === "Account Rate Code" ? <AccountRateCode company_id={company_id} /> :
                                reportName === "Fleet Standard" ? <FleetStandardAudit company_id={company_id} /> :
                                  reportName === "Fleet Description" ? <FleetDescription company_id={company_id} /> :
                                    reportName === "Minimum Rate" ? <MinimumRate company_id={company_id} /> :
                                      reportName === "Rate ID" ? <RateID company_id={company_id} /> :
                                        reportName === "Error Code" ? <ErrorCode company_id={company_id} /> :
                                          reportName === "Utilization Based Adjustment" ? <UtilizationBasedAdjustment company_id={company_id} /> :
                                            reportName === "EuropCars" ? <EuropCars company_id={company_id} /> :
                                              reportName === "Update Inventory" ? <Inventory company_id={company_id} /> :
                                                reportName === "Adjustment" ? <Adjustment company_id={company_id} /> :
                                                  reportName === "Promotion" ? <Promotion company_id={company_id} /> :
                                                    reportName === "Blackout" ? <Blackout company_id={company_id} /> :
                                                      reportName === "No Show" ? <NoShow company_id={company_id} /> :
                                                        reportName === "Rate Update" ? <RateUpdate company_id={company_id} locationName={locationName} setCompanyName={setCompanyName} /> :
                                                          reportName === "Commission Screen" ? <CommissionScreen company_id={company_id} locationName={locationName} setCompanyName={setCompanyName} /> :
                                                            reportName === "Capacity Control" ? <CapacityControl company_id={company_id} locationName={locationName} setCompanyName={setCompanyName} /> : "")}

    </>
  );
}
