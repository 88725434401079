import React, { useEffect, useState } from 'react';
import { Alert } from '@mui/material';
import Select from "react-select";
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { baseURL } from '../backend_url';
import axios from 'axios';
import Swal from 'sweetalert2';
import ReactPhoneInput from "react-phone-input-2";

export default function NewForm() {

  var temp_company_id;
  const arr_location_check = [];

  const [companyDetail, setcompanyDetail] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [validationMsg, setValidationMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [company_id, setcompany_id] = useState('');
  const [loading, setLoading] = useState(false);
  const [locationDetail, setLocationDetail] = useState([{}]);
  const [checkedState, setCheckedState] = useState({});
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [maxUser, setMaxUser] = useState(null);
  const [existingUser, setExistingUser] = useState(null);
  const [roleDetail, setRoleDetail] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [loggedInUserDetail, setLoggedInUserDetail] = useState({});
  const [phone2, setPhone2] = useState("");
  const [sq1, setSq1] = useState("");
  const [sq2, setSq2] = useState("");
  const [sq3, setSq3] = useState("");
  const [q1, setq1] = useState("In what city were you born?");
  const [q2, setq2] = useState("What is the name of your favorite pet?");
  const [q3, setq3] = useState("At what age did you start school?");
  const [submitAndSendMail, setSubmitAndSendMail] = useState("No");

  const [selectAllLocation, setSelectAllLocation] = useState(false);


  // form validation rules 
  const validationSchema = Yup.object().shape({
    userName: Yup.string()
      .required('User Name is required')
      .min(3, 'Name too short')
      .max(100, 'Name too large!'),

    userEmail: Yup.string()
      .required('User Email ID is required')
      .email('Enter a valid Email ID'),

    password: Yup.string()
      .required('Password is required')
      .min(8, 'Must be greater than 8 characters')
      .max(200, 'Must be less than 200 characters')
      .matches(
        // /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        "Must Contain atleast 8 Characters, One Uppercase, One Lowercase and One Number "
      )

  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, setValue, formState: { errors } } = useForm(formOptions);

  //API call
  useEffect(() => {

    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);
    setLoggedInUserDetail(foundUser);

    if (foundUser.role === 'SuperAdmin') {

      axios.get(baseURL + "/get_company_details_for_users")
        .then((res) => {
          setcompanyDetail(res.data);
        });
    }
    else {

      fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['User_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
        .then((res) => res.json())
        .then((data) => {
          setcompanyDetail(data);
        });
    }

    // axios.get(baseURL + "/user_role_setup")
    //     .then((res) => {
    //       if(res.data){
    //         setRoleDetail(res.data)
    //       }
    //     })
  }, []);

  //getting Company ID
  useEffect(() => {
    if (companyDetail && companyName) {

      const company_obj = companyDetail.filter(
        (value) => value.company_name === companyName
      )[0];
      temp_company_id = company_obj._id;
      setcompany_id(temp_company_id);
      setMaxUser(company_obj.no_of_users);

      //API call for getting data from Location DB
      const requestOptions1 = {
        method: "GET",
        mode: 'cors',
        headers: {
          "Content-Type": "application/json",
        },
      };

      fetch(
        baseURL + "/get_user_details/" + temp_company_id,
        requestOptions1
      )
        .then((res) => res.json())
        .then((data) => {

          if (data.existing_users === undefined)
            setExistingUser(0)
          else
            setExistingUser(data.existing_users)
        });

      //API call for getting data from Location DB
      const requestOptions2 = {
        method: "GET",
        mode: 'cors',
        headers: {
          "Content-Type": "application/json",
        },
      };

      fetch(
        baseURL + "/get_company_location/" + temp_company_id,
        requestOptions2
      )
        .then((res) => res.json())
        .then((data) => {

          let location_hash = {}
          for (let i = 0; i < data.res.length; i++) {
            location_hash[data.res[i].location_id] = false
          }

          setLocationDetail(data.res);
          setCheckedState(location_hash);

        });

      let accountType = ""
      for (let i = 0; i < companyDetail.length; i++) {
        if (companyDetail[i]._id === temp_company_id && companyDetail[i].account_type === 'Affiliate') {

          temp_company_id = companyDetail[i].affiliated_to_id;
          accountType = companyDetail[i].account_type;
          break;
        }
      }

      axios.get(baseURL + "/user_role_setup/" + temp_company_id + "*" + accountType)
        .then((res) => {
          setRoleDetail(res.data)
        })
    }

  }, [companyName])



  //OnSubmit code
  const submitHandler = () => {
    setLoading(true)
    setSuccessMsg("");
    setValidationMsg("");

    if (selectedRole === '') {
      Swal.fire("Role Name field Can't be empty")
      setLoading(false)
      return;
    }
    if (sq1.toString().trim() === '') {
      Swal.fire("Please fill answer for security question 1")
      setLoading(false)
      return;
    }
    if (sq2.toString().trim() === '') {
      Swal.fire("Please fill answer for security question 2")
      setLoading(false)
      return;
    }
    if (sq3.toString().trim() === '') {
      Swal.fire("Please fill answer for security question 3")
      setLoading(false)
      return;
    }


    //API call for inserting data into DB
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        "company_id": company_id.toString(),
        "company_name": companyName,
        "user_email": userEmail,
        "user_name": userName,
        "password": password,
        "selected_role": selectedRole,
        "location_allocated": checkedState,
        "contact_no": phone2,
        'created_by': loggedInUserDetail.userEmail,
        'updated_by': loggedInUserDetail.userEmail,
        'logged_in': false,
        's_q1': q1,
        's_q2': q2,
        's_q3': q3,
        's_a1': sq1.toString().trim(),
        's_a2': sq2.toString().trim(),
        's_a3': sq3.toString().trim(),
        'send_mail': submitAndSendMail

      }),
    };

    fetch(baseURL + "/add_new_user", requestOptions)
      .then(res => res.json())
      .then(data => {
        setLoading(false);
        if (data.status.indexOf('already Exists') > -1) {
          setValidationMsg(data.msg);
        }
        else if (data.status.indexOf('limit touched') > -1) {
          setValidationMsg(data.msg);
        }
        else {
          setSuccessMsg("New user created successfully with User Email - " + data.msg);
          setcompany_id('');
          setUserEmail('');
          setUserName('');
          setCompanyName('');
          setPassword('');
          setValue('userEmail', '');
          setValue('userName', '');
          setValue('password', '');
          setShowPassword(false);
          setExistingUser("");
          setMaxUser("");
          setPhone2("");
          setSq1("");
          setSq2("");
          setSq3("");
          setSubmitAndSendMail("No");

        }

      });
  }; //submitHandler func ends here

  //Option for company name
  const RoleNameOptions = roleDetail.map((value) => (

    { value: value.role_name, label: value.role_name }
  ));

  const handleInputChangeRoleName = characterEntered => {
    setSelectedRole(characterEntered.value);
  };

  //Option for company name
  const companyNameOptions = companyDetail.map((value) => (

    { value: value.company_name, label: value.company_name }
  ));

  const handleInputChangeCompanyName = characterEntered => {
    setCompanyName(characterEntered.value);
  };

  const handleGeneratePassword = () => {
    let pass = generatePassword()
    setPassword(pass)
    setValue('password', pass)
  }

  const generatePassword = () => {
    var length = 8,
      charset2 = "abcdefghijklmnopqrstuvwxyz",
      charset1 = "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
      charset3 = "0123456789",
      retVal = "";
    for (var i = 0, n = charset1.length; i < 3; ++i) {
      retVal += charset1.charAt(Math.floor(Math.random() * n));
    }

    for (var i = 0, n = charset2.length; i < 2; ++i) {
      retVal += charset2.charAt(Math.floor(Math.random() * n));
    }

    for (var i = 0, n = charset3.length; i < 3; ++i) {
      retVal += charset3.charAt(Math.floor(Math.random() * n));
    }
    return shuffle_pass(retVal);
  }


  const shuffle_pass = (pass_str) => {
    var a = pass_str.split(""),
      n = a.length;

    for (var i = n - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var tmp = a[i];
      a[i] = a[j];
      a[j] = tmp;
    }
    return a.join("");
  }

  //Location Tick Check 
  const handleLocationCheck = (location_name) => {

    const location_hash_temp = { ...checkedState };
    location_hash_temp[location_name] = !location_hash_temp[location_name];
    setCheckedState(location_hash_temp);

    let arr = Object.keys(location_hash_temp);
    let flag = true;
    for (let i = 0; i < arr.length; i++) {

      if (location_hash_temp[arr[i]] === false) {
        flag = false;
        break;
      }
    }
    if (flag) {
      setSelectAllLocation(true);
    }
    else {
      setSelectAllLocation(false);
    }

  }


  //Location Tick Check 
  const handleAllLocationCheck = () => {

    const location_hash_temp = { ...checkedState };
    setSelectAllLocation(!selectAllLocation);

    let arr = Object.keys(location_hash_temp);
    for (let i = 0; i < arr.length; i++) {

      if (!selectAllLocation) {
        location_hash_temp[arr[i]] = true
      }
      else {
        location_hash_temp[arr[i]] = false
      }
    }

    setCheckedState(location_hash_temp);
  }


  return (
    <>
      <div className="row pt-4">
        <div className="col-8 mx-3">
          <span style={{ color: '#1E1E1E', fontSize: '21px' }}>Setup New User: </span>
        </div>
      </div>
      <form className="row g-3" style={{fontSize:'12px', color:'black', fontWeight:'600'}} onSubmit={handleSubmit(submitHandler)}>
        <div className="row pt-5">
          {/* <div className="col-1"></div> */}
          <div className="col-8 mx-3">
            <div className='row'>
              {validationMsg ? (
                <div className='row mb-3'>
                  <Alert severity="error">{validationMsg}</Alert>
                </div>
              ) : (
                ""
              )}

              {successMsg ?

                (

                  <div className="alert alert-success alert-dismissible fade show" role="alert">
                    {successMsg}
                    <button className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setSuccessMsg('')}></button>
                  </div>
                )
                : ""}



              <div className="col-md-2 mb-3">
                <label htmlFor="company_name" className="form-label">
                  Account Name
                </label>
              </div>
              <div className="col-md-5  mb-3">

                <Select
                  value={{ value: companyName, label: (companyName ? companyName : "Select Account name") }}
                  inputId="company_name"
                  name="company_name"
                  options={companyNameOptions}
                  onChange={handleInputChangeCompanyName}
                />


              </div>
              <div className="col-md-1 mb-3"></div>
              <div className="col-md-2 mb-3">
                {/* <label htmlFor="company_id" className="form-label">
                  Account ID
                </label> */}
              </div>
              <div className="col-md-2 mb-3">
                {/* <input
                  type="text"
                  className="form-control"
                  value={company_id}
                  id="company_id"
                  disabled
                /> */}
              </div>

              <div className="col-md-2 mb-3">
                <label htmlFor="user_email" className="form-label">
                  User Email
                </label>
              </div>
              <div className="col-md-5 mb-3">
                <input
                  type="text"
                  className={`form-control ${errors.userEmail ? 'is-invalid' : ''}`} {...register('userEmail')}
                  id="user_email"
                  value={userEmail ?? ""}
                  onChange={(e) => setUserEmail(e.target.value)}
                  placeholder="eg: abc123@xyz.com"
                />
                <div className="invalid-feedback">{errors.userEmail?.message}</div>
              </div>

              <div className="col-md-1 mb-3"></div>


              <div className="col-md-2 mb-3">
                <label htmlFor="max_user" className="form-label">
                  Max Allowed Users
                </label>
              </div>
              <div className="col-md-2 mb-3">
                <input
                  type="text"
                  className="form-control"
                  value={maxUser}
                  id="max_user"
                  disabled
                />
              </div>


              <div className="col-md-2 mb-3">
                <label htmlFor="existing_user" className="form-label">
                  Role Name
                </label>
              </div>
              <div className="col-md-5 mb-3">
                <Select
                  value={{ value: selectedRole, label: (selectedRole ? selectedRole : "Select Role Name") }}
                  inputId="role_name"
                  name="role_name"
                  options={RoleNameOptions}
                  onChange={handleInputChangeRoleName}
                />

              </div>


              <div className="col-md-1 mb-3"></div>
              <div className="col-md-2 mb-3">
                <label htmlFor="existing_user" className="form-label">
                  Existing Active Users
                </label>
              </div>
              <div className="col-md-2 mb-3">
                <input
                  type="text"
                  className="form-control"
                  value={existingUser}
                  id="existing_user"
                  disabled
                />
              </div>



              <div className="col-md-2 mb-3">
                <label htmlFor="user_name" className="form-label">
                  User Name
                </label>
              </div>
              <div className="col-md-5 mb-3">
                <input
                  type="text"
                  className={`form-control ${errors.userName ? 'is-invalid' : ''}`} {...register('userName')}
                  id="user_name"
                  value={userName ?? ""}
                  onChange={(e) => setUserName(e.target.value)}
                  placeholder="User Name"
                />
                <div className="invalid-feedback">{errors.userName?.message}</div>
              </div>

              <div className="col-md-1 mb-3"></div>
              <div className="col-md-2 mb-3">
                <label htmlFor="user_name" className="form-label">
                  Contact No.
                </label>
              </div>
              <div className="col-md-2 mb-3">
                <ReactPhoneInput enableSearch searchPlaceholder='Search Country' value={phone2}
                  placeholder='Enter Your Contact No.' onChange={(value) => setPhone2(value)} />

              </div>



              <div className="col-md-2 mb-3">
                <label htmlFor="password" className="form-label">
                  Password
                </label>
              </div>
              <div className="col-md-5 mb-3">
                <input
                  type={showPassword ? "text" : "password"}
                  className={`form-control ${errors.password ? 'is-invalid' : ''}`} {...register('password')}
                  value={password}
                  id="password"
                  onChange={(e) => setPassword(e.target.value)}
                />

                <div className='row mx-1'>
                  <div className="col-md-3 form-check mt-3">

                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                      checked={showPassword} onChange={() => setShowPassword(!showPassword)} />
                    <label className="form-check-label" for="flexCheckDefault">
                      Show Password
                    </label>

                  </div>
                  <div className="col-md-2 mt-3"></div>
                  <div className="col-md-1 mt-3">
                    <span title='Click to auto generate password' onClick={handleGeneratePassword} style={{ cursor: 'pointer' }}><AutorenewIcon /> </span>
                  </div>

                  <div className="col-md-6 mt-3">
                    <label className="form-check-label" for="flexCheckDefault">
                      Generate Password
                    </label>
                  </div>

                  <div className="col-md-1 mb-3"></div>

                </div>

                <div className="invalid-feedback">{errors.password?.message}</div>
              </div>
            </div>

            <div className='row'>
              <div className="col-md-2 mb-3">
                <label htmlFor="company_name" className="form-label">
                  Security Question 1
                </label>
              </div>
              <div className="col-md-3  mb-3">


                <label htmlFor="q1" className="form-label">
                  {q1}
                </label>

              </div>
              <div className="col-md-3  mb-3">

                <input
                  type="text"
                  className="form-control"
                  value={sq1}
                  id="q1"
                  onChange={(e) => setSq1(e.target.value)}
                />
              </div>
              {/* <div className="col-md-1 mb-3"></div> */}
              <div className="col-md-2 mb-3">

              </div>
              <div className="col-md-2 mb-3">
                {/* <input
                  type="text"
                  className="form-control"
                  value={company_id}
                  id="company_id"
                  disabled
                /> */}
              </div>
            </div>

            <div className='row'>
              <div className="col-md-2 mb-3">
                <label htmlFor="company_name" className="form-label">
                  Security Question 2
                </label>
              </div>
              <div className="col-md-3  mb-3">


                <label htmlFor="q2" className="form-label">
                  {q2}
                </label>

              </div>
              <div className="col-md-3  mb-3">
                <input
                  type="text"
                  className="form-control"
                  value={sq2}
                  id="q2"
                  onChange={(e) => setSq2(e.target.value)}
                />
              </div>
              {/* <div className="col-md-1 mb-3"></div> */}
              <div className="col-md-2 mb-3">

              </div>
              <div className="col-md-2 mb-3">
                {/* <input
                  type="text"
                  className="form-control"
                  value={company_id}
                  id="company_id"
                  disabled
                /> */}
              </div>

            </div>

            <div className='row'>
              <div className="col-md-2 mb-3">
                <label htmlFor="company_name" className="form-label">
                  Security Question 3
                </label>
              </div>
              <div className="col-md-3  mb-3">


                <label htmlFor="q3" className="form-label">
                  {q3}
                </label>

              </div>
              <div className="col-md-3  mb-3">

                <input
                  type="text"
                  className="form-control"
                  value={sq3}
                  id="q3"
                  onChange={(e) => setSq3(e.target.value)}
                />
              </div>
              {/* <div className="col-md-1 mb-3"></div> */}
              <div className="col-md-2 mb-3">
              </div>
              <div className="col-md-2 mb-3">
                {/* <input
                  type="text"
                  className="form-control"
                  value={company_id}
                  id="company_id"
                  disabled
                /> */}
              </div>
            </div>
          </div>
        </div>
        <div className='row pt-3'>
          {companyName ? (
            <>
              {/* <div className="col-md-2 mb-3"></div> */}
              <div className="col-md-5 mb-3" >
                <span style={{ color: '#1E1E1E', fontSize: '21px' }}>Location Access:</span>
                <table className="table text-center table-bordered text-center " >
                  <thead>
                    <tr >
                      <th scope="col" style={{ width: '120px' }} >Location Name</th>
                      <th scope="col" style={{ width: '70px' }} >Allocated</th>
                    </tr>
                  </thead>
                  <tbody >
                    {locationDetail.length > 0 &&
                      <tr>
                        <td>{"Select All"}</td>
                        <td scope="col" style={{ width: '70px' }}>
                          <input type="checkbox" className='form-check-input' checked={selectAllLocation} onChange={() => { handleAllLocationCheck() }} />
                        </td>
                      </tr>
                    }
                    {
                      locationDetail.length > 0 ? (
                        locationDetail.map((val) => (
                          <tr key={val._id}>
                            <td>{val.location_name}</td>
                            <td scope="col" style={{ width: '70px' }}>
                              <input type="checkbox" className='form-check-input' checked={checkedState[val.location_id]} onChange={() => handleLocationCheck(val.location_id)} />
                            </td>
                          </tr>
                        ))
                      ) : ""
                    }
                  </tbody>
                </table>
              </div>
            </>
          ) : ""}
        </div>
        <div className="row">
          <div className="col-1 mx-4"></div>
          <div className="col-4">
            {loading ? (
              <button className="btn btn-success" type="button" disabled>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: 5, fontSize:'12px' }}></span>
                Submit
              </button>
            ) : (
              <button type="submit" style={{fontSize:'12px'}} className="btn btn-success" >
                Submit
              </button>
            )}
          </div>
          <div className="col-4">
            {loading ? (
              <button className="btn btn-info" type="button" disabled>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: 5, fontSize:'12px' }}></span>
                Submit & Send Mail
              </button>

            ) : (
              <button type="submit" style={{fontSize:'12px'}} className="btn btn-info" onClick={() => setSubmitAndSendMail("Yes")}>
                Submit & Send Mail
              </button>

            )}
          </div>
          <div className="col-md-5"></div>

          <div className="col-3"></div>
        </div>
      </form>
      <br />
    </>
  );
}
