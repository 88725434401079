import React, { useState } from 'react'
import UpdateForm from './UpdateForm';
import { Typography } from '@mui/material';
import { Modal } from 'react-bootstrap'

export default function RolesSetup() {
    const [lgShow, setLgShow] = useState(false);

    return (
        <>
            <div className="row">
                <div className="row my-3 ">
                    <div className="col-md-6">
                        <Typography className="mx-3" variant='h6' sx={{ pb: 3 }}>
                            Access Setup
                        </Typography>
                    </div>

                    <div className="col-md-6 d-flex justify-content-end">
                        <button type="button" className="btn btn-danger" onClick={() => setLgShow(true)} style={{ height: "40px" }}  >Help</button>
                        <Modal
                            size="lg"
                            show={lgShow}
                            onHide={() => setLgShow(false)}
                            aria-labelledby="example-modal-sizes-title-lg">
                            <Modal.Header closeButton style={{ color: '#FFFFFF', backgroundColor: '#C62828' }}>
                                <Modal.Title id="example-modal-sizes-title-lg" >
                                    Instructions to Add/View/Edit Roles
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <h5>Create New Roles</h5>
                                <ol>
                                    <li>Select Account to give Access Rolewiser</li>
                                    <li>Select the Account Name from the dropdown</li>
                                    <li>Click on Create new button</li>
                                    <li>Enter Role Name</li>
                                    <li>Select the Show for from the dropdown</li>
                                    <li>Select the Access specifier and Account Access from the dropdown for all the modules shown below</li>
                                    <li>Click on Submit</li>
                                </ol>

                                <h5>Existing Records</h5>
                                <ol>
                                    <li>To view/Edit the existing Roles select the Account Name from the dropdown</li>
                                </ol>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            </div>

            <UpdateForm />
        </>
    );
}
