import React, { useEffect, useState } from 'react'
import { Alert } from '@mui/material';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import Select from "react-select";
// import { DatePicker } from 'antd';
import { baseURL } from '../backend_url';
// import moment from 'moment';
import ReactPhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/high-res.css';

export default function NewForm() {

  // const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const accountTypeOptions = [{ value: 'Corporate', label: 'Corporate' }, { value: 'Affiliate', label: 'Affiliate' }, { value: 'Franchise', label: 'Franchise' }, { value: 'Sub-Franchise', label: 'Sub-Franchise' }, { value: 'Sub-Affiliate', label: 'Sub-Affiliate' }]

  const [accountName, setAccountName] = useState()
  const [accountType, setAccountType] = useState()
  const [affiliateToOptions, setAffiliateToOptions] = useState([])
  const [affiliateTo, setAffiliateTo] = useState()
  const [contactName, setContactName] = useState()
  const [contactEmail, setContactEmail] = useState()
  const [contactPhone, setContactPhone] = useState('')
  const [noOfBranches, setNoOfBranches] = useState()
  const [noOfUsers, setNoOfUsers] = useState()
  const [contact2ndName, setContact2ndName] = useState()
  const [contact2ndEmail, setContact2ndEmail] = useState()
  const [contact2ndPhone, setContact2ndPhone] = useState();
  // const [blackOutFrom, setBlackOutFrom] = useState()
  // const [blackOutTo, setBlackOutTo] = useState()
  const [notes, setNotes] = useState();

  const [daysForMarkingNoShow, setDaysForMarkingNoShow] = useState(3);

  const [validationMsg, setValidationMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const [loading, setLoading] = useState(false);

  const [loggedInUserDetail, setLoggedInUserDetail] = useState({});

  // form validation rules 
  const validationSchema = Yup.object().shape({
    accountName: Yup.string()
      .required('Required')
      .min(3, 'Too short')
      .max(100, 'Too long'),

    contactName: Yup.string()
      .required('Required')
      .min(3, 'Too short')
      .max(100, 'Too long'),

    contactEmail: Yup.string()
      .email('Invalid Email ID')
      .required('Required'),

    noOfBranches: Yup.number()
      .required('Required')
      .typeError('Required')
      .min(1, 'Too small'),

    noOfUsers: Yup.number()
      .required('Required')
      .typeError('Required')
      .min(1, 'Too small'),

    contact2ndName: Yup.string()
      .nullable()
      .max(100, "Too long"),

    contact2ndEmail: Yup.string()
      .nullable()
      .email("Invalid Email ID")
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, setValue, formState: { errors } } = useForm(formOptions);

  useEffect(() => {
    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);
    setLoggedInUserDetail(foundUser);
  }, [])

  // get all corporate company names on account type = 'Affiliate'
  useEffect(() => {
    fetch(baseURL + '/get_corporate_companies', {
      method: 'GET',
      mode: 'cors',
      headers: { "Content-Type": "application/json" }
    })
      .then((resp) => resp.json())
      .then((res) => setAffiliateToOptions(res.map((value) => (
        { value: value.company_name, label: value.company_name }
      ))))
  }, [accountType])

  // set no. of users as no. of branches * 2 -> by default
  useEffect(() => {
    if (noOfBranches != undefined && noOfBranches != null && noOfBranches != '') {
      setNoOfUsers(noOfBranches * 2)
      setValue('noOfUsers', (noOfBranches * 2))
    }
    else {
      setNoOfUsers('')
      setValue('noOfUsers', '')
    }
  }, [noOfBranches])


  // submit data
  const submitHandler = () => {
    setSuccessMsg("");
    setLoading(true);
    setValidationMsg("");


    if (accountType === '' || (accountType === 'affiliate' && affiliateTo === '')) {
      setLoading(false)
      setValidationMsg("Please fill all mandatory fields to proceed")
      return
    }


    fetch(baseURL + '/save_company',
      {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({
          "company_id": null,
          "company_name": accountName,
          "account_type": accountType,
          "affiliate_to": accountType === 'Corporate' ? null : affiliateTo,
          "primary_contact_name": contactName,
          "primary_contact_email": contactEmail,
          "primary_contact_phone": contactPhone,
          "no_of_loc": noOfBranches,
          "no_of_users": noOfUsers,
          "secondary_contact_name": contact2ndName ?? null,
          "secondary_contact_email": contact2ndEmail ?? null,
          "secondary_contact_phone": contact2ndPhone ?? null,
          // "black_out_from": blackOutFrom == null ? null : new Date(blackOutFrom),
          // "black_out_to": blackOutTo == null ? null : new Date(blackOutTo),
          "notes": notes ?? null,
          // "days_for_marking_no_show": daysForMarkingNoShow,
          "updated_by": loggedInUserDetail.userEmail
        })
      })
      .then(resp => resp.json())
      .then(data => {
        setLoading(false)
        if (data === 'Company already exist') {
          setValidationMsg("Account already exist! Please check the Account Name and try again")
        }
        else {
          let msg = "Account Name - " + accountName.toString() + " created successfully with Account ID - " + data._id;
          setSuccessMsg(msg);
        }

        window.scrollTo(0, 0);
      })
      .catch(error => console.log(error))

    setAccountName('')
    setAccountType('')
    setAffiliateTo('')
    setContactName('')
    setContactEmail('')
    setContactPhone('')
    setNoOfBranches('')
    setNoOfUsers('')
    setContact2ndName('')
    setContact2ndEmail('')
    setContact2ndPhone('')
    // setBlackOutFrom('')
    // setBlackOutTo('')
    setNotes('')
    setDaysForMarkingNoShow(3);

    // setting value for Yup validations
    setValue('accountName', '')
    setValue('contactName', '')
    setValue('contactEmail', '')
    setValue('contactPhone', '')
    setValue('noOfBranches', '')
    setValue('noOfUsers', '')
    setValue('contact2ndName', '')
    setValue('contact2ndEmail', '')
    setValue('contact2ndPhone', '')
  }

  const handleInputChangeAffiliateTo = characterEntered => {
    setAffiliateTo(characterEntered.value);
  };

  const handleInputChangeAccountType = characterEntered => {
    setAccountType(characterEntered.value);
  };


  return (
    <>
      <div className="row pt-5" style={{fontSize:'12px', color:'black', fontWeight:'600'}}>
        <div className="col-11 mx-3">
          <form className="row g-3" onSubmit={handleSubmit(submitHandler)}>

            {validationMsg ?
              (
                <Alert severity="error">{validationMsg}</Alert>
              ) : ("")
            }
            {successMsg ?
              (
                <div className="alert alert-success alert-dismissible fade show" role="alert">
                  {successMsg}
                  <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setSuccessMsg('')}></button>
                </div>
              )
              : ""
            }
            <div className="row mt-3">
              <div className="col-md-2 mb-1">
                <label htmlFor="accountName" className="form-label" style={{ marginTop: 5 }}>
                  Account Name *
                </label>
              </div>
              <div className="col-md-3 mb-1">
                <input type="text" className={`form-control ${errors.accountName ? 'is-invalid' : ''}`} {...register('accountName')} id="accountName" value={accountName} onChange={(e) => setAccountName(e.target.value)} />
                <div className="invalid-feedback">{errors.accountName?.message}</div>
              </div>
              <div className="col-md-1 mb-1"> </div>
              <div className="col-md-2 mb-1">
                {/* <label htmlFor="accountID" className="form-label" style={{ marginTop: 5 }}>
                  Account ID
                </label> */}
              </div>
              <div className="col-md-3 mb-1">
                {/* <input type="text" className='form-control' disabled style={{ width: 100 }} /> */}
              </div>
              <div className="col-md-1 mb-1"> </div>
            </div>
            <div className="row pt-4">
              <div className="col-md-2 mb-1">
                <label htmlFor="accountType" className="form-label" style={{ marginTop: 5 }}>
                  Account Type *
                </label>
              </div>
              <div className="col-md-3 mb-1">
                <Select
                  name="form-field-name"
                  value={{ value: accountType, label: (accountType ? accountType : "Select") }}
                  options={accountTypeOptions}
                  onChange={handleInputChangeAccountType}
                  placeholder="Select"
                />
              </div>
              <div className="col-md-7 mb-1"> </div>
            </div>
            {
              accountType !== 'Corporate' && accountType ?
                <div className="row pt-4">
                  <div className="col-md-2 mb-1">
                    <label htmlFor="affiliateTo" className="form-label" style={{ marginTop: 5 }}>
                      {accountType.toString() + "d To *"}
                    </label>
                  </div>

                  <div className="col-md-3 mb-1">
                    <Select
                      name="form-field-name"
                      value={{ value: affiliateTo, label: (affiliateTo ? affiliateTo : "Select") }}
                      options={affiliateToOptions}
                      onChange={handleInputChangeAffiliateTo}
                      placeholder="Select"
                    />
                  </div>

                  <div className="col-md-7 mb-1"> </div>
                </div> : ''}


            <div className="row pt-4">
              <div className="col-md-2 mb-1">
                <label htmlFor="contactName" className="form-label" style={{ marginTop: 5 }}>
                  Primary Contact Name*
                </label>
              </div>
              <div className="col-md-3 mb-1">
                <input type="text" className={`form-control ${errors.contactName ? 'is-invalid' : ''}`} {...register('contactName')} id="contactName" value={contactName} onChange={(e) => setContactName(e.target.value)} />
                <div className="invalid-feedback">{errors.contactName?.message}</div>
              </div>
              <div className="col-md-1 mb-1"> </div>
              <div className="col-md-2 mb-1">
                <label htmlFor="contactEmail" className="form-label" style={{ marginTop: 5 }}>
                  Primary Contact Email *
                </label>
              </div>
              <div className="col-md-3 mb-1">
                <input type="text" className={`form-control ${errors.contactEmail ? 'is-invalid' : ''}`} {...register('contactEmail')} id="contactEmail" value={contactEmail} onChange={(e) => setContactEmail(e.target.value)} />
                <div className="invalid-feedback">{errors.contactEmail?.message}</div>
              </div>
              <div className="col-md-1 mb-1"> </div>
            </div>
            <div className="row pt-4">
              <div className="col-md-2 mb-1">
                <label htmlFor="contactPhone" className="form-label">
                  Primary Contact Phone Number *
                </label>
              </div>
              <div className="col-md-3 mb-1">
                <ReactPhoneInput enableSearch searchPlaceholder='Search Country' value={contactPhone} placeholder='' onChange={(value) => setContactPhone(value)}
                  inputProps={{
                    name: 'contactPhone',
                    required: true,
                    style: { width: '101%' } 
                  }} />
              </div>
              <div className="col-md-6 mb-1"> </div>
            </div>
            <div className="row pt-4">
              <div className="col-md-2 mb-1">
                <label htmlFor="contact2ndName" className="form-label" style={{ marginTop: 5 }}>
                  Secondary Contact Name
                </label>
              </div>
              <div className="col-md-3 mb-1">
                <input type="text" className={`form-control ${errors.contact2ndName ? 'is-invalid' : ''}`} {...register('contact2ndName')} id="contact2ndName" value={contact2ndName} onChange={(e) => setContact2ndName(e.target.value)} />
                <div className="invalid-feedback">{errors.contact2ndName?.message}</div>
              </div>
              <div className="col-md-1 mb-1"> </div>
              <div className="col-md-2 mb-1">
                <label htmlFor="contact2ndEmail" className="form-label" style={{ marginTop: 5 }}>
                  Secondary Contact Email
                </label>
              </div>
              <div className="col-md-3 mb-1">
                <input type="text" className={`form-control ${errors.contact2ndEmail ? 'is-invalid' : ''}`} {...register('contact2ndEmail')} id="contact2ndEmail" value={contact2ndEmail} onChange={(e) => setContact2ndEmail(e.target.value)} />
                <div className="invalid-feedback">{errors.contact2ndEmail?.message}</div>
              </div>
              <div className="col-md-1 mb-1"> </div>
            </div>
            <div className="row pt-4">
              <div className="col-md-2 mb-1">
                <label htmlFor="contactPhone" className="form-label">
                  Secondary Contact Phone Number
                </label>
              </div>
              <div className="col-md-3 mb-1">
                <ReactPhoneInput enableSearch searchPlaceholder='Search Country' value={contact2ndPhone} placeholder='' onChange={(value) => setContact2ndPhone(value)}
                inputProps={{
                  // name: 'contactPhone',
                  // required: true,
                  style: { width: '101%' } 
              }} />
              </div>
              <div className="col-md-7 mb-1"> </div>
            </div>
            <div className="row pt-4">
              <div className="col-md-2 mb-1">
                <label htmlFor="noOfBranches" className="form-label" style={{ marginTop: 5 }}>
                  Number of Locations *
                </label>
              </div>
              <div className="col-md-3 mb-1">
                <input type="number" className={`form-control ${errors.noOfBranches ? 'is-invalid' : ''}`} {...register('noOfBranches')} id="noOfBranches" value={noOfBranches} onChange={(e) => setNoOfBranches(e.target.value)} style={{ width: 100 }} />
                <div className="invalid-feedback">{errors.noOfBranches?.message}</div>
              </div>
              <div className="col-md-1 mb-1"> </div>
              <div className="col-md-2 mb-1">
                <label htmlFor="noOfUsers" className="form-label" style={{ marginTop: 5 }}>
                  Number of Users *
                </label>
              </div>
              <div className="col-md-3 mb-1">
                <input type="number" className={`form-control ${errors.noOfUsers ? 'is-invalid' : ''}`} {...register('noOfUsers')} id="noOfUsers" value={noOfUsers} onChange={(e) => setNoOfUsers(e.target.value)} style={{ width: 100 }} />
                <div className="invalid-feedback">{errors.noOfUsers?.message}</div>
              </div>
            </div>
            {/* <div className="row pt-4">
              <div className="col-md-2 mb-1">
                <label htmlFor="blackOutFrom" className="form-label" style={{ marginTop: 5 }}>
                  Black Out From
                </label>
              </div>

              <div className="col-md-3 mb-1">
                <DatePicker size="large" format='MM-DD-yyyy' placeholder='mm-dd-yyyy' onChange={(date, dateString) => {
                  setBlackOutFrom(date)
                }} value={blackOutFrom ? moment(blackOutFrom) : ''} disabledDate={(current) => {
                  if (blackOutTo) {
                    return current && current.valueOf() > (blackOutTo.valueOf() + (1 * 24 * 60 * 60)) || current.valueOf() < (new Date().valueOf() - (1000 * 24 * 60 * 60))
                  }
                  else {
                    return current && current.valueOf() < (new Date().valueOf() - (1 * 24 * 60 * 60))
                  }
                }} style={{ width: '100%' }} />
              </div>

              <div className="col-md-1 mb-1"> </div>

              <div className="col-md-2 mb-1">
                <label htmlFor="blackOutTo" className="form-label" style={{ marginTop: 5 }}>
                  Black Out To
                </label>
              </div>

              <div className="col-md-3 mb-1">
                <DatePicker size="large" format='MM-DD-yyyy' placeholder='mm-dd-yyyy' onChange={(date, dateString) => {
                  setBlackOutTo(date)
                }} value={blackOutTo ? moment(blackOutTo) : ''} disabledDate={(current) => {
                  if (blackOutFrom) {
                    return current && current.valueOf() < blackOutFrom.valueOf()
                  }
                  else {
                    return current && current.valueOf() < (new Date().valueOf() - (1 * 24 * 60 * 60))
                  }
                }} style={{ width: '100%' }} />
              </div>

              <div className="col-md-1 mb-1"> </div>
            </div> */}
            <div className="row pt-4">
              {/* <div className="col-md-2 mb-1">
                <label htmlFor="daysForMarkingNoShow" className="form-label">
                  # Days for Marking no Show *
                </label>
              </div> */}
              {/* <div className="col-md-1 mb-1">
                <input
                  type="number"
                  min={1}
                  className="form-control"
                  value={daysForMarkingNoShow}
                  id="daysForMarkingNoShow"
                  onChange={(e) => setDaysForMarkingNoShow(e.target.value)}
                  required
                />
              </div> */}
            </div>
            <div className="row pt-4">
              <div className="col-md-2 mb-1">
                <label htmlFor="notes" className="form-label" style={{ marginTop: 10 }}>
                  Notes/Comments
                </label>
              </div>
              <div className="col-md-3 mb-1">
                <textarea name='notes' rows={2} style={{ width: '100%' }} value={notes} onChange={(e) => setNotes(e.target.value)} />
              </div>
              <div className="col-md-7 mb-1"> </div>
            </div>
            <div className="row pt-4">
              <div className="col d-flex justify-content-end">
                {loading ?
                  <button className="btn btn-success" type="button" disabled>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: 5, fontSize:'12px' }}></span>
                    Submit
                  </button> :
                  <button type="submit"  style={{fontSize:'12px'}}  className="btn btn-success" >
                    Submit
                  </button>
                }
              </div>
            </div>
          </form>
          <br/><br/>
        </div>
      </div>
    </>
  );
}