import React, { useState, useEffect } from 'react'
import { Button, Typography } from '@mui/material';
import { Modal } from 'react-bootstrap'
import UpdateForm from './UpdateForm'

export default function Cars() {

  const [lgShow, setLgShow] = useState(false);
  const [accessType, setAccessType] = useState('');

    useEffect(() => {
        const loggedInUser = sessionStorage.getItem("user_token");
        const foundUser = JSON.parse(loggedInUser);
        console.log('foundUser cars',foundUser)
        if (foundUser.role === 'SuperAdmin') {
          setAccessType('Edit');
          console.log('im car edit')
        }
        else { 
          setAccessType(foundUser.access['Cars_Access Specifier']);
          console.log('im car access')
          console.log(foundUser.access['Cars_Access Specifier'])
        }
      }, []);


  return (
    <>

      <div className="row my-3 ">
        <div className="col-md-6">
          {/* <Typography className="mx-3" variant='h6' sx={{ pb: 3 }}>
            Cars - Master
          </Typography> */}
          <Typography className="mx-3" variant='h6' sx={{ pb: 3 }} style = {{marginTop:'5px', fontSize:'20px'}}>
           <b>Cars - Master</b>
          </Typography>
        </div>
        <div className="col-md-6 d-flex justify-content-end"   >

          <button type="button" className="btn btn-danger" onClick={() => setLgShow(true)} style={{ height: "40px" }}  >Help</button>
          <Modal
            size="lg"
            show={lgShow}
            onHide={() => setLgShow(false)}
            aria-labelledby="example-modal-sizes-title-lg"

          >
            <Modal.Header closeButton style={{ color: '#FFFFFF', backgroundColor: '#C62828' }}>
              <Modal.Title id="example-modal-sizes-title-lg" >
                Instructions to Add/Edit Cars
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ol>
                <li>Select Account Name
                </li>
                <li>Select Country
                </li>
                <li>Click on Fetch Button
                </li>
                <li>Click On Add New Button
                </li>
                <li>Select the Car Class from the dropdown and from uplpoad image feild choose the car image which you want to upload
                </li>
                <li>Note : Upload Image should be JPG or JPEG format
                </li>
                <li>Click on the save Button
                </li>
              </ol>

              <h5>Existing Cars:
              </h5>
              <ul>
                <li>To view/edit/Download the existing Car Classes select the Account Name and Country name from the dropdown.
                </li>
              </ul>


            </Modal.Body>
          </Modal>

        </div>
      </div>


      {/* <div className="col-4 d-flex justify-content-end px-5">
        
          
         
        </div> */}




{accessType === 'View' ? <UpdateForm accessType={accessType} /> : <UpdateForm />}





    </>
  );
}
