import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import "antd/dist/antd.css";
import { DatePicker } from "antd";
import Swal from "sweetalert2";
import moment from "moment";
import { Calendar } from 'antd';
// import MultiRangeSlider from "multi-range-slider-react";
import RangeSlider from "react-bootstrap-range-slider";
import axios from "axios";
import { baseURL } from "../backend_url";
import { formatDate, formatDate_withTime } from "../commonFunctions";
// import { MDBDataTable } from 'mdbreact';
import { Alert } from "@mui/material";
import Button from "@mui/material/Button";
import { Preview } from "@mui/icons-material";
import { MDBDataTable } from "mdbreact";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdbreact";
import { isEmpty } from 'lodash';
import './MinimumRateMapping.css';


const options = [
  { value: "Select SIP", label: "Select SIP" },
  { value: "ECAR", label: "ECAR" },
  { value: "CCAR", label: "CCAR" },
];

export default function DataTable({ accessType }) {

  const [columnData, setColumnData] = useState([]);
  const [fromDate, setFromDate] = useState(new Date())
  const [toDate, setToDate] = useState(new Date())
  const [rowsData, setRowsData] = useState([]);
  const [rateInfoDetails, setRateInfoDetails] = useState([]);
  const [range, setRange] = useState({ min: 10, max: 100 });
  const [selectedOption, setSelectedOption] = useState("");
  const [locationDetail, setLocationDetail] = useState([{}]);
  const [locationName, setLocationName] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [temp_company_id, settemp_company_id] = useState("");
  const [sippCodeList, setSippCodeList] = useState([]);
  const [selectedSippCode, setSelectedSippCode] = useState("");
  const [sippCodeOptions, setSippCodeOptions] = useState("");
  const [competesSippCodeList, setCompetesSippCodeList] = useState([]);
  const [selectedCompetesSippCode, setSelectedCompetesSippCode] = useState("");
  const [numDropdowns, setNumDropdowns] = useState(1);
  const [companyName, setCompanyName] = useState("");
  const [loggedInUserDetail, setLoggedInUserDetail] = useState({});
  const [locationId, setLocationId] = useState("");
  const [company_id, setcompany_id] = useState("");
  const [companyDetail, setcompanyDetail] = useState([{}]);
  const [competitorsGrid, setCompetitorsGrid] = useState([
    ["", "1", "1", "1", "1", "1", "1"],
  ]);
  const [availableCarClasses, setAvailableCarClasses] = useState([{}]);
  const daysNameTable = [['Monday', 'Tuesday', 'Wednesday', 'Thursday'], ['Friday', 'Saturday', 'Sunday']]
  const [daysNameHash, setDaysNameHash] = useState({ "Monday": false, "Tuesday": false, 'Wednesday': false, 'Thursday': false, 'Friday': false, 'Saturday': false, 'Sunday': false })
  const [selectAllDaysFlag, setSelectAllDaysFlag] = useState(true)

  const changeAllDaysCheckBox = () => {
    let temp = { ...daysNameHash }
    if (selectAllDaysFlag === true) {
        setSelectAllDaysFlag(false)
        let keys = Object.keys(temp)
        for (let i = 0; i < keys.length; i++) {
            temp[keys[i]] = false
        }
    }
    else {
        setSelectAllDaysFlag(true)
        let keys = Object.keys(temp)
        for (let i = 0; i < keys.length; i++) {
            temp[keys[i]] = true
        }
    }
    setDaysNameHash(temp)
    // setPreloadFlag(true)
  }

  const handleFromDateChange = (date) => {
    setFromDate(date);
    if (date && (!toDate || date.isAfter(toDate))) {
      setToDate(date);
    }
  };

  const changeDaysCheckBox = (key) => {
    let temp = { ...daysNameHash };
    let status = temp[key]
    temp[key] = !status

    setDaysNameHash(temp);
  }


  //Option for Location name
  // const locationNameOptions = locationDetail.map((value) => ({
  //   value: value.location_name,
  //   label: value.location_name,
  // }));

  const locationNameOptions = locationDetail.map((value) => (
    { value: value.location_name, label: value.location_name === undefined ? value.location_name : value.location_name + " - " + value.location_id.toString().trim().split("-")[1] }
  ));

  const handleInputChangeLocationName = (characterEntered) => {
    // const filtredId = locationDetail.filter((item) => item.)
    console.log(characterEntered);
    setLocationName(characterEntered.value);
    // setCompanyId();
  };

  // const handleInputChangeSippCode = (characterEntered, i) => {

  const getTheDataByDateandLocation = () => {
    let from_date = ''
    let temp_location_id = ''
    if (locationName != "") {
      from_date = new Date(fromDate).toDateString()
      setNumDropdowns(1);
      setCompetitorsGrid([["", "1", "1", "1", "1", "1", "1"]]);
      temp_location_id = locationDetail.filter((loc) => (loc.location_name === locationName)).map((val) => val.location_id)
      setLocationId(temp_location_id);

      axios
        .get(baseURL + "/get_all_minumum_rate_coll/" + locationId + "*" + from_date)
        .then((res) => {
          console.log(res)
          if(res.data !== null){
            setNumDropdowns(res.data.selected_items.length);
            setCompetitorsGrid(res.data.selected_items);
            const usedCarClasses = res.data.selected_items
              .filter(row => row[0].value)
              .map(row => row[0].value);
            const newAvailableCarClasses = selectedSippCodeNameOptions.filter(
              option => !usedCarClasses.includes(option.value)
            );
            // Add back deselected car classes to newAvailableCarClasses
            res.data.selected_items.forEach(row => {
              if (!row[0].value) {
                newAvailableCarClasses.push({ value: row[0].label, label: row[0].label });
              }
            });
            setAvailableCarClasses(newAvailableCarClasses.filter(option => option.value !== 'undefined'));
          }
        });
    } else {
      Swal.fire("Please select the Location")
    }
  }


  const handleInputChangeSippCode = (characterEntered, i) => {
    let arr1 = [...competitorsGrid];
    arr1[i][0] = characterEntered;

    const usedCarClasses = arr1
      .filter((row, index) => index !== i && row[0].value)
      .map(row => row[0].value);
    if (characterEntered) {
      usedCarClasses.push(characterEntered.value);
    }
    const newAvailableCarClasses = selectedSippCodeNameOptions.filter(
      option => !usedCarClasses.includes(option.value)
    );
    if (!characterEntered) {
      newAvailableCarClasses.push({ value: arr1[i][0].value, label: arr1[i][0].value });
    }
    setCompetitorsGrid(arr1);
    setAvailableCarClasses(newAvailableCarClasses);
  };

  const handleInputChangeCompanyName = characterEntered => {
    setCompanyName(characterEntered.value);
  };

  // const handleInputChangeMinCappedDaily = (characterEntered, i) => {
  //   if (/^\d*$/.test(characterEntered)){
  //     let arr1 = [...competitorsGrid];
  //     arr1[i][1] = characterEntered;
  //     setCompetitorsGrid(arr1);
  //   }
  // };

  const handleInputChangeMinCappedDaily = (characterEntered, i) => {
    // Allow digits with an optional decimal point followed by more digits
    if (/^\d*\.?\d*$/.test(characterEntered)) {
        let arr1 = [...competitorsGrid];
        arr1[i][1] = characterEntered;
        setCompetitorsGrid(arr1);
    }
};

  const handleInputChangeMinCappedWeekly = (characterEntered, i) => {
    if (/^\d*\.?\d*$/.test(characterEntered)) {
      let arr1 = [...competitorsGrid];
      arr1[i][2] = characterEntered;
      setCompetitorsGrid(arr1);
    }  
  };

  const handleInputChangeMinCappedXDay = (characterEntered, i) => {
    if (/^\d*\.?\d*$/.test(characterEntered)) {
      let arr1 = [...competitorsGrid];
      arr1[i][3] = characterEntered;
      setCompetitorsGrid(arr1);
    }  
  };

  const handleInputChangeMaxCappedDaily = (characterEntered, i) => {
    if (/^\d*\.?\d*$/.test(characterEntered)) {
      let arr1 = [...competitorsGrid];
      arr1[i][4] = characterEntered;
      setCompetitorsGrid(arr1);
    }  
  };

  const handleInputChangeMaxCappedWeekly = (characterEntered, i) => {
    if (/^\d*\.?\d*$/.test(characterEntered)) {
      let arr1 = [...competitorsGrid];
      arr1[i][5] = characterEntered;
      setCompetitorsGrid(arr1);
    }  
  };

  const handleInputChangeMaxCappedXDay = (characterEntered, i) => {
    if (/^\d*\.?\d*$/.test(characterEntered)) {
      let arr1 = [...competitorsGrid];
      arr1[i][6] = characterEntered;
      setCompetitorsGrid(arr1);
    }  
  };

  // const handleInputChangeDailyRate = (characterEntered, i) => {
  //   let arr1 = [...competitorsGrid];
  //   arr1[i][4] = characterEntered;
  //   setCompetitorsGrid(arr1);
  // };

  const selectedSippCodeNameOptions = sippCodeList.map((value) => ({
    value: value.SIPP_CODE,
    label: value.SIPP_CODE,
  }));

  console.log("selectedSippCodeNameOptions");
  console.log(selectedSippCodeNameOptions);


  const companyNameOptions = companyDetail.map((value) => (
    { value: value.company_name, label: value.company_name }
  ));

  useEffect(() => {
    if (companyDetail && companyName) {

      let obj_company_detail = companyDetail.filter(
        (value) => value.company_name === companyName
      )[0];
      let temp_company_id = obj_company_detail._id;
      let company_type = obj_company_detail.account_type;

      setcompany_id(temp_company_id);
      setLocationName('')
      setLocationId('')
      setLocationDetail([{}])
      setNumDropdowns(1);
      setCompetitorsGrid([["", "1", "1", "1", "1", "1", "1"]]);
      //API call for getting data from DB
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };

      fetch(baseURL + "/get_company_location/" + temp_company_id, requestOptions)
        .then((res) => res.json())
        .then((data) => {

          let allTemp = data.res;
          console.log("allTemp");
          console.log(allTemp);
          if (loggedInUserDetail.role === 'SuperAdmin') {
            if (allTemp.length > 0) {
              setLocationDetail(allTemp);
            }
          }
          else if (company_type === 'Affiliate' && loggedInUserDetail.account_type.account_type === 'Corporate') {

            if (allTemp.length > 0) {
              setLocationDetail(allTemp);
            }
          }
          else {
            axios.post(baseURL + "/get_individual_user_details", { 'user_email': loggedInUserDetail.userEmail })
              .then((data) => {
                console.log("data");
                console.log(data);
                if (allTemp.length > 0) {
                  allTemp = allTemp.filter((val) => val !== undefined && data.data[0].location_allocated[val.location_id]);
                  setLocationDetail(allTemp);
                }
              }
              )
          }
        });
    }
  }, [companyName]);

  useEffect(() => {
    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);
    setLoggedInUserDetail(foundUser);
    console.log(foundUser.role);

    if (foundUser.role === 'SuperAdmin') {

      axios.get(baseURL + "/get_company_details_for_users")
        .then((res) => {
          setcompanyDetail(res.data);
        });
    }
    else {
      console.log("im heree")
      fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['Min/MaxSettings_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
        .then((res) => res.json())
        .then((data) => {

          setcompanyDetail(data);
        });
    }
  }, []);

  //-------------SIPP Codes based on the selected location-------

  useEffect(() => {
    if (locationName != "") {
      let temp_location_id = ''
      if (locationName && locationDetail) {
          temp_location_id = locationDetail.filter((loc) => (loc.location_name === locationName)).map((val) => val.location_id)
          setLocationId(temp_location_id);
      }
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };
      fetch(
        baseURL + "/get_existing_fleet_setup/" + temp_location_id,
        requestOptions
      )
        .then((res) => res.json())
        .then((data) => {
          let allTemp = data;
          setSippCodeList(allTemp);
          setCompetesSippCodeList(allTemp);
        });

    }
  }, [locationName]);

  useEffect(() => {
    setAvailableCarClasses([...selectedSippCodeNameOptions]);
  }, [sippCodeList]);

  const options = [
    { id: 1, value: "option1", label: "Option 1" },
    { id: 2, value: "option2", label: "Option 2" },
    { id: 3, value: "option3", label: "Option 3" },
  ];

  const options1 = [
    { id: 1, value: "Active", label: "Active" },
    { id: 2, value: "IN - Active", label: "In - Active" },
  ];

  const handleAddDropdown = () => {
    setNumDropdowns(numDropdowns + 1);
    let arr1 = [...competitorsGrid];
    arr1.push(["", "1", "1", "1", "1", "1", "1"]);
    setCompetitorsGrid(arr1);
  };

  // const handleKeyDown = (e) => {
  //   // Prevent invalid characters
  //   if (!/^\d$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
  //     e.preventDefault();
  //   }
  // };

  const handleKeyDown = (e) => {
    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
    const isDigit = /^\d$/.test(e.key);
    const isDecimal = e.key === '.';
    const alreadyHasDecimal = e.target.value.includes('.');
  
    if (!isDigit && !allowedKeys.includes(e.key) && (!isDecimal || alreadyHasDecimal)) {
      e.preventDefault();
    }
  };

  // const handlePaste = (e) => {
  //   const paste = e.clipboardData.getData('text');
  //   if (!/^\d*$/.test(paste)) {
  //     e.preventDefault();
  //   }
  // };

  const handlePaste = (e) => {
    const paste = e.clipboardData.getData('text');
    if (!/^\d*\.?\d*$/.test(paste)) {
      e.preventDefault();
    }
  };

  const handleSubmit = async () => {
    if (companyName === "") {
      Swal.fire('Please select Account Name');
      return;
    }

    if (locationName === "") {
      Swal.fire('Please select a Location to proceed');
      return;
    }

    console.log(competitorsGrid);
    let isValid = true;
    const firstRow = competitorsGrid[0];
    if (firstRow[0] === '') {
      Swal.fire(`Please Select a SIPP Code for the first row`);
      return;
    }
    const columnLabels = ["SIPP CODE", "Min Capped Daily", "Min Capped Weekly", "Min Capped X-Day", "Max Capped Daily", "Max Capped Weekly", "Max Capped X-Day"];

    for (let colIndex = 1; colIndex < firstRow.length; colIndex++) {
      const cellValue = firstRow[colIndex];
      if (!cellValue || cellValue.trim() === '') {
        isValid = false;
        Swal.fire(`For Row 1, the ${columnLabels[colIndex]} has an empty value. Please fill it.`);
        return;
      }
    }

    if (isValid) {
      for (let rowIndex = 0; rowIndex < competitorsGrid.length; rowIndex++) {
        const row = competitorsGrid[rowIndex];
        if (row[0]["value"]) {
          for (let colIndex = 1; colIndex < row.length; colIndex++) {
            const cellValue = row[colIndex];
            if (!cellValue || cellValue.trim() === '') {
              isValid = false;
              Swal.fire(`In Row ${rowIndex + 1}, for ${columnLabels[0]} as ${row[0]["value"]} the ${columnLabels[colIndex]} has an empty value. Please fill it.`);
              return;
            }
          }
        }
      }
    }
    const filteredArray = competitorsGrid.filter(innerArray => {
      const firstValue = innerArray[0];
      return firstValue !== undefined && firstValue !== '';
    });
    const body_payload = {
      location_name: locationName,
      location_id: locationId.toString(),
      selected_items: filteredArray,
      from_date: new Date(fromDate).toDateString(),
      to_date: new Date(toDate).toDateString(),
      created_by: loggedInUserDetail.userEmail,
      updated_by: loggedInUserDetail.userEmail,
      created_date: new Date(),
      updated_date: new Date()
    };

    axios.post(baseURL + "/save_minumum_rate", body_payload).then((res) => {
      Swal.fire({
        position: "middle",
        icon: "success",
        title: "Submitted Successfully",
        showConfirmButton: false,
        timer: 3000,
      });
    });
  };

  useEffect(() => {
    let temp_location_id = ''
    if (locationName != "") {
      // setNumDropdowns(1);
      // setCompetitorsGrid([["", "", "","", ""]]);
      temp_location_id = locationDetail.filter((loc) => (loc.location_name === locationName)).map((val) => val.location_id)
      setLocationId(temp_location_id);

      // axios
      //   .get(baseURL + "/get_all_minumum_rate_coll/" + locationName)
      //   .then((res) => {
      //     setNumDropdowns(res.data.selected_items.length);
      //     setCompetitorsGrid(res.data.selected_items);
      //     const usedCarClasses = res.data.selected_items
      //     .filter(row => row[0].value)
      //     .map(row => row[0].value);

      //   const newAvailableCarClasses = selectedSippCodeNameOptions.filter(
      //     option => !usedCarClasses.includes(option.value)
      //   );
      //   // Add back deselected car classes to newAvailableCarClasses
      //   res.data.selected_items.forEach(row => {
      //     if (!row[0].value) {
      //       newAvailableCarClasses.push({ value: row[0].label, label: row[0].label });
      //     }
      //   });
      //   setAvailableCarClasses(newAvailableCarClasses.filter(option => option.value !== 'undefined' ));
      //   console.log("im at inside availableCarClasses");
      //   console.log(availableCarClasses);
      //   });
    }
  }, [locationName, sippCodeList]);

  // const handleClear = () => {
  //   console.log('cometitorsGrid',competitorsGrid)
  //   // setNumDropdowns(1);
  //   // setCompetitorsGrid([["", "1", "1", "1", "1", "1", "1"]]);
  // };
  const handleClear = () => {
    // setNumDropdowns(1);
  
    setCompetitorsGrid((prevGrid) => {
      return prevGrid.map((row) => {
        if (row.length > 0) {
          return [row[0], "1", "1", "1", "1", "1", "1"];
        }
        return row;
      });
    });
  };

  return (
    <div className="row" style={{backgroundColor:'#cfe2f3', marginLeft:'-32px'}}>
      <div className="col-md-12">
        <div className="row pt-2" >
          <div className="col-md-2 mb-3">
            <label htmlFor="company_name" className="form-label" style={{ marginTop: 5, marginLeft:'18px',fontSize:'12px' }}>
              <b>Account Name</b>
            </label>
          </div>
          <div className="col-md-3 mb-3" style={{marginLeft: '-63px'}}>
            <Select
              value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
              inputId="company_name"
              name="company_name"
              options={companyNameOptions}
              onChange={handleInputChangeCompanyName}
            />
          </div>
          <div className="col-md-2 mb-3">
            <label htmlFor="locationName" className="form-label" style={{ marginTop: 5, marginLeft:'58px',fontSize:'12px'  }}>
              <b>Location Name</b>
            </label>
          </div>
          <div className="col-md-3 mb-3">
            <Select
              value={{ value: locationName, label: (locationName ? locationName : "Select Location Name") }}
              inputId="locationName"
              name="locationName"
              options={locationNameOptions}
              onChange={handleInputChangeLocationName}
            />
          </div>
        </div>
        <div className="row" style={{ display: 'flex', verticalAlign: 'middle', alignItems: 'center', marginTop: '10px' }}>
          <div className="col-md-2 mb-3" style={{ marginLeft: '18px' }}>
          <label htmlFor="locationName" className="form-label" style={{ fontSize: '12px' }}>
            <b>From Date :</b>
          </label>
        </div>
        <div className="col-md-3 mb-3" style={{ marginLeft: '-80px' }}>
          <DatePicker
            size="large"
            format="MM-DD-YYYY"
            placeholder="mm-dd-yyyy"
            onChange={handleFromDateChange}
            disabledDate={(current) => {
              return current && current.valueOf() < (new Date().valueOf() - (1000 * 24 * 60 * 60));
            }}
            style={{ width: '100%', position: 'relative' }}
            value={fromDate ? moment(fromDate.valueOf()) : null}
          />
        </div>
        <div className="col-md-2 mb-3">
          <label htmlFor="locationName" className="form-label" style={{ marginLeft: '58px', fontSize: '12px' }}>
            <b>To Date*</b>
          </label>
        </div>
        <div className="col-md-3 mb-3">
          <DatePicker
            size="large"
            format="MM-DD-YYYY"
            placeholder="mm-dd-yyyy"
            disabledDate={(current) => {
              return current && current.valueOf() < (fromDate ? fromDate.valueOf() : (new Date().valueOf() - (1000 * 24 * 60 * 60)));
            }}
            onChange={(date) => setToDate(date)}
            style={{ width: '100%' }}
            value={toDate ? moment(toDate.valueOf()) : null}
          />
        </div>
            {/* <div className="col-md-2 mb-3" style={{marginLeft:'18px'}}>
              <label htmlFor="locationName" className="form-label" style= {{fontSize:'12px'}}>
                <b> From Date :</b>
              </label>
            </div>
            <div className="col-md-3 mb-3" style={{marginLeft:'-80px'}}>
              <DatePicker size="large" format='MM-DD-YYYY' placeholder='mm-dd-yyyy' onChange={(date, dateString) => {
                setFromDate(date);
              }}
                disabledDate={(current) => {
                  if (toDate && fromDate.valueOf() !== toDate.valueOf()) {
                    return current && current.valueOf() > (toDate.valueOf() + (1 * 24 * 60 * 60)) || current.valueOf() < (new Date().valueOf() - (1000 * 24 * 60 * 60))
                  }
                  else {
                    return current && current.valueOf() < (new Date().valueOf() - (1000 * 24 * 60 * 60))
                  }
                }}
                style={{ width: '100%', position: 'relative' }}
                value={moment(fromDate.valueOf())}
              />
            </div>
          <div className="col-md-2 mb-3">
              <label htmlFor="locationName" className="form-label" style={{ marginLeft:'58px',fontSize:'12px'  }}>
                <b> To Date*</b>
              </label>
            </div>
            <div className="col-md-3 mb-3">
              <DatePicker size="large" format='MM-DD-YYYY' placeholder='mm-dd-yyyy' disabledDate={(current) => {
                if (fromDate) {
                  return current && current.valueOf() < fromDate.valueOf();
                }
                else {
                  return current && current.valueOf() < new Date().valueOf() - (1000 * 24 * 60 * 60);
                }
              }} onChange={(date, dateString) => setToDate(date)} style={{ width: '100%' }}
                value={moment(toDate.valueOf())}
              />
            </div> */}
          
          <div className="col-md-1 mb-3">
            <button
              type="submit"
              className="btn btn-success"
              onClick={getTheDataByDateandLocation}
              style={{
                maxWidth: "150px",
                maxHeight: "100px",
                marginLeft: "40px",
                fontSize:'12px',
                // marginTop: "-310px",
                // marginLeft: "15px",
                backgroundColor: " green",
              }}
            >
              Fetch
            </button>
          </div>
        </div>

        <table className="table table-bordered custom-table" style={{ marginLeft: '10px', marginRight: '10px', marginTop: '15px' }}>
          <thead>
            <tr style={{backgroundColor:'orange'}}>
              <th className="text-center custom-th" style={{backgroundColor:'orange',fontSize:'12px'}}><b>SIPP CODE</b></th>
              <th colSpan="3" className="text-center custom-th" style={{backgroundColor:'orange',fontSize:'12px'}}><b>Min Capped Rates</b></th>
              <th colSpan="3" className="text-center custom-th" style={{backgroundColor:'orange',fontSize:'12px'}}><b>Max Capped Rates</b></th>
            </tr>
            <tr>
              <th style={{backgroundColor:'orange'}}></th>
              <th className="text-center" style={{backgroundColor:'orange',fontSize:'12px'}}>Capped Daily<span className="blue-required"> *</span></th>
              <th className="text-center" style={{backgroundColor:'orange',fontSize:'12px'}}>Capped Weekly<span className="blue-required"> *</span></th>
              <th className="text-center" style={{backgroundColor:'orange',fontSize:'12px'}}>Capped X Day<span className="blue-required"> *</span></th>
              <th className="text-center" style={{backgroundColor:'orange',fontSize:'12px'}}>Capped Daily<span className="blue-required"> *</span></th>
              <th className="text-center" style={{backgroundColor:'orange',fontSize:'12px'}}>Capped Weekly<span className="blue-required"> *</span></th>
              <th className="text-center" style={{backgroundColor:'orange',fontSize:'12px'}}>Capped X Day<span className="blue-required"> *</span></th>
            </tr>
          </thead>
          <tbody>
            {[...Array(numDropdowns)].map((_, i) => (
              <tr className="pt-3" style={{textAlign:'center'}}>
                <td style={{width:'15%'}}>
                  <Select
                    value={{
                      value: competitorsGrid[i][0]["value"],
                      label: competitorsGrid[i][0]["value"] ? competitorsGrid[i][0]["value"] : "Select SIPP Code",
                    }}
                    options={availableCarClasses}
                    onChange={(e) => handleInputChangeSippCode(e, i)}
                  />
                </td>
                <td>
                  <input type="number" className="form-control text-center" value={competitorsGrid[i][1]} onChange={(e) => handleInputChangeMinCappedDaily(e.target.value, i)}  onKeyDown={handleKeyDown} onPaste={handlePaste}/>
                </td>
                <td>
                  <input type="number" className="form-control text-center" value={competitorsGrid[i][2]} onChange={(e) => handleInputChangeMinCappedWeekly(e.target.value, i)} onKeyDown={handleKeyDown} onPaste={handlePaste} />
                </td>
                <td>
                  <input type="number" className="form-control text-center" value={competitorsGrid[i][3]} onChange={(e) => handleInputChangeMinCappedXDay(e.target.value, i)} onKeyDown={handleKeyDown} onPaste={handlePaste} />
                </td>
                <td>
                  <input type="number" className="form-control text-center" value={competitorsGrid[i][4]} onChange={(e) => handleInputChangeMaxCappedDaily(e.target.value, i)} onKeyDown={handleKeyDown} onPaste={handlePaste} />
                </td>
                <td>
                  <input type="number" className="form-control text-center" value={competitorsGrid[i][5]} onChange={(e) => handleInputChangeMaxCappedWeekly(e.target.value, i)} onKeyDown={handleKeyDown} onPaste={handlePaste} />
                </td>
                <td>
                  <input type="number" className="form-control text-center" value={competitorsGrid[i][6]} onChange={(e) => handleInputChangeMaxCappedXDay(e.target.value, i)} onKeyDown={handleKeyDown} onPaste={handlePaste} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {accessType === 'View' ? "" :
          <>
            <button type="submit" className="btn btn-success" onClick={handleAddDropdown} style={{ maxWidth: "100px", maxHeight: "100px", marginTop: "2em", marginLeft: "10px", fontSize:'12px', backgroundColor: "green" }}>
              Add
            </button>
            <div className="row pt-3" style={{ marginLeft: "0px" }}>
              <div className="col-md-6">
                <button className="btn btn-primary" type="submit" style={{ marginRight: "10px", fontSize:'12px' }} onClick={handleSubmit}>
                  Submit
                </button>
                <button className="btn btn-danger" type="cancel" style= {{fontSize:'12px'}} onClick={handleClear}>
                  Clear
                </button>
              </div>
              <div className="col-md-6">
                <div className="float-end" style={{ color: "blue" }}>(*) This Fields must be Mandatory</div>
              </div>
            </div>
          </>
        }
      </div>
   </div>

)
};





       {/* <div
          className=" mt-5"
          style={{ border: "5px solid black", padding: "10px", marginLeft: "10px", marginRight: "10px" }}
        >
          <div className="row mt-5">
            <div className="col-md-1">
            </div>
            <div className="col-md-2" style={{ marginLeft: "20px", marginTop: "25px" }}>
              <div className="rms-heading text-center"><b>SIPP CODE</b></div>
            </div>
            <div className="col-md-5" style={{ marginLeft: "20px" }}>
              <div className="rms-capped-rates text-center"><b>Capped Rates</b></div>
              <div
                className="row"
                style={{
                  borderRight: "3px solid blue",
                  borderLeft: "3px solid blue",
                  marginTop: "19px",
                  marginLeft: "5px",
                  marginRight: "30px"
                }}
              >
                <div className="col-md-4" >
                  <div className="rms-heading text-center">
                    Capped Daily <span className="blue-required"> *</span>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="rms-heading text-center">
                    Capped Weekly<span className="blue-required"> *</span>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="rms-heading text-center">
                    Capped X Day<span className="blue-required"> *</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2" style={{ marginLeft: "-15px" }}>
              <div className="rms-capped-rates1 text-center"><b>If Competitor Rates are not Present</b>
              </div>
              <div className="rms-heading pt-2 text-center">
                Daily Rate<span className="blue-required"> *</span>
              </div>
            </div>
          </div>
          {[...Array(numDropdowns)].map((_, i) => (
            <div className="row pt-3">
              <div className="col-md-1">
              </div>
              <div className="col-md-2" style={{ marginLeft: "20px" }}>
                <Select
                  value={{
                    value: competitorsGrid[i][0]["value"],
                    label: competitorsGrid[i][0]["value"]
                      ? competitorsGrid[i][0]["value"]
                      : "Select SIPP Code",
                  }}
                  options={availableCarClasses}
                  onChange={(e) => handleInputChangeSippCode(e, i)}
                />
              </div>
              <div className="col-md-5" style={{ marginLeft: "20px" }}>
                <div
                  className="row"
                  style={{
                    borderRight: "3px solid blue",
                    borderLeft: "3px solid blue",
                    marginLeft: "5px",
                    marginRight: "30px"
                  }}
                >
                  <div className="col-md-3" style={{ marginLeft: "18px" }}>
                    <input
                      type="number"
                      className="form-control"
                      value={competitorsGrid[i][1]}
                      onChange={(e) =>
                        handleInputChangeCappedDaily(e.target.value, i)
                      }
                    />
                  </div>
                  <div className="col-md-3" style={{ marginLeft: "33px" }}>
                    <input
                      type="number"
                      className="form-control"
                      value={competitorsGrid[i][2]}
                      onChange={(e) =>
                        handleInputChangeCappedWeekly(e.target.value, i)
                      }
                    />
                  </div>
                  <div className="col-md-3" style={{ marginLeft: "33px" }}>
                    <input
                      type="number"
                      className="form-control"
                      value={competitorsGrid[i][3]}
                      onChange={(e) =>
                        handleInputChangeCappedXDay(e.target.value, i)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-1" style={{ marginLeft: "39px" }}>
                <input
                  type="number"
                  className="form-control"
                  value={competitorsGrid[i][4]}
                  onChange={(e) =>
                    handleInputChangeDailyRate(e.target.value, i)
                  }
                />
              </div>
            </div>
          ))}

          { 
          accessType === 'View' ? "" :
          <>
          <button
            type="submit"
            className="btn btn-success"
            onClick={handleAddDropdown}
            style={{
              maxWidth: "100px",
              maxHeight: "100px",
              marginTop: "2em",
              marginLeft: "120px",
              backgroundColor: " green",
            }}
          >
            Add
          </button>
          <div className="row pt-3" style={{ marginLeft: "110px" }}>
            <div className="col-md-6">
              <button
                class="btn btn-primary"
                type="submit"
                style={{ marginRight: "10px" }}
                onClick={handleSubmit}
              >
                Submit
              </button>
              <button
                className="btn btn-danger"
                type="cancel"
                style={{}}
                onClick={handleClear}
              >
                Clear
              </button>
            </div>
            <div className="col-md-6">
              <div className="float-end" style={{ color: "blue" }}>
                (*) This Fields must be Mandatory
              </div>
            </div>
          </div>

          <div className="row pt-3">
            <div className="col-md-2"></div>
          </div>
          </>
}
        </div>
        <div className="row pt-3">
          <div className="col-md-3">
            <div
              className="row"
              style={{
                borderRight: "3px solid blue",
                borderLeft: "3px solid blue",
              }}
            ></div>
          </div>
        </div>
        <div className="row pt-3">
          <div className="col-md-3">
            <div
              className="row"
              style={{
                borderRight: "3px solid blue",
                borderLeft: "3px solid blue",
              }}
            ></div>
          </div>
        </div>
        <div className="row pt-3">
          <div className="col-md-3">
            <div
              className="row"
              style={{
                borderRight: "3px solid blue",
                borderLeft: "3px solid blue",
              }}
            ></div>
          </div>
        </div>
        <div className="col-md-3">
          <div
            className="row"
            style={{
              borderRight: "3px solid blue",
              borderLeft: "3px solid blue",
            }}
          ></div>
        </div>
      </div>
    </div> */}


    
