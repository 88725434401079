import React, { useState, useEffect } from 'react'
import { Button, Typography } from '@mui/material';
import { Modal } from 'react-bootstrap'
import UpdateForm from './UpdateForm'

export default function Locations() {

  const [lgShow, setLgShow] = useState(false);
  const [accessType, setAccessType] = useState('')

  //API call
  useEffect(() => {

    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);

    if (foundUser.role === 'SuperAdmin') {

      setAccessType('Edit');

    }
    else {

      setAccessType(foundUser.access['Locations_Access Specifier']);
    }

  }, []);


  return (
    <>

      <div className="row">

        <div className="row my-3 ">
          <div className="col-md-6">
            <Typography className="mx-3" variant='h6' sx={{ pb: 3 }}>
              Location {accessType === 'View' && "/ View Only Mode"}
            </Typography>
          </div>

          <div className="col-md-6 d-flex justify-content-end">
            <button type="button" className="btn btn-danger" onClick={() => setLgShow(true)} style={{ height: "40px" }}  >Help</button>
            <Modal
              size="lg"
              show={lgShow}
              onHide={() => setLgShow(false)}
              aria-labelledby="example-modal-sizes-title-lg"

            >
              <Modal.Header closeButton style={{ color: '#FFFFFF', backgroundColor: '#C62828' }}>
                <Modal.Title id="example-modal-sizes-title-lg" >
                  Instructions to Add/Edit Location Information
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h5>Select Account & Location to add/edit details:</h5>
                <ol>
                  <li>Select the account name and Location Name from the dropdown</li>
                  <li>Enter the required details. Click on Update button</li>
                  <li>Pop Up will show Location Setup successful!</li>
                </ol>
              </Modal.Body>
            </Modal>
          </div>
        </div>

      </div>

      <UpdateForm accessType={accessType} />
    </>
  );
}
