import React, { useEffect, useState } from 'react';
import './Navibar.css'
// import logo from './RezGlobal.png'
import logo from './RMPlogo1.png'
import { IoLogOut } from "react-icons/io5";
import ColorLensIcon from '@mui/icons-material/ColorLens';
import { useNavigate } from 'react-router-dom';
import AccountPopover from './AccountPopover';
import {BrowserRouter as Router, Link} from 'react-router-dom';
import axios from 'axios';
import { baseURL } from '../pages/backend_url';

import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

export default function Navibar({ setSidebarColor, sidebarColor, setLoginSuccess }) {

  const [navibar, setNavibar] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState("");

  
  const [notiCount, setNotiCount] = useState(0)
  const [currentURL, setCurrentURL] = useState("")
  const loggedInUser = sessionStorage.getItem("user_token");
  const foundUser = JSON.parse(loggedInUser);
  // useEffect(() => {
  //   if (foundUser.role === 'SuperAdmin') {
  //     const getEnreadMsgCount = () => {
  //       axios.get(baseURL + "/combined_unread_msg_count/" + foundUser.userEmail)
  //         .then((res) => {
  //           setNotiCount(res.data);
  //         })
  //     }
  //     // Set up an interval to run the function every 10 seconds
  //     const intervalId = setInterval(getEnreadMsgCount, 5000);
  //     // Clean up the interval when the component is unmounted
  //     return () => clearInterval(intervalId);
  //   }
  //   else if (foundUser.role != 'SuperAdmin') {
  //     const getEnreadMsgCount = () => {
  //       axios.get(baseURL + "/combined_unread_msg_count/" + foundUser.userEmail)
  //         .then((res) => {
  //           setNotiCount(res.data);
  //         })
  //     }
  //     // Set up an interval to run the function every 120 seconds
  //     const intervalId = setInterval(getEnreadMsgCount, 120000);
  //     // Clean up the interval when the component is unmounted
  //     return () => clearInterval(intervalId);
  //   }
  // }, [])
  function notificationsLabel(count) {
    if (count == 0) {
      return 'no notifications';
    }
    if (count > 100) {
      return 'more than 100 notifications';
    }
    return `${count} notifications`;
  }

  return (
    // <nav className="navbar navibar">
    <nav className={`navbar ${!navibar && 'navibar'} ${navibar && 'navibar2'}`} style={{ backgroundColor: backgroundColor === "#54ADD1" ? "#54ADD1" : "#54ADD1" }}>
      {/* <nav className={`navbar ${!navibar && 'navibar'} ${navibar && 'navibar2'}`} > */}
      <div className="container-fluid ">

        <div className="row" style={{ width: '100%' }}>
          <div className="col-auto" >
          <Link to="/">  <img src={logo} style = {{marginLeft:'-2px', width: '50px', height: 'auto'}}  alt="" className='logo' /> 
          <span className="ms-1" style={{color:'black', fontSize:'25px', marginLeft:'-5px', }}><b> Rate Mate Pro</b></span>
          </Link>
          </div>

          {/* <div className="col-auto navibarFont" style={{ marginTop: 4, marginLeft: -15 }}>
          <Link to="/" style={{ color: '#000000'}}> ROUTESREZ </Link>
          </div> */}
          <div className="col d-flex justify-content-end">
            {/* <input type="color" value={backgroundColor}
              onChange={(e) => { setBackgroundColor(e.target.value); }}
              style={{ marginTop: 8, marginRight: 10 }} title="Choose color of Navbar" />

            <input type="color" value={sidebarColor}
              onChange={(e) => { setSidebarColor(e.target.value) }}
              style={{ marginTop: 8, marginRight: 10 }} title="Choose color of Sidebar" /> */}

            {/* <IoLogOut style={{ marginTop: 5, fontSize: 35, cursor: 'pointer' }} onClick={() => { handleLogout() }} /> */}
          
            {/* <Badge color="secondary" badgeContent={99}>
              <NotificationsNoneIcon aria-label={notificationsLabel(100)} />
            </Badge> */}
            {/* {foundUser.role === 'SuperAdmin' && */}
            <Link to="/SupportScreen">
                <IconButton aria-label={notificationsLabel(notiCount)} sx={{ marginRight: '30px' }} title='Support Notification'>
                  <Badge badgeContent={notiCount} color="error" size="large">
                    <NotificationsNoneIcon sx={{ color: 'white' }} size="large" />
                  </Badge>
                </IconButton>
              </Link>
            {/* } */}
            <AccountPopover setLoginSuccess={setLoginSuccess} />
          
          </div>
        </div>

      </div>
    </nav>
  )
}
