import React, { useState, useEffect } from "react";
import { Alert } from "@mui/material";
import Select from "react-select";
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { baseURL } from '../backend_url';
import Swal from "sweetalert2";
import axios from 'axios';
import ReactPhoneInput from "react-phone-input-2";

export default function UpdateForm({ accessType }) {
  var temp_company_id = "";
  var user_detail_obj = {};
  var user_module_obj = {};
  var updatedCheckedState2 = [];
  const [companyDetail, setcompanyDetail] = useState([{}]);
  const [companyName, setCompanyName] = useState("");
  const [company_id, setcompany_id] = useState("");
  const [userDetail, setUserDetail] = useState([{}]);
  const [userID, setUserID] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [checkboxFlag, setCheckboxFlag] = useState(false);
  const [validationMsg, setValidationMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [locationDetail, setLocationDetail] = useState([{}]);
  const [checkedState, setCheckedState] = useState([]);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [maxUser, setMaxUser] = useState(null);
  const [existingUser, setExistingUser] = useState(null);
  const [roleDetail, setRoleDetail] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [loggedInUserDetail, setLoggedInUserDetail] = useState({});
  const [phone2, setPhone2] = useState("");
  const [sq1, setSq1] = useState("");
  const [sq2, setSq2] = useState("");
  const [sq3, setSq3] = useState("");
  const [q1, setq1] = useState("In what city were you born?");
  const [q2, setq2] = useState("What is the name of your favorite pet?");
  const [q3, setq3] = useState("At what age did you start school?");
  const [submitAndSendMail, setSubmitAndSendMail] = useState("No");

  const [selectAllLocation, setSelectAllLocation] = useState(false);

  // form validation rules 
  const validationSchema = Yup.object().shape({

    password: Yup.string()
      .required('Password is required')
      .min(8, 'Must be greater than 8 characters')
      .max(200, 'Must be less than 200 characters')
      .matches(
        // /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        "Must Contain atleast 8 Characters, One Uppercase, One Lowercase and One Number "
      )

  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, setValue, formState: { errors } } = useForm(formOptions);


  //API call
  useEffect(() => {

    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);
    setLoggedInUserDetail(foundUser);

    if (foundUser.role === 'SuperAdmin') {

      axios.get(baseURL + "/get_company_details_for_users")
        .then((res) => {
          setcompanyDetail(res.data);
        });
    }
    else {

      fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['User_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
        .then((res) => res.json())
        .then((data) => {

          setcompanyDetail(data);
        });
    }

    // axios.get(baseURL + "/user_role_setup")
    //     .then((res) => {
    //       if(res.data){
    //         setRoleDetail(res.data)
    //       }
          
    //     })

  }, []);

  //Trigger on Company Name Dropdown Changes , getting Company ID
  useEffect(() => {

    if (companyDetail && companyName) {

      const company_obj = companyDetail.filter(
        (value) => value.company_name === companyName
      )[0];
      temp_company_id = company_obj._id;
      setcompany_id(temp_company_id);
      setMaxUser(company_obj.no_of_users);
      setSelectAllLocation(false);

      //API call for getting data from DB
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };

      fetch(
        baseURL + '/get_user_details/' + temp_company_id.toString(),
        requestOptions
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.existing_users === undefined)
            setExistingUser(0)
          else
            setExistingUser(data.existing_users)

          setcompany_id(temp_company_id);
          if (data === 'New Entry !!') {
            setUserDetail(data);
          }
          else {
            setUserDetail(data.res);

          }

        });

      //API call for getting data from Location DB
      const requestOptions2 = {
        method: "GET",
        mode: 'cors',
        headers: {
          "Content-Type": "application/json",
        },
      };

      fetch(
        baseURL + '/get_company_location/' + temp_company_id.toString(),
        requestOptions2
      )
        .then((res) => res.json())
        .then((data) => {
          setLocationDetail(data.res);

        });

      let accountType = ""
      for (let i = 0; i < companyDetail.length; i++) {
        if (companyDetail[i]._id === temp_company_id && companyDetail[i].account_type === 'Affiliate') {

          temp_company_id = companyDetail[i].affiliated_to_id;
          accountType = companyDetail[i].account_type;
          break;
        }
      }
      console.log('temp_company_id',temp_company_id)
      console.log('accountType',accountType)
      axios.get(baseURL + "/user_role_setup/" + temp_company_id + "*" + accountType)
        .then((res) => {
          setRoleDetail(res.data)
        })

      // axios.get(baseURL + "/user_role_setup")
      //   .then((res) => {
      //     if(res.data){
      //       setRoleDetail(res.data)
      //     }
          
      //   })

      setUserEmail(null);
      setUserID("");
      setPassword("");
      setShowPassword(false);

    }
  }, [companyName]);



  //Trigger on USER ID dropdown changes
  useEffect(() => {

    if (userEmail && userDetail) {
      user_detail_obj = userDetail.filter(
        (value) => value.user_email === userEmail
      )[0];
   

    setUserID(user_detail_obj._id);
    setUserName(user_detail_obj.user_name);
    setCheckboxFlag(user_detail_obj.is_active);
    setPassword(user_detail_obj.password)
    setSelectAllLocation(false);

    if (user_detail_obj.location_allocated != undefined && user_detail_obj.location_allocated != null) {
      let obj = Object.keys(user_detail_obj.location_allocated);
      let flag = false;
      for (let i = 0; i < obj.length; i++) {
        if (user_detail_obj.location_allocated[obj[i]] === false) {
          flag = true;
        }
      }
      if (!flag) {
        setSelectAllLocation(true);
      }

    }

    // 
    const new_locations = JSON.parse(JSON.stringify(locationDetail));
    new_locations.forEach(emp => {
      if (user_detail_obj['location_allocated'][emp.location_id] === undefined) user_detail_obj['location_allocated'][emp.location_id] = false;
    });
    // 


    // setCheckedState(new_locations);
    setCheckedState(user_detail_obj.location_allocated);
    setSelectedRole(user_detail_obj.selected_role);
    setPhone2(user_detail_obj.contact_no);
    setSq1(user_detail_obj.s_a1);
    setSq2(user_detail_obj.s_a2);
    setSq3(user_detail_obj.s_a3);
  }
  }, [userEmail]);



  //OnSubmit code
  const submitHandler = () => {
    // e.preventDefault();
    setSuccessMsg("");
    setLoading(true);
    setValidationMsg("");

    if (companyName === '' || userEmail === '') {
      if (companyName === '')
        setValidationMsg('Please select an account name to proceed')

      else if (userEmail === '')
        setValidationMsg('Please select an user to proceed')


      setLoading(false)
    }
    else {

      if (sq1.toString().trim() === '') {
        Swal.fire("Please fill answer for security question 1")
        setLoading(false)
        return;
      }
      if (sq2.toString().trim() === '') {
        Swal.fire("Please fill answer for security question 2")
        setLoading(false)
        return;
      }
      if (sq3.toString().trim() === '') {
        Swal.fire("Please fill answer for security question 3")
        setLoading(false)
        return;
      }


      //API call for updating DB data
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          is_active: checkboxFlag,
          password: password,
          location_allocated: checkedState,
          selected_role: selectedRole,
          user_name: userName,
          updated_by: loggedInUserDetail.userEmail,
          contact_no: phone2,
          s_q1: q1,
          s_q2: q2,
          s_q3: q3,
          s_a1: sq1.toString().trim(),
          s_a2: sq2.toString().trim(),
          s_a3: sq3.toString().trim(),
          userEmail: userEmail,
          send_mail: submitAndSendMail

        }),
      };

      fetch(baseURL + "/update_user/" + userID, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          setLoading(false);
          setSuccessMsg(
            "Edits successfully completed for the User Email - " + userEmail
          );

        });

      setUserID("");
      setUserName("");
      setCheckboxFlag(false);
      setUserEmail('');
      setCompanyName('');
      setcompany_id('');
      setShowPassword(false);
      setMaxUser(null);
      setExistingUser(null);
      setPhone2("");
      setSq1("");
      setSq2("");
      setSq3("");
      setSubmitAndSendMail("No")
    }
  }; //submitHandler func ends here


  const handleChecked = () => {

    if (checkboxFlag === true) {

      Swal.fire({
        title: 'Do you really want to deactivate this user ?',

        showCancelButton: true,
        confirmButtonText: 'Yes',


      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {

          setCheckboxFlag(!checkboxFlag);
        }
      })
    }

    if (checkboxFlag === false) {

      Swal.fire({
        title: 'Do you really want to activate this user ?',

        showCancelButton: true,
        confirmButtonText: 'Yes',


      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {

          axios.get(baseURL + "/check_max_user_limit/" + company_id)
            .then((res) => {

              if (res.data === 'failed') {
                setValidationMsg("You have already touched the limit of maximum number of users. Please contact support@caledontechnologies.com to increase the limit")
              }
              else {
                setCheckboxFlag(!checkboxFlag);
              }
            })
        }
      })
    }


  };

  //Option for company name
  const RoleNameOptions = roleDetail.map((value) => (

    { value: value.role_name, label: value.role_name }
  ));

  const handleInputChangeRoleName = characterEntered => {
    setSelectedRole(characterEntered.value);
  };

  //Option for company name
  const companyNameOptions = companyDetail.map((value) => (

    { value: value.company_name, label: value.company_name }
  ));

  const handleInputChangeCompanyName = characterEntered => {
    setCompanyName(characterEntered.value);
  };


  //Option for user Email
  const userEmailOptions = userDetail === "New Entry !!" ? [{}] :
    userDetail.map((value) => (

      { value: value.user_email, label: value.user_email }
    ));

  const handleInputChangeUserEmail = characterEntered => {
    setUserEmail(characterEntered.value);
  }

  const handleGeneratePassword = () => {
    setPassword(generatePassword())
  }
  const generatePassword = () => {
    var length = 8,
      charset2 = "abcdefghijklmnopqrstuvwxyz",
      charset1 = "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
      charset3 = "0123456789",
      retVal = "";
    for (var i = 0, n = charset1.length; i < 3; ++i) {
      retVal += charset1.charAt(Math.floor(Math.random() * n));
    }

    for (var i = 0, n = charset2.length; i < 2; ++i) {
      retVal += charset2.charAt(Math.floor(Math.random() * n));
    }

    for (var i = 0, n = charset3.length; i < 3; ++i) {
      retVal += charset3.charAt(Math.floor(Math.random() * n));
    }
    return shuffle_pass(retVal);
  }


  const shuffle_pass = (pass_str) => {
    var a = pass_str.split(""),
      n = a.length;

    for (var i = n - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var tmp = a[i];
      a[i] = a[j];
      a[j] = tmp;
    }
    return a.join("");
  }


  //Location Tick Check 
  const handleLocationCheck = (location_name) => {

    const location_hash_temp = { ...checkedState };
    location_hash_temp[location_name] = !location_hash_temp[location_name];
    setCheckedState(location_hash_temp);

    let arr = Object.keys(location_hash_temp);
    let flag = true;
    for (let i = 0; i < arr.length; i++) {

      if (location_hash_temp[arr[i]] === false) {
        flag = false;
        break;
      }
    }
    if (flag) {
      setSelectAllLocation(true);
    }
    else {
      setSelectAllLocation(false);
    }

  }


  //Location Tick Check 
  const handleAllLocationCheck = () => {

    const location_hash_temp = { ...checkedState };
    setSelectAllLocation(!selectAllLocation);

    let arr = Object.keys(location_hash_temp);
    for (let i = 0; i < arr.length; i++) {

      if (!selectAllLocation) {
        location_hash_temp[arr[i]] = true
      }
      else {
        location_hash_temp[arr[i]] = false
      }
    }

    setCheckedState(location_hash_temp);

  }


  return (
    <>
      <div className="row pt-4">
        <div className="col-8 mx-3">
          <span style={{ color: '#1E1E1E', fontSize: '21px' }}>{accessType === 'View' ? "Select User to view details:" : "Select User to edit details:"} </span>
        </div>
      </div>

      <div className="row pt-5" style={{fontSize:'12px', color:'black', fontWeight:'600'}}>
        {/* <div className="col-1"></div> */}
        <div className="col-8 mx-3">
          <form className="row g-3" onSubmit={handleSubmit(submitHandler)}>
            {validationMsg ? (
              <Alert severity="error">{validationMsg}!</Alert>
            ) : (
              ""
            )}

            {successMsg ?

              (

                <div className="alert alert-success alert-dismissible fade show" role="alert">
                  {successMsg}
                  <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setSuccessMsg('')}></button>
                </div>
              )
              : ""}



            <div className="col-md-2 mb-3">
              <label htmlFor="company_name" className="form-label">
                Account Name
              </label>
            </div>

            <div className="col-md-5 mb-3">

              <Select

                value={{ value: companyName, label: (companyName ? companyName : "Select Account name") }}
                inputId="company_name"
                name="company_name"
                options={companyNameOptions}
                onChange={handleInputChangeCompanyName}

              />

            </div>


            <div className="col-md-1 mb-3"></div>

            <div className="col-md-2 mb-3">
              {/* <label htmlFor="company_id" className="form-label">
                Account ID
              </label> */}
            </div>
            <div className="col-md-2 mb-3">
              {/* <input
                type="text"
                className="form-control"
                value={company_id}
                id="company_id"
                disabled
              /> */}
            </div>

            {userDetail === "New Entry !!" ? (
              ""
            ) : (
              <>
                <div className="col-md-2 mb-3">
                  <label htmlFor="user_email" className="form-label">
                    User Email
                  </label>
                </div>
                <div className="col-md-5 mb-3">

                  <Select
                    value={{ value: userEmail, label: userEmail ? userEmail : "Select User Email" }}
                    inputId="user_email"
                    name="user_email"
                    options={userEmailOptions}
                    onChange={handleInputChangeUserEmail}
                  />
                </div>
                <div className="col-md-1 mb-3"></div>
              </>
            )}

            {userDetail === "New Entry !!" ? (
              <Alert severity="error">
                No existing user for selected Account
              </Alert>
            ) : (
              <>
                {/* <div className="col-md-1 mb-3"></div> */}
                <div className="col-md-2 mb-3">
                  <label htmlFor="user_name" className="form-label">
                    Contact No.
                  </label>
                </div>
                <div className="col-md-1 mb-3">
                  <ReactPhoneInput enableSearch searchPlaceholder='Search Country' value={phone2}
                    placeholder='Enter Your Contact No.' onChange={(value) => setPhone2(value)} />

                </div>


                <div className="col-md-2 mb-3">
                  <label htmlFor="existing_user" className="form-label">
                    Role Name
                  </label>
                </div>
                <div className="col-md-5 mb-3">
                  <Select
                    value={{ value: selectedRole, label: (selectedRole ? selectedRole : "Select Role Name") }}
                    inputId="role_name"
                    name="role_name"
                    options={RoleNameOptions}
                    onChange={handleInputChangeRoleName}
                    isDisabled={accessType === 'View'}
                  />

                </div>


                <div className="col-md-1 mb-3"></div>


                <div className="col-md-2 mb-3">
                  <label htmlFor="max_user" className="form-label">
                    Max Allowed Users
                  </label>
                </div>
                <div className="col-md-2 mb-3">
                  <input
                    type="text"
                    className="form-control"
                    value={maxUser}
                    id="max_user"
                    disabled
                  />
                </div>

                {userID ? (
                  <>


                    <div className="col-md-2 mb-3">
                      <label htmlFor="user_id" className="form-label">
                        User Name
                      </label>
                    </div>
                    <div className="col-md-5 mb-3">
                      <input
                        type="text"
                        className="form-control"
                        value={userName ?? ""}
                        id="user_name"
                        onChange={(e) => setUserName(e.target.value)}
                      />

                    </div>



                    <div className="col-md-1 mb-3"></div>
                    <div className="col-md-2 mb-3">
                      <label htmlFor="existing_user" className="form-label">
                        Existing Active Users
                      </label>
                    </div>
                    <div className="col-md-2 mb-3">
                      <input
                        type="text"
                        className="form-control"
                        value={existingUser}
                        id="existing_user"
                        disabled
                      />
                    </div>

                    {!(accessType === 'View') && (<>
                      <div className="col-md-2 mb-3">

                        <label htmlFor="password" className="form-label">
                          Password
                        </label>

                      </div>
                      <div className="col-md-5 mb-3">
                        <input
                          type={showPassword ? "text" : "password"}
                          className={`form-control ${errors.password ? 'is-invalid' : ''}`} {...register('password')}
                          value={password}
                          id="password"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <div className='row mx-1'>
                          <div className="col-md-3 form-check mt-3">

                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                              checked={showPassword} onChange={() => setShowPassword(!showPassword)} />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                              Show Password
                            </label>

                          </div>
                          <div className="col-md-2 mt-3"></div>
                          <div className="col-md-1 mt-3">
                            <span title='Click to auto generate password' onClick={handleGeneratePassword} style={{ cursor: 'pointer' }}><AutorenewIcon /> </span>
                          </div>

                          <div className="col-md-6 mt-3">
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                              Generate Password
                            </label>
                          </div>

                        </div>
                        <div className="invalid-feedback">{errors.password?.message}</div>
                      </div>


                      <div className="col-md-5 mb-1"></div>

                      {/* Security Question Start */}
                      <div className='row'>
                        <div className="col-md-2 mb-3">
                          <label htmlFor="company_name" className="form-label">
                            Security Question 1
                          </label>
                        </div>
                        <div className="col-md-3  mb-3">


                          <label htmlFor="q1" className="form-label">
                            {q1}
                          </label>

                        </div>
                        <div className="col-md-3  mb-3">

                          <input
                            type="text"
                            className="form-control"
                            value={sq1}
                            id="q1"
                            onChange={(e) => setSq1(e.target.value)}
                          />
                        </div>
                        {/* <div className="col-md-1 mb-3"></div> */}
                        <div className="col-md-2 mb-3">

                        </div>
                        <div className="col-md-2 mb-3">
                          {/* <input
                  type="text"
                  className="form-control"
                  value={company_id}
                  id="company_id"
                  disabled
                /> */}
                        </div>
                      </div>

                      <div className='row'>
                        <div className="col-md-2 mb-3">
                          <label htmlFor="company_name" className="form-label">
                            Security Question 2
                          </label>
                        </div>
                        <div className="col-md-3  mb-3">


                          <label htmlFor="q2" className="form-label">
                            {q2}
                          </label>

                        </div>
                        <div className="col-md-3  mb-3">
                          <input
                            type="text"
                            className="form-control"
                            value={sq2}
                            id="q2"
                            onChange={(e) => setSq2(e.target.value)}
                          />
                        </div>
                        {/* <div className="col-md-1 mb-3"></div> */}
                        <div className="col-md-2 mb-3">

                        </div>
                        <div className="col-md-2 mb-3">
                          {/* <input
                  type="text"
                  className="form-control"
                  value={company_id}
                  id="company_id"
                  disabled
                /> */}
                        </div>

                      </div>

                      <div className='row'>
                        <div className="col-md-2 mb-3">
                          <label htmlFor="company_name" className="form-label">
                            Security Question 3
                          </label>
                        </div>
                        <div className="col-md-3  mb-3">


                          <label htmlFor="q3" className="form-label">
                            {q3}
                          </label>

                        </div>
                        <div className="col-md-3  mb-3">

                          <input
                            type="text"
                            className="form-control"
                            value={sq3}
                            id="q3"
                            onChange={(e) => setSq3(e.target.value)}
                          />
                        </div>
                        {/* <div className="col-md-1 mb-3"></div> */}
                        <div className="col-md-2 mb-3">

                        </div>
                        <div className="col-md-2 mb-3">
                          {/* <input
                  type="text"
                  className="form-control"
                  value={company_id}
                  id="company_id"
                  disabled
                /> */}
                        </div>

                      </div>
                      {/* Security Question End */}

                    </>)
                    }
                    <div className="col-md-2 mb-1">User Status</div>


                    <div className="col-md-10 mb-4">

                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="gridCheck"
                          checked={checkboxFlag ?? ""}
                          onChange={handleChecked}
                          style={{ width: "50px", height: "25px", marginRight: "8px" }}
                          disabled={accessType === 'View'}
                        />
                        <label className="form-check-label" htmlFor="gridCheck" style={{ margin: 4 }}>
                          {checkboxFlag ? "Active" : "Deactivated"}
                        </label>
                      </div>


                    </div>
                    <div className="col-md-5 mb-3" >
                      <span style={{ color: '#1E1E1E', fontSize: '21px' }}>Location Access:</span>
                      <table className="table text-center" >
                        <thead>
                          <tr >
                            <th scope="col" style={{ width: '150px' }} >Location Name</th>
                            <th scope="col" style={{ width: '70px' }}>Allocated</th>
                          </tr>
                        </thead>
                        <tbody >
                          {locationDetail.length > 0 &&
                            <tr>
                              <td>{"Select All"}</td>
                              <td scope="col" style={{ width: '70px' }}>
                                <input type="checkbox" className='form-check-input' checked={selectAllLocation} onChange={() => { handleAllLocationCheck() }} disabled={accessType === 'View'} />
                              </td>
                            </tr>
                          }
                          {
                            locationDetail.length > 0 ? (
                              locationDetail.map((val) => (
                                <tr key={val._id}>
                                  <td>{val.location_name}</td>
                                  <td scope="col" style={{ width: '70px' }}>
                                    <input type="checkbox" className='form-check-input' checked={checkedState[val.location_id]} onChange={() => handleLocationCheck(val.location_id)} disabled={accessType === 'View'} />
                                  </td>
                                </tr>
                              ))

                            ) : ""
                          }

                        </tbody>
                      </table>


                    </div>

                  </>
                ) : (
                  "")}
                <div className="col-md-2"></div>
                <div className="row">
                  <div className="col-md-5 mb-4 ">
                    {!(accessType === 'View') && (loading ? (

                      <button className="btn btn-success" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: 5, fontSize:'12px' }}></span>
                        Update
                      </button>

                    ) : (
                      <button type="submit" style={{fontSize:'12px'}} className="btn btn-success" >
                        Update
                      </button>

                    ))}
                  </div>
                  <div className="col-md-5 mb-4 ">
                    {!(accessType === 'View') && (loading ? (

                      <button className="btn btn-info" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: 5, fontSize:'12px' }}></span>
                        Update & Send Mail
                      </button>

                    ) : (
                      <button type="submit" className="btn btn-info" style={{fontSize:'12px'}} onClick={() => setSubmitAndSendMail("Yes")}>
                        Update & send Mail
                      </button>

                    ))}
                  </div>
                </div>

              </>
            )}
          </form>

        </div>

      </div>
    </>
  );
}
