import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import "antd/dist/antd.css";
import Swal from 'sweetalert2';
import axios from 'axios';
import { baseURL } from '../backend_url';
import { formatDate, formatDate_withTime } from '../commonFunctions';
import { Country } from "country-state-city";
import { saveAs } from 'file-saver'
import DownloadIcon from '@mui/icons-material/Download';
import Button from '@mui/material/Button';

export default function UpdateForm({accessType}) {

  var obj_company_detail = "";

  const [companyDetail, setcompanyDetail] = useState([{}]);
  const [companyName, setCompanyName] = useState("");
  const [company_id, setcompany_id] = useState("");
  const [updateFlag, setUpdateFlag] = useState(false);
  const [updateErrorCodesDetails, setUpdateErrorCodesDetails] = useState([]);
  const [lgShow1, setLgShow1] = useState(false);
  const [lgShow2, setLgShow2] = useState(false);
  const [updateClicked, setUpdateClicked] = useState(false);
  const [codeDescription, setCodeDescription] = useState("");
  const [loggedInUserDetail, setLoggedInUserDetail] = useState({});
  const [errorCodeDetails, setErrorCodeDetails] = useState([]);
  const [receiveMail, setReceiveMail] = useState(false);
  const [country, setCountry] = useState("");
  const [SIPPDetail, setSIPPDetail] = useState([]);
  const [SIPPName, setSIPPName] = useState("");
  const [carClassImage, setCarClassImage] = useState(null);
  const [imageURL, setImageURL] = useState(null);
  const [carsDetail, setCarsDetail] = useState([]);
  const [locationName, setLocationName] = useState("");
  const [locationId, setLocationId] = useState("");
  const [locationDetail, setLocationDetail] = useState([{}]);
  const [listOfExistingCarClasses, setListOfExistingCarClasses] = useState([])
  const [selectedSippCode, setSelectedSippCode] = useState("");




  //This is for scrolling
  const titleRef = useRef()

  function handleBackClick() {
    titleRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  const handleInputChangeSippCode = (characterEntered) => {
    setSelectedSippCode(characterEntered.value);
  };

  //API call
  useEffect(() => {
    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);
    setLoggedInUserDetail(foundUser);
    if (foundUser.role === 'SuperAdmin') {
        axios.get(baseURL + "/get_company_details_for_users")
            .then((res) => {
                setcompanyDetail(res.data);
            });
    }
    else {
        fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['Cars_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
            .then((res) => res.json())
            .then((data) => {
                setcompanyDetail(data);
            });
    }

    // const loggedInUser = sessionStorage.getItem("user_token");
    // const foundUser = JSON.parse(loggedInUser);
    // setLoggedInUserDetail(foundUser)

    // if (foundUser.role === 'SuperAdmin') {

    //   axios.get(baseURL + "/get_corporate_companies")
    //     .then((res) => {
    //       setcompanyDetail(res.data);
    //     });
    // }
    // else {

    //   fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['AccountRateRules_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
    //     .then((res) => res.json())
    //     .then((data) => {

    //       setcompanyDetail(data);

    //     });
    // }

  }, []);

  const handleInputChangeLocationName = (characterEntered) => {
    setLocationName(characterEntered.value);
  };

  const locationNameOptions = locationDetail.map((value) => (
    { value: value.location_name, label: value.location_name === undefined ? value.location_name : value.location_name + " - " + value.location_id.toString().trim().split("-")[1] }
  ));

  useEffect(() => {
    if (companyDetail && companyName) {
        let obj_company_detail = companyDetail.filter(
            (value) => value.company_name === companyName
        )[0];
        let temp_company_id = obj_company_detail._id;
        let company_type = obj_company_detail.account_type;
        setLocationName('')
        setLocationId('')
        setLocationDetail([{}])
        setSIPPDetail([]);
        setImageURL(null)
        // setNumDropdowns(1);
        // setCompetitorsGrid([["", ""]]);
        //API call for getting data from DB
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        };
        setcompany_id(temp_company_id);


        fetch(baseURL + "/get_company_location/" + temp_company_id, requestOptions)
            .then((res) => res.json())
            .then((data) => {
                let allTemp = data.res;
                if (loggedInUserDetail.role === 'SuperAdmin') {
                    if (allTemp.length > 0) {
                        setLocationDetail(allTemp);
                    }
                }
                else if (company_type === 'Affiliate' && loggedInUserDetail.account_type.account_type === 'Corporate') {

                    if (allTemp.length > 0) {
                        setLocationDetail(allTemp);
                    }
                }
                else {
                    axios.post(baseURL + "/get_individual_user_details", { 'user_email': loggedInUserDetail.userEmail })
                        .then((data) => {
                            if (allTemp.length > 0) {
                                allTemp = allTemp.filter((val) => val !== undefined && data.data[0].location_allocated[val.location_id]);
                                setLocationDetail(allTemp);
                            }
                        }
                        )
                }
            });
    }
  }, [companyName]);

  const sippCodeOptions = listOfExistingCarClasses.map((value) => (
    { value: value.SIPP_CODE, label: value.SIPP_CODE === undefined ? value.SIPP_CODE : value.SIPP_CODE }
  ));


  useEffect(() => {
    let temp_location_id = ''
    if (locationName != "") {
      // setLocationName('')
      // setLocationId('')
      // setLocationDetail([{}])
      setSIPPDetail([]);
      setImageURL(null)
      temp_location_id = locationDetail.filter((loc) => (loc.location_name === locationName)).map((val) => val.location_id)
      setLocationId(temp_location_id);
      axios.get(baseURL + "/get_existing_fleet_setup/" + temp_location_id)
        .then((res) => {
          console.log(res)
          if(res.data !== null){
            // let arr1 = res.data
            // console.log(arr1)
            // let car_class_arr = []
            // let arr2 = [];

            // for(let t=0; t<arr1.length; t++){
            //   if(car_class_arr.indexOf(arr1[t].SIPP_CODE) < 0){
            //     arr2.push(arr1[t]);
            //     car_class_arr.push(arr1[t].SIPP_CODE);
            //   }
            // }
            // console.log(arr2)
            // console.log(car_class_arr)
            setListOfExistingCarClasses(res.data)
            // setSIPPDetail(car_class_arr)
          }
          else {
            setSIPPDetail([])
          }
          
          // setSIPPDetail(arr2);
          // setcompany_id(company_id_temp);
          setUpdateFlag(!updateFlag);
          setLgShow1(false);
        });
     }
  }, [locationName]);

  //Trigger on Company Name Dropdown Changes , getting Company ID
  // useEffect(() => {
  //   if (companyDetail && companyName) {

  //     setSIPPDetail([]);
  //     setImageURL(null)

  //     obj_company_detail = companyDetail.filter(
  //       (value) => value.company_name === companyName
  //     )[0];
  //     const company_id_temp = obj_company_detail._id;

  //     axios.get(baseURL + "/get_car_class/" + company_id_temp)
  //       .then((res) => {
  //         let arr1 = res.data
  //         let car_class_arr = []
  //         let arr2 = [];

  //         for(let t=0; t<arr1.length; t++){
  //           if(car_class_arr.indexOf(arr1[t].SIPP_code) < 0){

  //             arr2.push(arr1[t]);
  //             car_class_arr.push(arr1[t].SIPP_code);
  //           }
  //         }
  //         setSIPPDetail(arr2);
  //         setcompany_id(company_id_temp);
  //         setUpdateFlag(!updateFlag);
  //         setLgShow1(false);
  //       });

  //   }//if condition ends

  // }, [companyName]);

  //Option for company name
  const companyNameOptions = companyDetail.map((value) => (

    { value: value.company_name, label: value.company_name }
  ));
  //Option for SIPP
  const SIPPOptions = SIPPDetail.map((value) => (

    { value: value.SIPP_code, label: value.SIPP_code }
  ));

  const handleInputChangeCompanyName = characterEntered => {
    setCompanyName(characterEntered.value);
  };
  const handleInputChangeSIPP = characterEntered => {
    setSIPPName(characterEntered.value);
  };


  useEffect(() => {

    if (country && company_id) {

      axios.get(baseURL + "/get_cars/" + company_id + "-" + country.name)
        .then((res) => {
          console.log(res.data)
          setCarsDetail(res.data)

        }).catch((e) => { });
    }

  }, [updateFlag])

  //handle Fetch Click
  const handleFetch = (e) => {

    e.preventDefault();
    // setLgShow1(true);
    setCarsDetail('')
    setSelectedSippCode('')
    setLgShow1(false);
    if(companyName === ''){
      Swal.fire("Please select Account Name")
      return;
    }else if (locationName === ''){
      Swal.fire("Please select Location Name")
      return;
    }

    if (locationId) {

      axios.get(baseURL + "/get_cars/" + locationId)
        .then((res) => {
          console.log(res.data)
          if(res.data !== null){
            setCarsDetail(res.data)
          }
          setLgShow1(true);
          // setCarsDetail(res.data)

        }).catch((e) => { });
    }
  }



  useEffect(() => {

    if (lgShow1 === false) {
      setUpdateClicked(false);
    }

  }, [lgShow1])


  const handleSubmit = (e) => {

    e.preventDefault();

    if (companyName === "") {
      Swal.fire("Please select Account Name");
      return;
    }
    else if (locationName === "") {
      Swal.fire("Please select Location Name");
      return;
    }
    else if (selectedSippCode === "") {
      Swal.fire("Car Class field can't be empty");
      return;
    }
    else if (carClassImage === null || carClassImage === '') {
      Swal.fire("Please choose an image");
      return;
    }

    Swal.fire({
      title: 'Do you want to save this car image ?',

      showCancelButton: true,
      confirmButtonText: 'Yes',

    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        // const arr_json_mapping = {
        //   "_id": company_id + "-" + country.name + "-" + SIPPName,
        //   "account_id": company_id.toString(),
        //   "country": country.name,
        //   "SIPP_code": SIPPName,
        //   "created_date": new Date(),
        //   "updated_date": new Date(),
        //   "created_by": loggedInUserDetail.userEmail,
        //   "updated_by": loggedInUserDetail.userEmail,
        //   "file": carClassImage
        // }

        let formData = new FormData();
        // console.log("carClassImage1");
        // console.log(carClassImage)
        // let car_detail = {...carClassImage}
        // console.log("carClassImage2");
        // console.log(car_detail)
        // car_detail['name'] = "TIGER_SINGH.png" 
        // console.log("carClassImage3");
        // console.log(car_detail)

        formData.append("file", carClassImage);
        formData.append("_id", locationId + "-" + selectedSippCode);
        formData.append("account_id", company_id.toString());
        formData.append("SIPP_CODE", selectedSippCode);
        formData.append("created_date", new Date());
        formData.append("updated_date", new Date());
        formData.append("created_by", loggedInUserDetail.userEmail);
        formData.append("updated_by", loggedInUserDetail.userEmail);
        axios.post(baseURL + "/save_cars", formData)
          .then((res) => {
            if (res.data === 'Already Present') {
              Swal.fire({
                position: 'middle',
                icon: 'error',
                title: 'Already exists, Please edit the existing one',
                showConfirmButton: false,
                timer: 3000
              })
            }
            else {
              Swal.fire({
                position: 'middle',
                icon: 'success',
                title: 'Saved Successfully !!',
                showConfirmButton: false,
                timer: 3000
              })
              // setUpdateFlag(!updateFlag);
              setUpdateFlag(!updateFlag)
              setCarClassImage(null);
              setSelectedSippCode("");
              setImageURL(null);
            }
          });
      } else {
        Swal.fire('Changes are not saved');
      }
    });


  }//handlesubmit ends here


  const handleUpdate = (e) => {

    e.preventDefault();

    if (companyName === "") {
      Swal.fire("Account Name can't be empty");
      return;
    }
    else if (locationName === "") {
      Swal.fire("Location Name can't be empty");
      return;
    }
    else if (selectedSippCode === "") {
      Swal.fire("Car Class field can't be empty");
      return;
    }
    else if (carClassImage === null || carClassImage === '') {
      Swal.fire("Please choose an image");
      return;
    }
    Swal.fire({
      title: 'Do you want to save this car image ?',
      showCancelButton: true,
      confirmButtonText: 'Yes',

    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        let formData = new FormData();

        formData.append("file", carClassImage);
        formData.append("_id", locationId + "-" + selectedSippCode);
        formData.append("account_id", company_id.toString());
        formData.append("SIPP_CODE", selectedSippCode);
        formData.append("created_date", new Date());
        formData.append("updated_date", new Date());
        formData.append("created_by", loggedInUserDetail.userEmail);
        formData.append("updated_by", loggedInUserDetail.userEmail);
        // formData.append("file", carClassImage);
        // formData.append("_id", company_id + "-" + country.name + "-" + SIPPName);
        // formData.append("account_id", company_id.toString());
        // formData.append("country", country.name);
        // formData.append("SIPP_code", SIPPName);
        // formData.append("created_date", new Date());
        // formData.append("updated_date", new Date());
        // formData.append("created_by", loggedInUserDetail.userEmail);
        // formData.append("updated_by", loggedInUserDetail.userEmail);

        axios.post(baseURL + "/save_cars", formData)
          .then((res) => {

            Swal.fire({
              position: 'middle',
              icon: 'success',
              title: 'Updated Successfully !!',
              showConfirmButton: false,
              timer: 3000
            })

            setUpdateFlag(!updateFlag)
            setCarClassImage(null);
            setSIPPName("");
            setImageURL(null);
            setUpdateClicked(false);

          });
      } else {
        Swal.fire('Changes are not saved');
      }
    });


  }//update rate rules ends

  const updateCars = (val) => {

    Swal.fire({
      title: 'Do you want to edit this ?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {

      if (result.isConfirmed) {
        setSelectedSippCode(val.SIPP_CODE);
        setLgShow1(true);
        setLgShow2(true);
        setUpdateClicked(true);
        setImageURL(baseURL + "/static/uploads/" + val.image_path);
        handleBackClick();
      }
    })
  }

  const handleImageChange = (e) => {

    if (!(e.target.files[0].name.match(/\.(jpg|jpeg)$/))) {
      // .match(/\.(jpg|jpeg)$/)
      Swal.fire("Please select JPG or JPEG image format");
      return;
    }

    setCarClassImage(e.target.files[0]);
    setImageURL(URL.createObjectURL(e.target.files[0]))
  }

  const downloadImage = (image_url, image) => {
    saveAs(image_url, image) // Put your image url here.
  }

  const handleCancel = (e) => {

    e.preventDefault();
    setSIPPName("");
    setLgShow1(true);
    setLgShow2(true);
    setUpdateClicked(false);
    setImageURL("");

  }

  return (
    <>
      <div className="row pt-2" ref={titleRef} id="title" >
        <div className="col-8 mx-3">
          <span style={{ color: '#1E1E1E', fontSize: '21px' }}>Select account to proceed ahead : </span>
        </div>
      </div>

      <div className="row pt-5" style={{fontSize:'12px', color:'black', fontWeight:'600'}}>
        {/* <div className="col-1"></div> */}
        <div className="col-10 mx-3">
          {/* First Row */}
          <div className="row">
            <div className="col-md-2 mb-3">
              <label htmlFor="company_name" className="form-label">
                Account Name
              </label>
            </div>
            <div className="col-md-4 mb-3">
              <Select
                value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                inputId="company_name"
                name="company_name"
                options={companyNameOptions}
                onChange={handleInputChangeCompanyName}
              />
            </div>
            <div className="col-md-1 mb-3"></div>
            <div className="col-md-2 mb-3">
            </div>
            <div className="col-md-2 mb-3">
            </div>
          </div>{/* first row wrapper ends */}
          {/* Second Row */}
          <div className="row">
              <div className="col-md-2 mb-3">
                <label htmlFor="locationName" className="form-label" style={{ marginTop: 5,fontSize:'12px' }}>
                    Location Name
                </label>
              </div>
              <div className="col-md-4 mb-3">
                <Select
                    value={{ value: locationName, label: (locationName ? locationName : "Select Location Name") }}
                    inputId="locationName"
                    name="locationName"
                    options={locationNameOptions}
                    onChange={handleInputChangeLocationName}
                />
              </div>
            <div className="col-md-1 mb-3"></div>
            <div className="col-md-2 mb-3">
            </div>
            <div className="col-md-2 mb-3">
            </div>
          </div>{/* Second row wrapper ends */}
          {/* Third Row */}
          <div className="row mt-3">

            <div className="col-md-2 mb-3">

            </div>
            <div className="col-md-4 mb-3 d-flex ">
              <button type="submit" className="btn btn-primary" style={{fontSize:'12px'}} onClick={(e) => handleFetch(e)}>
                {"Fetch"}
              </button>

            </div>

            <div className="col-md-12 mb-2 d-flex justify-content-start">
              {companyName === '' && lgShow1 ? (<span style={{ color: 'red' }}>Please select Account Name to proceed ahead</span>) : ""}
            </div>

            {
              companyName && lgShow1  && accessType === 'Edit'? (
                <div className="col-md-12 mb-5 d-flex justify-content-start">
                  <button type="submit" className="btn btn-primary" onClick={() => setLgShow2(!lgShow2)}>
                    {!lgShow2 ? "+ Add New" : "- Hide"}
                  </button>

                </div>
              ) : ""
            }




            {
              companyName && lgShow1 && lgShow2 ? (
                <>
                  {/* fourth row wrapper ends */}
                  <div className="row">
                    <div className="col-md-2 mb-3">
                      <label htmlFor="car_class" className="form-label">
                        Car Class
                      </label>
                    </div>
                    <div className="col-md-4 mb-3">
                      <Select
                        value={{ value: selectedSippCode, label: (selectedSippCode ? selectedSippCode : "Select Car Class") }}
                        // value={{ value: SIPPName, label: (SIPPName ? SIPPName : "Select Car Class") }}
                        inputId="car_class"
                        name="car_class"
                        options={sippCodeOptions}
                        onChange={handleInputChangeSippCode}
                      />
                    </div>
                    {/* <div className="col-md-2 mb-3"></div> */}
                    <div className="col-md-3 mb-3">
                      <Button variant="contained" component="label">
                        Choose Image...
                        <input hidden accept="image/*" type="file" onChange={(e) => handleImageChange(e)}/>
                      </Button>
                    </div>
                  </div>{/* fourth row wrapper ends */}
                  {/* fifth row wrapper ends */}
                  {imageURL && (
                    <div className="row" style={{marginTop:"-16%", marginLeft:'-14%'}}>
                      <div className="col-md-2 mb-3">
                      </div>
                      <div className="col-md-4 mb-3">
                      </div>
                      <div className="col-md-1 mb-3"></div>
                      <div className="col-md-1 mb-3">
                      </div>
                      <div className="col-md-3 mb-3">
                        <img src={imageURL} style={{ maxWidth: '60%' }} />
                      </div>
                    </div>
                  )}
                  {/* fifth row wrapper ends */}
                  <div className="col-md-11 mt-4 d-flex">
                    {
                      !updateClicked ? <button type="submit" style={{fontSize:'12px', marginLeft:'18%'}} className="btn btn-success" onClick={handleSubmit}>
                        Save
                      </button>
                        :
                        <>
                          <div className="col-md-2" style={{marginLeft:'56%'}}>
                            <button type="submit" style={{fontSize:'12px'}} className="btn btn-danger" onClick={handleCancel}>
                              Cancel
                            </button>
                          </div>
                          <div className="col-md-2" style={{marginLeft:'-8%'}}>
                            <button type="submit" style={{fontSize:'12px'}} className="btn btn-success" onClick={handleUpdate}>
                              Update
                            </button>
                          </div>
                        </>
                    }
                  </div>
                </>
              ) : ""
            }
          </div>{/* Third row wrapper ends */}

          {/* Fourth Row*/}
          {lgShow1 && <>
            <div className="row mt-3">
              <div className="col-md-11">
                <h5 className="mb-3">Existing Cars: </h5>
                <table className="table" >
                  <thead>
                    <tr>
                      <th style={{backgroundColor:'orange'}} scope="col">Car Image</th>
                      <th style={{backgroundColor:'orange'}} scope="col">Car Class</th>
                      <th style={{backgroundColor:'orange'}} scope="col">Account</th>
                      {/* <th scope="col">Country</th> */}
                      <th style={{backgroundColor:'orange'}} scope="col">Updated On</th>
                      <th style={{backgroundColor:'orange'}} scope="col">Updated By</th>
                      <th style={{backgroundColor:'orange'}} scope="col">Edit</th>
                      <th style={{backgroundColor:'orange'}} scope="col">Download Car Image</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      carsDetail.length > 0 ? carsDetail.map((val, i) => (
                        <tr>
                          <td style={{ width: "200px" }}><img src={baseURL + "/static/uploads/" + val.image_path} style={{ maxWidth: '60%', minWidth: '40%' }} /></td>
                          <td>{val.SIPP_CODE}</td>
                          <td>{companyName}</td>
                          {/* <td>{val.country}</td> */}
                          <td>{formatDate_withTime(new Date(val.updated_date))}</td>
                          <td>{val.updated_by}</td>
                          <td><span style={{ cursor: "pointer" }} onClick={() => { updateCars(val); }}><EditIcon /></span></td>
                          <td align="center"><span style={{ cursor: "pointer" }} onClick={() => { downloadImage(baseURL + "/static/uploads/" + val.image_path, val.image_path); }}><DownloadIcon /></span></td>

                        </tr>

                      )) : <span>No cars for selected Account and Country</span>
                    }

                  </tbody>
                </table>
              </div>
            </div>{/* Fourth row ends */}
          </>
          }
          <div className="col-md-10 mb-4 ">

          </div>

        </div>


      </div> {/* overall wrapper row ends */}

    </>
  );
}
