import React, { useState, useEffect, useRef } from 'react'
import { Alert } from "@mui/material";
import Select from "react-select";
import Swal from 'sweetalert2';
import { Calendar } from 'antd';
// import './RateUpdate.css'
import Modal from 'react-bootstrap/Modal'
import { baseURL } from '../backend_url';
import axios from 'axios';
import moment from 'moment';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
// import { ExportToExcel } from './ExportToExcel'
// import { ExportToExcelWithRates } from './ExportToExcelWithRates';
// import ExcelUpload from './ExcelUpload';
import { formatDate, formatDate_withTime, to_locale_string } from '../commonFunctions';
import MultiRangeSlider from "multi-range-slider-react";
import RangeSlider from "react-bootstrap-range-slider";
import { isEmpty } from 'lodash';
import { MDBDataTable } from 'mdbreact';
import './AutomationSetUp.css';


export default function UpdateForm({ accessType }) {

    const formatDate = (date) => {
        return [
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
            date.getFullYear(),
        ].join('-');
    }

    const formatDate_Modal = (date) => {
        return [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate())
        ].join('-');
    }

    const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    }

    const deleteAutoSetUp = (id) => {
        Swal.fire({
          title: 'Do you want to delete this Automation setup ?',
          showCancelButton: true,
          confirmButtonText: 'Yes',
        }).then((result) => {
          if (result.isConfirmed) {
    
            axios.post(baseURL + "/delete_auto_setup", {
              '_id': id,
              'updated_by': loggedInUserDetail.userEmail,
              'updated_date': new Date()
            }
            )
              .then((res1) => {
                Swal.fire({
                  position: 'middle',
                  icon: 'success',
                  title: 'Automation SetUp deleted successfully',
                  showConfirmButton: false,
                  timer: 3000
                });
                setRecordUpdateFlag(!recordUpdateFlag);
              }).catch((e) => { });
          }
        })
      }

    const [companyDetail, setcompanyDetail] = useState([{}]);
    const [companyName, setCompanyName] = useState("");
    const [company_id, setcompany_id] = useState("");
    const [updatedValues3, setUpdatedValues3] = useState([]);
    const [automationRecordsShow, setAutomationRecordsShow] = useState('');
    const [columnData, setColumnData] = useState([]);
    const [recordUpdateFlag, setRecordUpdateFlag] = useState(false);
    const [automationDetails, setAutomationDetails] = useState([]);


    const [locationDetail, setLocationDetail] = useState([{}]);
    const [competitorsList, setCompetitorsList] = useState([{}]);
    const [locationName, setLocationName] = useState("");
    const [locationId, setLocationId] = useState("");

    const [listOfRateCodes, setListOfRateCodes] = useState({})
    const [table, setTable] = useState([])
    const [carDetails, setCarDetails] = useState([])
    const [gridDetails, setGridDetails] = useState({})

    const [hour, setHour] = useState(["00:00", "00:00", "00:00", "00:00", "00:00"]);

    const hours = [
      { "name": "00:00" }, { "name": "00:30" }, { "name": "01:00" }, { "name": "01:30" }, { "name": "02:00" }, { "name": "02:30" }, { "name": "03:00" },
      { "name": "03:30" }, { "name": "04:00" }, { "name": "04:30" }, { "name": "05:00" }, { "name": "05:30" }, { "name": "06:00" }, { "name": "06:30" },
      { "name": "07:00" }, { "name": "07:30" }, { "name": "08:00" }, { "name": "08:30" }, { "name": "09:00" }, { "name": "09:30" }, { "name": "10:00" },
      { "name": "10:30" }, { "name": "11:00" }, { "name": "11:30" }, { "name": "12:00" }, { "name": "12:30" }, { "name": "13:00" }, { "name": "13:30" },
      { "name": "14:00" }, { "name": "14:30" }, { "name": "15:00" }, { "name": "15:30" }, { "name": "16:00" }, { "name": "16:30" }, { "name": "17:00" },
      { "name": "17:30" }, { "name": "18:00" }, { "name": "18:30" }, { "name": "19:00" }, { "name": "19:30" }, { "name": "20:00" }, { "name": "20:30" },
      { "name": "21:00" }, { "name": "21:30" }, { "name": "22:00" }, { "name": "22:30" }, { "name": "23:00" }, { "name": "23:30" }, { "name": "23:59" },
    ];

    const handlePickUpTime = (value) => {
        setSelectedPickUpTime(value);
    };


    const [fromDate, setFromDate] = useState(new Date())
    const [toDate, setToDate] = useState(new Date())

    const [validationMsg, setValidationMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const [loggedInUserDetail, setLoggedInUserDetail] = useState({});

    const [rateCodeNames, setRateCodeNames] = useState({})
    const [selectedAutomationTimings, setSelectedAutomationTimings] = useState(new Array(7).fill(''));
    const [selectedPickUpTime, setSelectedPickUpTime] = useState('');

    const [selectedRateParity, setSelectedRateParity] = useState('Static');
    const [selectedRateSourceOption, setSelectedRateSourceOption] = useState('Expedia');
    const [selectedCompetitionOption, setSelectedCompetitionOption] = useState('SIPP CODE');
    const [carDetailsInOption1, setCarDetailsInOption1] = useState([])
    const [carDetailsInOption2, setCarDetailsInOption2] = useState([])
    const [carDetailsInOptionConstant, setCarDetailsInOptionConstant] = useState([])
    const [fleetStandardCarDetails, setFleetStandardCarDetails] = useState([])
    const [expediaOptions, setExpediaOptions]  = useState(['Economy', 'Compact','MidSize', 'Standard', 'Full-Size','Premium', 'Luxury', 'Convertible', 'Minivan', 'SUV', 'Compact SUV', 'Midsize SUV', 'Standard SUV', 'Standard Elite SUV', 'Fullsize SUV', 'Premium SUV', 'Luxury SUV', 'Special SUV', 'Van', 'Pickup', 'Sports Car', 'Other']);

    const options1 = [
        { id: 1, value: "Active", label: "Active" },
        { id: 2, value: "IN - Active", label: "In - Active" },
      ];

    const rateSourceOptions = [
    { value: "Expedia", label: "Expedia" },
    { value: "Priceline", label: "Priceline" }
    ];

    const competitionOptions = [
        { value: "SIPP CODE", label: "SIPP CODE" },
        { value: "Car Category", label: "Car Category" }
    ];  

    const parityOptions = [
        { value: "Static", label: "Static" },
        { value: "Multiple of LOR", label: "Multiple of LOR" }
    ];  

    const handleRateSourceOption = (value) => {
        if(value == "Expedia" && selectedCompetitionOption !== "SIPP CODE"){
            setCarDetailsInOption1(expediaOptions)
            setCarDetailsInOption2(expediaOptions)
        }else if(value == "Expedia" && selectedCompetitionOption !== "Car Category"){
            setCarDetailsInOption1(carDetailsInOptionConstant)
            setCarDetailsInOption2(fleetStandardCarDetails)
        }else if(value == "Priceline"){
            setCarDetailsInOption1(carDetailsInOptionConstant)
            setCarDetailsInOption2(fleetStandardCarDetails)
            setSelectedCompetitionOption('SIPP CODE');
        }    
        setSelectedRateSourceOption(value);
    };

    const handleCompetitionOption = (value) => {
        setSelectedCompetitionOption(value);
        if(value == "SIPP CODE"){
            setCarDetailsInOption1(carDetailsInOptionConstant)
            setCarDetailsInOption2(fleetStandardCarDetails)
        }else if(value == "Car Category"){
            if(selectedRateSourceOption == "Expedia"){
                setCarDetailsInOption1(expediaOptions)
                setCarDetailsInOption2(expediaOptions)
            }else if (selectedRateSourceOption == "Priceline"){
                setCarDetailsInOption1(carDetailsInOptionConstant)
                setCarDetailsInOption2(fleetStandardCarDetails)
                setSelectedCompetitionOption('SIPP CODE');
            }    
        }
    };

    useEffect(() => {
        if(selectedCompetitionOption == "Car Category"){
            setCarDetailsInOption1(expediaOptions)
            setCarDetailsInOption2(expediaOptions)
        }else{
            setCarDetailsInOption1(carDetailsInOptionConstant)
            setCarDetailsInOption2(fleetStandardCarDetails)
        }
    }, [carDetailsInOption1, selectedCompetitionOption]);

    const handleParity = (value) => {
        setSelectedRateParity(value);
    };


    // Data for the numbers 1 to 7
    const [numbers, setNumbers] = useState({ "1": false, "2": false, '3': false, '4': false, '5': false, '6': false, 'Weekly': false, 'Monthly': false })
    // const [numbers, setNumbers] = useState({ "1": false, "2": false, '3': false, '4': false, '5': false, '6': false, 'Weekly': false })

    // Function to handle individual checkbox selection
    const handleNumberSelection = (number) => {
        setNumbers({
            ...numbers,
            [number]: !numbers[number], // Toggle the value for the clicked number
        });
    };

    const handleAutomationSetUpSubmit = (e) => {
        e.preventDefault();

        if (companyName === "") {
            Swal.fire('Please select Account Name');
            return;
        }

        if (locationName === "") {
            Swal.fire('Please select a Location to proceed');
            return;
        }

        if (selectedPickUpTime === "") {
            Swal.fire('Please select a PickUp Time');
            return;
        }

        const hasNonEmptyValue = selectedAutomationTimings.some(item => item.trim() !== '');

        if (!hasNonEmptyValue) {
            Swal.fire("Please select atleast one Automation Timings");
            return;
        }

        const hasTrueValueForLor = Object.values(numbers).some((value) => value === true);

        if (!hasTrueValueForLor) {
            Swal.fire("Please select atleast one Lor");
            return;
        }

        let carDetails_d1 = []
        for(let i=0; i<carDetails.length; i++){
            
            carDetails_d1.push(carDetails[i])
        }
    
        let sippValues_d1 = []
        for(let i=0; i<sippValues.length; i++){
            if(sippValues.length > 0)
            {
                sippValues_d1.push(sippValues[i]['compSippValues'])
            }
        }

        let sippValues_d2 = []
        for(let i=0; i<sippValues2.length; i++){
            if(sippValues2.length > 0)
            {                 
                if (sippValues2[i] && sippValues2[i].hasOwnProperty('compSippValues2')) { 
                    if (sippValues2[i]['compSippValues2'] != '' && sippValues2[i]['compSippValues2'] != undefined) {   
                        sippValues_d2.push(sippValues2[i]['compSippValues2'])
                    }else{
                        sippValues_d2.push('empty')
                    }
                }else{
                    sippValues_d2.push('empty')
                }
            }
        }

        let sippValues_d3 = []
        for(let i=0; i<sippValues3.length; i++){
            if(sippValues3.length > 0)
            {                 
                if (sippValues3[i] && sippValues3[i].hasOwnProperty('compSippValues3')) { 
                    if (sippValues3[i]['compSippValues3'] != '' && sippValues3[i]['compSippValues3'] != undefined) {   
                        sippValues_d3.push(sippValues3[i]['compSippValues3'])
                    }else{
                        sippValues_d3.push('empty')
                    }
                }else{
                    sippValues_d3.push('empty')
                }
            }
        }

        let selectedStrategy_d1 = []
        for(let i=0; i<selectedStrategy.length; i++){
            let strategy = '';
            if(selectedStrategy.length > 0)
            {
                if(selectedStrategy[i] == '' || selectedStrategy[i] == undefined || !(selectedStrategy[i])){
                    strategy = '';

                }else{
                     strategy = selectedStrategy[i]['strategyValues'];
                }
                selectedStrategy_d1.push(strategy)
            }
        }

        let selectedCompetitor_d1 = []

        for(let i=0; i<selectedCompetitor.length; i++){
            let competitor = '';
            if(selectedCompetitor.length > 0)
            {
                if(selectedCompetitor[i] == '' || selectedCompetitor[i] == undefined || !(selectedCompetitor[i])){
                    competitor = '';

                }else{
                    competitor = selectedCompetitor[i]['competitorValues'];
                }
                selectedCompetitor_d1.push(competitor)
            }
        }

        let selectedChange_d1 = []
        for(let i=0; i<selectedChange.length; i++){
            let change = '';
            if(selectedChange.length > 0)
            {
                if(selectedChange[i] == '' || selectedChange[i] == undefined || !(selectedChange[i])){
                    change = '';

                }else{
                    change = selectedChange[i]['changeValues'];
                }
                selectedChange_d1.push(change)
            }
        }

        let selectedAmount_d1 = []
        for(let i=0; i<selectedAmount.length; i++){
            let amount = '';
            if(selectedAmount.length > 0)
            {
                if(selectedAmount[i] == '' || selectedAmount[i] == undefined || !(selectedAmount[i])){
                    amount = '';
                }else if(selectedAmount[i]['amountValues'] == '' || selectedAmount[i]['amountValues'] == undefined || !(selectedAmount[i]['amountValues'])){
                    amount = '0';
                }else{
                    amount = selectedAmount[i]['amountValues'];
                }
                selectedAmount_d1.push(amount)
            }
        }
        
        let competitorCount = selectedCompetitor_d1.filter(item => item !== '').length;
        let changeCount = selectedChange_d1.filter(item => item !== '').length;
        let amountCount = selectedAmount_d1.filter(item => item !== '').length;
        const countOfCustom = selectedStrategy_d1.filter(item => item === 'Custom').length;
        const countOfStrategy = selectedStrategy_d1.filter(item => item === '').length;
        const nonEmptySippCount = sippValues_d1.filter(car => car !== '').length;

        // if((nonEmptySippCount != carDetails.length) || (selectedStrategy_d1.length != carDetails.length) || (countOfCustom != competitorCount)|| (countOfCustom != changeCount)|| (countOfCustom != amountCount) || (countOfStrategy > 0)){
        //     for(let i=0; i<carDetails_d1.length; i++)
        //     {
        //         if(sippValues_d1.length > 0)
        //         {
        //             if(sippValues_d1[i] == '' || sippValues_d1[i] == undefined)
        //             {
        //                 Swal.fire("Please select Competes sipp code for "+carDetails_d1[i]+"");
        //                 return;
        //             }    
        //         }else{
        //                Swal.fire("Please select Competes sipp code for "+carDetails_d1[i]+" ");
        //                return;
        //         }

        //         if(sippValues_d1.length > 0)
        //             {
        //                 console.log(selectedStrategy_d1[i])
        //                 if(sippValues_d1[i] == '' || sippValues_d1[i] == undefined)
        //                 {
        //                     if(selectedStrategy_d1[i] === "Leave as it is"){
        //                         if(selectedCompetitionOption === "SIPP CODE"){
        //                             sippValues_d1[i] = 'CCAR';
        //                         }else{
        //                             sippValues_d1[i] = 'Mini';
        //                         }                            
        //                     }else{
        //                         Swal.fire("Please select Competes sipp code for "+carDetails_d1[i]+"");
        //                         return;
        //                     }
        //                 }    
        //             }

        //         if(selectedStrategy_d1.length > 0)
        //         {
        //             if(selectedStrategy_d1[i] == '' || selectedStrategy_d1[i] == undefined)
        //             {
        //                 Swal.fire("Please select Strategy for "+carDetails_d1[i]+" ");
        //                 return;
        //             } else if(selectedStrategy_d1[i] == 'Custom'){
        //                 if(selectedCompetitor_d1[i] == '' || selectedCompetitor_d1[i] == undefined)
        //                 {
        //                     Swal.fire("Please select Competitor for "+carDetails_d1[i]+" ");
        //                     return;
        //                 }else if(selectedChange_d1[i] == '' || selectedChange_d1[i] == undefined)
        //                 {
        //                     Swal.fire("Please select Dollar/Percentage for "+carDetails_d1[i]+" ");
        //                     return;
        //                 }else if(selectedAmount_d1[i] == '' || selectedAmount_d1[i] == undefined)
        //                 {
        //                     Swal.fire("Please select Range value for "+carDetails_d1[i]+" ");
        //                     return;
        //                 }   
        //             }
        //         }else
        //         {
        //                 Swal.fire("Please select strategy for "+carDetails_d1[i]+" ");
        //                 return;
        //         }
        //     }
        // }
        if((nonEmptySippCount != carDetails.length) || (selectedStrategy_d1.length != carDetails.length) || (countOfCustom != competitorCount) || (countOfCustom != changeCount) || (countOfCustom != amountCount) || (countOfStrategy > 0)){
            for(let i=0; i<carDetails_d1.length; i++)
            {
                if(sippValues_d1.length > 0)
                {
                    console.log(selectedStrategy_d1[i])
                    if(sippValues_d1[i] == '' || sippValues_d1[i] == undefined)
                    {
                        if(selectedStrategy_d1[i] === "Leave as it is"){
                            if(selectedCompetitionOption === "SIPP CODE"){
                                sippValues_d1[i] = 'CCAR';
                            }else{
                                sippValues_d1[i] = 'Mini';
                            }                            
                        }else{
                            Swal.fire("Please select Competes sipp code for "+carDetails_d1[i]+"");
                            return;
                        }
                    }    
                }else{
                       Swal.fire("Please select Competes sipp code for "+carDetails_d1[i]+" ");
                       return;
                }
                if(selectedStrategy_d1.length > 0)
                {
                    if(selectedStrategy_d1[i] == '' || selectedStrategy_d1[i] == undefined)
                    {
                        Swal.fire("Please select Strategy for "+carDetails_d1[i]+" ");
                        return;
                    } else if(selectedStrategy_d1[i] == 'Custom'){
                        if(selectedCompetitor_d1[i] == '' || selectedCompetitor_d1[i] == undefined)
                        {
                            Swal.fire("Please select Competitor for "+carDetails_d1[i]+" ");
                            return;
                        }else if(selectedChange_d1[i] == '' || selectedChange_d1[i] == undefined)
                        {
                            Swal.fire("Please select Dollar/Percentage for "+carDetails_d1[i]+" ");
                            return;
                        }else if(selectedAmount_d1[i] == '' || selectedAmount_d1[i] == undefined)
                        {
                            Swal.fire("Please select Range value for "+carDetails_d1[i]+" ");
                            return;
                        }   
                    }
                }else
                {
                        Swal.fire("Please select strategyssss for "+carDetails_d1[i]+" ");
                        return;
                }
            }
        }
        const secondArray = sippValues_d2.slice();
        const thirdArray = sippValues_d3.slice();
        carDetails_d1.forEach((value, index) => {
            if (sippValues_d2[index] ===  undefined) {
                secondArray[index] = 'empty'
            }
        });
        carDetails_d1.forEach((value, index) => {
            if (sippValues_d3[index] ===  undefined ) {
                thirdArray[index] = 'empty'
            }
        });

        const truncated_comp_sipp_code1 = sippValues_d1.slice(0, carDetails_d1.length);
        const truncated_comp_sipp_code2 = secondArray.slice(0, carDetails_d1.length);
        const truncated_comp_sipp_code3 = thirdArray.slice(0, carDetails_d1.length);
        // const truncated_strategy = selectedStrategy_d1.slice(0, carDetails_d1.length);
        // const truncated_competitor = selectedCompetitor_d1.slice(0, carDetails_d1.length);
        // const truncated_change = selectedChange_d1.slice(0, carDetails_d1.length);
        // const truncated_amount = selectedAmount_d1.slice(0, carDetails_d1.length);

        const body_payload = 
        {
            location_name: locationName,
            account_id: company_id.toString(),
            location_id: locationId.toString(),
            pickup_time: selectedPickUpTime,
            automation_timings: selectedAutomationTimings,
            rateSourceSelected: selectedRateSourceOption,
            competitionSelected: selectedCompetitionOption,
            paritySelected: selectedRateParity,
            lor: numbers,
            sipp_code: carDetails_d1,
            comp_sipp_code: truncated_comp_sipp_code1,
            comp_sipp_code2: truncated_comp_sipp_code2,
            comp_sipp_code3: truncated_comp_sipp_code3,
            strategy: selectedStrategy_d1,
            selected_competitor: selectedCompetitor_d1,
            selected_change: selectedChange_d1,
            amount: selectedAmount_d1,
            from_date:new Date(fromDate).toDateString(),
            to_date: new Date(toDate).toDateString(),
            created_by: loggedInUserDetail.userEmail,
            updated_by: loggedInUserDetail.userEmail,
            created_date: new Date(),
            updated_date: new Date()
        };
        console.log(body_payload)

        axios
          .post(baseURL + "/save_automation_setup_info", body_payload)
          .then((res) => {
            Swal.fire({
              position: "middle",
              icon: "success",
              title: "Automation SetUp Added Successfully",
              showConfirmButton: false,
              timer: 3000
            });
            setRecordUpdateFlag(!recordUpdateFlag);

          }).catch((e) => { });

       // handleClear();
    };

    const data = {
        columns: columnData,
        rows: automationDetails
    };

    // Function to handle "Select All" checkbox
    const handleSelectAll = () => {
        const areAllSelected = Object.values(numbers).every((value) => value);
        const updatedNumbers = {};

        for (const key in numbers) {
            updatedNumbers[key] = !areAllSelected;
        }

        setNumbers(updatedNumbers);
    };

    const mainDropdownOptions = ['Lowest', '2nd Lowest', '3rd Lowest','Lowest Off-Airport', '2nd Lowest Off-Airport', '3rd Lowest Off-Airport','Lowest In-Terminal', '2nd Lowest In-Terminal', '3rd Lowest In-Terminal','Lowest among all the Car Classes', 'Custom', 'Leave as it is'];
    const changeOptions = ['Dollar ($)', 'Percentage (%)']; // Replace this with your additional dropdown options

    // const [selectedValues, setSelectedValues] = useState([]);
    const [sippValues, setSelectedSippValues] = useState([]);
    const [sippValues2, setSelectedSippValues2] = useState([]);
    const [sippValues3, setSelectedSippValues3] = useState([]);
    // const [rangeValues, setSelectedRangeValues] = useState([]);
    const [selectedStrategy, setSelectedStrategy] = useState([]);
    const [selectedAmount, setSelectedAmount] = useState([]); 
    const [selectedCompetitor, setSelectedCompetitor] = useState([]);
    const [selectedChange, setSelectedChange] = useState([]);
    // const [selectedTerminal, setSelectedTerminal] = useState([]);
    // const [defaultAmountType, setDefaultAmountType] = useState(["Dollar: $"]);

    const handleComptitorSippCodes = (index, value) => {
        setSelectedSippValues((prevValues) => {
            const updatedValues2 = [...prevValues];
            updatedValues2[index] = { compSippValues: value };
            return updatedValues2;
        });
    };

    const handleComptitorSippCodes2 = (index, value) => {
        setSelectedSippValues2((prevValues2) => {
            const updatedValues2 = [...prevValues2];
            updatedValues2[index] = { compSippValues2: value };
            return updatedValues2;
        });
    };

    const handleComptitorSippCodes3 = (index, value) => {
        setSelectedSippValues3((prevValues3) => {
            const updatedValues2 = [...prevValues3];
            updatedValues2[index] = { compSippValues3: value };
            return updatedValues2;
        });
    };


    const handleStrategy = (index, value) => {
        setSelectedStrategy((prevValues3) => {
            const updatedValues3 = [...prevValues3];
            updatedValues3[index] = { strategyValues: value };
            return updatedValues3;
        });

        if (value != 'custom') {
            setSelectedCompetitor((prevValues4) => {
                const updatedValues4 = [...prevValues4];
                updatedValues4[index] = { competitorValues: '' };
                //updatedValues5[mainIndex].additionalDropdown = value;
                return updatedValues4;
            });

            setSelectedAmount((prevValues6) => {
                const updatedValues6 = [...prevValues6];
                updatedValues6[index] = { amountValues: '' };
                return updatedValues6;
            });

            setSelectedChange((prevValues5) => {
                const updatedValues5 = [...prevValues5];
                updatedValues5[index] = { changeValues: '' };
                return updatedValues5;
            });

            // setSelectedTerminal((prevValues7) => {
            //     const updatedValues7 = [...prevValues7];
            //     updatedValues7[index] = { terminalValues: '' };
            //     return updatedValues7;
            // });

        }
    };

    const handleAmount = (index, value) => {
        setSelectedAmount((prevValues6) => {
            const updatedValues6 = [...prevValues6];
            updatedValues6[index] = { amountValues: value };
            return updatedValues6;
        });
    };

    const handleChange = (index, value) => {
        setSelectedChange((prevValues5) => {
            const updatedValues5 = [...prevValues5];
            updatedValues5[index] = { changeValues: value };
            //updatedValues5[mainIndex].additionalDropdown = value;
            return updatedValues5;
        });
    };

    const handleCompetitor = (index, value) => {
        setSelectedCompetitor((prevValues4) => {
            const updatedValues4 = [...prevValues4];
            updatedValues4[index] = { competitorValues: value };
            //updatedValues5[mainIndex].additionalDropdown = value;
            return updatedValues4;
        });
    };

    const handleAutomationTimings = (index, value) => {
        setSelectedAutomationTimings((prevTimes) => {
          const newTimes = [...prevTimes];
          newTimes[index] = value;
          return newTimes;
        });
      };


    //API call
    useEffect(() => {
        const loggedInUser = sessionStorage.getItem("user_token");
        const foundUser = JSON.parse(loggedInUser);
        setLoggedInUserDetail(foundUser);
        if (foundUser.role === 'SuperAdmin') {

            // setRateCodeSelectionAccess("Yes");
            // setExcelUploadAccess("Yes");
            // setDaysApplicableAccess("Yes");
            // setCopyRateAccess("Yes");

        }
        else {
            // setRateCodeSelectionAccess(foundUser.access['RateUpdate_Rate Code Selection']);
            // setExcelUploadAccess(foundUser.access['RateUpdate_Excel Upload']);
            // setDaysApplicableAccess(foundUser.access['RateUpdate_Days Applicable Selection']);
            // setCopyRateAccess(foundUser.access['RateUpdate_Copy Rates']);
        }
        console.log(foundUser.role);


        if (foundUser.role === 'SuperAdmin') {

            axios.get(baseURL + "/get_company_details_for_users")
                .then((res) => {
                    setcompanyDetail(res.data);
                });
        }
        else {
            console.log("im heree")
            fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['AutomationSystemDrivenSetUp_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
                .then((res) => res.json())
                .then((data) => {

                    setcompanyDetail(data);
                });
        }

        fetch(baseURL + "/get_all_rate_codes_names")
            .then((res) => res.json())
            .then((data) => {
                if (Object.keys(data).length > 0) {
                    setRateCodeNames(data);
                }
                else {
                    setRateCodeNames({});
                }
            })
    }, []);

    useEffect(() => {
        if (companyDetail && companyName) {

            let obj_company_detail = companyDetail.filter(
                (value) => value.company_name === companyName
            )[0];
            let temp_company_id = obj_company_detail._id;
            let company_type = obj_company_detail.account_type;

            setcompany_id(temp_company_id);

            setLocationName('')
            setLocationId('')
            // setListOfRateCodes({})
            // setRateCodesSelected('')
            // setTable([])
            // setSelectAllDaysFlag(false)
            // setLocationNameForCopyRate('')
            // setLocationIDforCopyRate('')
            // setPreloadFlag(true);
            // setWeekendRatesShowFlag(false);
            // setReverseCalculatorGrid({})
            // setReverseCalculatorErrorMessage('')

            //API call for getting data from DB
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            };

            fetch(baseURL + "/get_company_location/" + temp_company_id, requestOptions)
                .then((res) => res.json())
                .then((data) => {

                    let allTemp = data.res;
                    console.log("allTemp");
                    console.log(allTemp);
                    if (loggedInUserDetail.role === 'SuperAdmin') {
                        if (allTemp.length > 0) {
                            setLocationDetail(allTemp);
                        }
                    }
                    else if (company_type === 'Affiliate' && loggedInUserDetail.account_type.account_type === 'Corporate') {

                        if (allTemp.length > 0) {
                            setLocationDetail(allTemp);
                        }
                    }
                    else {
                        axios.post(baseURL + "/get_individual_user_details", { 'user_email': loggedInUserDetail.userEmail })
                            .then((data) => {
                                console.log("data");
                                console.log(data);
                                if (allTemp.length > 0) {
                                    allTemp = allTemp.filter((val) => val !== undefined && data.data[0].location_allocated[val.location_id]);
                                    setLocationDetail(allTemp);
                                }
                            }
                            )
                    }
                });

            fetch(baseURL + `/get_remaining_rate_code/${temp_company_id.toString()}`)
                .then((res) => res.json())
                .then((data) => {
                    if (data.length > 0) {
                        let hmap = {}
                        let arr = []
                        for (let i = 0; i < data.length; i++) {
                            let key = data[i]

                            arr.push(key)
                            hmap[key] = false
                        }
                        setListOfRateCodes(hmap)

                        let res = []
                        let size = 2

                        for (let i = 0; i < arr.length; i += size) {
                            let temp = []
                            for (let j = i; j < i + size; j++) {
                                if (arr[j] !== undefined) {
                                    temp.push(arr[j])
                                }
                                else {
                                    temp.push(null)
                                }
                            }
                            res.push(temp)
                        }
                        setTable(res)
                    }
                })

            setCarDetails([])
            setCarDetailsInOptionConstant([])
            setGridDetails({})
        }
    }, [companyName]);

    const convertToZero = (value) => {
        return value === null || value === '' ? '0' : value;
      };

    useEffect(() => {
        let temp_location_id = ''
        if (locationName && locationDetail) {
            temp_location_id = locationDetail.filter((loc) => (loc.location_name === locationName)).map((val) => val.location_id)
            setLocationId(temp_location_id);
            // setRateCodesSelected('')
            setSelectedPickUpTime('');
            setSelectedAutomationTimings(new Array(7).fill(''));
            setNumbers(numbers);
            setSelectedSippValues([]);
            setSelectedSippValues2([]);
            setSelectedSippValues3([]);
            setSelectedStrategy([]);
            setSelectedCompetitor([]);
            setSelectedChange([]);
            setSelectedAmount([]);
            setFromDate(new Date());
            setToDate(new Date());

            axios
            .get(baseURL + "/get_automation_setup_info/" + locationName)
            .then((res) => {
                console.log(res);
                let rowsData = [];
                const fixedHeight = '50px';
                const myDataSet = res.data;
                for (var index = 0; index < res.data.length; index++) {
                    let rowItem = {};
                    const val = myDataSet[index];
                    rowItem["name"] = val.location_name;
                    rowItem["start_date"] = formatDate(new Date(to_locale_string(val.from_date))) 
                    rowItem["end_date"] = formatDate(new Date(to_locale_string(val.to_date))) 
                    rowItem["pickup_time"] = val.pickup_time
                    rowItem["rate_source"] = val.rateSourceSelected;
                    rowItem["competition_by"] = val.competitionSelected;
                    rowItem["range_value"] = val.paritySelected;
                    rowItem["lors_applicable"] = Object.keys(val.lor).filter((key) => (val.lor[key] === true))
                    .map((key2, k) => {
                        if (k % 2 === 0)
                            return " " + key2;
                        else
                            return key2;
                        }).toString()
                   
                        const final = {
                            sipp_code: val.sipp_code,
                            comp_sipp_code: val.comp_sipp_code,
                            comp_sipp_code2: val.comp_sipp_code2,
                            comp_sipp_code3: val.comp_sipp_code3,
                            strategy: val.strategy.map((value) => (value === '' ? '-' : value)),
                            selected_competitor: val.selected_competitor.map((value) => (value === '' ? '-' : value)),
                            selected_change: val.selected_change.map((value) => (value === '' ? '-' : value)),
                            amount: val.amount.map((value) => (value === '' ? '-' : value)),
                          };
                          const masterValue = (
                            <table style={{ border: '1px solid black', borderCollapse: 'collapse', height: '50px !important' }}>
                              <thead>
                                <tr style={{ border: '1px solid black' }}>
                                  <th style={{ border: '1px solid black', padding: '5px', textAlign: 'center', fontWeight: 'normal' }}>SIPP Code</th>
                                  <th style={{ border: '1px solid black', padding: '30px', textAlign: 'center', fontWeight: 'normal' }}>Competing SIPP CODE Category</th>
                                  <th style={{ border: '1px solid black', padding: '5px', textAlign: 'center', fontWeight: 'normal' }}>Strategy</th>
                                  <th style={{ border: '1px solid black', padding: '5px', textAlign: 'center', fontWeight: 'normal' }}>Competitor</th>
                                  <th style={{ border: '1px solid black', padding: '5px', textAlign: 'center', fontWeight: 'normal' }}>Change</th>
                                  <th style={{ border: '1px solid black', padding: '5px', textAlign: 'center', fontWeight: 'normal' }}>Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                {final.sipp_code.map((_, index) => (
                                  <tr key={index} style={{ border: '1px solid black' }}>
                                    <td style={{ border: '1px solid black', padding: '5px', textAlign: 'center' }}>{final.sipp_code[index] || '-'}</td>
                                    <td style={{ border: '1px solid black' }}>
                                        <span style={{ display: 'inline-block', padding: '20px', textAlign: 'center' }}>
                                           {final.comp_sipp_code[index] || '-'} * {final.comp_sipp_code2[index] || '-'} * {final.comp_sipp_code3[index] || '-'}
                                        </span>
                                    </td>                                   
                                    <td style={{ border: '1px solid black', padding: '5px', textAlign: 'center' }}>{final.strategy[index] || '-'}</td>
                                    <td style={{ border: '1px solid black', padding: '5px', textAlign: 'center' }}>{final.selected_competitor[index] || '-'}</td>
                                    <td style={{ border: '1px solid black', padding: '5px', textAlign: 'center' }}>{final.selected_change[index] || '-'}</td>
                                    <td style={{ border: '1px solid black', padding: '5px', textAlign: 'center' }}>{final.amount[index] || '-'}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          );  
                    rowItem["master"] = masterValue;
                    rowItem["automation_timings"] = val.automation_timings.map((value) => (value === '' ? '-' : value)).join( ', ');
                    rowItem["created_on"] = formatDate_withTime(new Date(val.created_date))
                    // rowItem["delete"] =  accessType === 'Edit' && <span style={{ cursor: "pointer" }} onClick={() => deleteAutoSetUp(val._id)}><DeleteForeverIcon /></span>
                    rowItem["delete"] =  <span style={{ cursor: "pointer" }} onClick={() => deleteAutoSetUp(val._id)}><DeleteForeverIcon /></span>
                    rowsData.push(rowItem)
                }
                setAutomationDetails(rowsData);
                console.log("automationDetails");
                console.log(automationDetails);
                setAutomationRecordsShow('jbhf');
                const MasterHeader = ({ label }) => (
                    <div style={{ textAlign: 'center' }}>{label}</div>
                );
                
                let column_data = []
                  column_data.push([
                    {
                      label: 'Location Name',
                      field: 'name',
                      sort: 'asc',
                      width: 600
                    },
                    {
                      label: 'Automation Start Date',
                      field: 'start_date',
                      sort: 'asc',
                      width: 150
                    },
                    {
                      label: 'Automation End Date',
                      field: 'end_date',
                      sort: 'asc',
                      width: 150
                    },
                    {
                        label: 'Rate Source',
                        field: 'rate_source',
                        sort: 'asc',
                        width: 150
                    },
                    {
                        label: 'Competition By',
                        field: 'competition_by',
                        sort: 'asc',
                        width: 150
                    },
                    {
                        label: 'Range Value',
                        field: 'range_value',
                        sort: 'asc',
                        width: 150
                    },
                    {
                        label: 'PickUp Time',
                        field: 'pickup_time',
                        sort: 'asc',
                        width: 150
                    },
                    // {
                    //     label: 'Advance Days',
                    //     field: 'advance_days',
                    //     sort: 'asc',
                    //     width: 150
                    // },
                    // {
                    //     label: 'Applicable Days',
                    //     field: 'applicable_days',
                    //     sort: 'asc',
                    //     width: 150
                    // },
                    {
                        label: 'LORs Applicable',
                        field: 'lors_applicable',
                        sort: 'asc',
                        width: 150
                    },
                    {
                        label: 'Strategy Applied for the SIPP Codes',
                        field: 'master',
                        width: 1800,
                        headerAlign: 'center',
                        headerStyle: {
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        },
                        headerRenderer: ({ label }) => (
                          <div style={{ textAlign: 'center' }}>{label}</div>
                        ),
                      },
                      {
                        label: 'Automation Timings',
                        field: 'automation_timings',
                        sort: 'asc',
                        width: 150
                      },
                      {
                        label: 'Created On',
                        field: 'created_on',
                        sort: 'asc',
                        width: 150
                      },
                    //   accessType === 'Edit' &&
                      {
                        label: 'Delete',
                        field: 'delete',
                        sort: 'asc',
                        width: 150
                      },
                  ])
               
                // console.log()

                let column_data_temp = [];

                for (let k = 0; k < column_data[0].length; k++) {
                    let value_arr = column_data[0];
                    column_data_temp.push(value_arr[k]);
                }

                setColumnData(column_data[0]);
                console.log("columnData");
                console.log(columnData);
            });

            fetch(baseURL + "/get_existing_fleet_setup/" + temp_location_id)
                .then((res) => res.json())
                .then((data) => {
                    if (data.length > 0) {
                        const selectedSippCodeNameOptions = data.map((value) => ({
                            value: value.SIPP_CODE,
                            label: value.SIPP_CODE,
                        }));
                        const selectedSippCodeNamesArray = selectedSippCodeNameOptions.map(option => option.value);
                        console.log("selectedSippCodeNamesArray")
                        console.log(selectedSippCodeNamesArray)
                        setCarDetails(selectedSippCodeNamesArray);
                        setCarDetailsInOption1(selectedSippCodeNamesArray)
                        setCarDetailsInOptionConstant(selectedSippCodeNamesArray)
                    }
                    else {
                        setCarDetails([])
                        setCarDetailsInOption1([])
                        setCarDetailsInOptionConstant([])
                    }
                });

                fetch(baseURL + "/get_existing_car_classes_standard")
                .then((res) => res.json())
                .then((data) => {
                    if (data.length > 0) {
                        const standardSippCodeOptions = data.map((value) => ({
                            value: value.SIPP_CODE
                        }));
                        const standardSippCodeArray = standardSippCodeOptions.map(option => option.value);
                        setFleetStandardCarDetails(standardSippCodeArray);
                        setCarDetailsInOption2(standardSippCodeArray)
                    }
                })    

            axios
                .get(baseURL + "/get_all_competitor_setup_info/" + locationName)
                // .get(baseURL + "/another_endpoint")
                .then(response => {
                console.log("Second Axios Request Response:");
                console.log(response.data);
                if(response.data !== null){
                    const newArray = response.data.selected_items.map(subArray => subArray[0]);
                    setCompetitorsList(newArray)
                }
                })
                .catch(error => {
                console.error("Second Axios Request Error:", error);
                });
    }
    }, [locationName])

    //Option for company name
    const companyNameOptions = companyDetail.map((value) => (
        { value: value.company_name, label: value.company_name }
    ));

    const handleInputChangeCompanyName = characterEntered => {
        setCompanyName(characterEntered.value);
    };

    //Option for Location name
    const locationNameOptions = locationDetail.map((value) => (
        { value: value.location_name, label: value.location_name === undefined ? value.location_name : value.location_name + " - " + value.location_id.toString().trim().split("-")[1] }
    ));

    const rateCodeOptionsForCopyRates = Object.keys(listOfRateCodes).map((val) => (
        { value: val, label: rateCodeNames[val] }
    ));

    const handleInputChangeLocationName = characterEntered => {
        setLocationName(characterEntered.value);
    };


    return (
        <>
    
            <div className="row pt-2">
                <div className="col-12 mx-3">
                    <span style={{ color: '#1E1E1E', fontSize: '18px' }}>Select Location for Automation SetUp:</span>
                </div>
            </div>


            <div className="row pt-3 mx-2">
                <div className="col-md-10">
                    {
                        validationMsg ?
                            (<Alert severity="error">{validationMsg}!</Alert>) : ""
                    }

                    {
                        successMsg ?
                            (
                                <div className="alert alert-success alert-dismissible fade show" role="alert">
                                    {successMsg}
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setSuccessMsg('')}></button>
                                </div>
                            )
                            : ""
                    }
                    <div className="row pt-3">
                        <div className="col-md-2 mb-3">
                            <label htmlFor="company_name" className="form-label" style={{ marginTop: 5 }}>
                               <b>Account Name</b> 
                            </label>
                        </div>

                        <div className="col-md-4 mb-3">
                            <Select
                                value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                                inputId="company_name"
                                name="company_name"
                                options={companyNameOptions}
                                onChange={handleInputChangeCompanyName}
                            />
                        </div>
                    </div>


                    <div className="row pt-2">
                        <div className="col-md-2 mb-3">
                            <label htmlFor="locationName" className="form-label" style={{ marginTop: 5 }}>
                                <b>Location Name</b>
                            </label>
                        </div>

                        <div className="col-md-4 mb-3">
                            <Select
                                value={{ value: locationName, label: (locationName ? locationName : "Select Location Name") }}
                                inputId="locationName"
                                name="locationName"
                                options={locationNameOptions}
                                onChange={handleInputChangeLocationName}
                            />
                        </div>

                        <div className="col-md-1 mb-3"></div>

                        <div className="col-md-2 mb-3">
                            {/* <label htmlFor="company_id" className="form-label" style={{ marginTop: 5 }}>
                                Location ID
                            </label> */}
                        </div>

                        <div className="col-md-2 mb-3">
                            {/* <input
                                type="text"
                                className="form-control"
                                value={locationId}
                                id="locationId"
                                disabled
                            /> */}
                        </div>
                    </div>
                </div>
            </div>

            {
                carDetails.length > 0 ?
                    <>
                        <div className="row pt-2 mx-2">
                            <div className="col-md-11">
                                <div className="row">
                                    { accessType === 'View' ? "" :
                                    <>
                                    <div className="col-md-2 mb-3">
                                        <label htmlFor="fromDate" className="form-label" style={{ marginTop: 5 }}>
                                            <b>Automation Start Date</b>
                                        </label>
                                    </div>

                                    <div className="site-calendar-demo-card col-md-4 mb-3">
                                        <Calendar fullscreen={false} value={moment(fromDate.valueOf())} onChange={(value) => {
                                            setFromDate(value._d);
                                            setToDate(value._d);
                                        }}
                                            disabledDate={(current) => {
                                                if (toDate && fromDate.valueOf() !== toDate.valueOf()) {
                                                    return current && (current.valueOf() > (toDate.valueOf() + (1 * 24 * 60 * 60))) || (current.valueOf() < (new Date().valueOf() - (1000 * 24 * 60 * 60)))
                                                }
                                                else {
                                                    return current && (current.valueOf() < (new Date().valueOf() - (1 * 24 * 60 * 60)))
                                                }
                                            }}
                                        />
                                    </div>

                                    <div className="col-md-1 mb-3"></div>

                                    <div className="col-md-1 mb-3">
                                        <label htmlFor="toDate" className="form-label" style={{ marginTop: 5 }}>
                                            <b>End Date</b>
                                        </label>
                                    </div>

                                    <div className="site-calendar-demo-card col-md-4 mb-3">
                                        <Calendar fullscreen={false} value={moment(toDate.valueOf())} onChange={(value) => { setToDate(value._d) }}
                                            disabledDate={(current) => {
                                                if (fromDate) {
                                                    return current && current.valueOf() < fromDate.valueOf()
                                                }
                                                else {
                                                    return current && current.valueOf() < (new Date().valueOf() - (1 * 24 * 60 * 60))
                                                }
                                            }} />
                                    </div>
                                    <div className="row pt-2">
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="applyRatesToDays" className="form-label" style={{ marginTop: 5 }}>
                                                <b>PickUp Time    -</b>
                                            </label>
                                        </div>
                                        <div className="col-md-2 mb-3" style={{marginLeft:'-33px'}}>
                                            <table className="table table-borderless text-left">
                                                <tbody className="text-left">
                                                    <tr>
                                                    <td>
                                                    <select
                                                        value={selectedPickUpTime}
                                                        onChange={(e) => handlePickUpTime(e.target.value)}
                                                        >
                                                        <option value="">Select Time</option>
                                                        {hours.map((val) => (
                                                            <option value={val.name} key={val.name}>
                                                            {val.name}
                                                            </option>
                                                        ))}
                                                        </select>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="row pt-1">
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="applyRatesToDays" className="form-label" style={{ marginTop: 5 }}>
                                                <b>Automation Timings -</b>
                                            </label>
                                        </div>
                                        <div className="col-md-8 mb-3" style={{marginLeft:'-30px'}}>
                                            <table className="table table-borderless text-left">
                                                <tbody className="text-left">
                                                    <tr>
                                                {selectedAutomationTimings.map((time, index) => (
                                                    <td key={index}>
                                                    <select
                                                        value={time}
                                                        onChange={(e) => handleAutomationTimings(index, e.target.value)}
                                                    >
                                                        <option value="">Select a Time</option>
                                                        {hours.map((val) => (
                                                        <option value={val.name} key={val.name}>
                                                            {val.name}
                                                        </option>
                                                        ))}
                                                    </select>
                                                    </td>
                                                ))}
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="row pt-1">
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="applyRatesToDays" className="form-label" style={{ marginTop: 5 }}>
                                                <b>Lor's -</b>
                                            </label>
                                        </div>
                                        <div className="col-md-6 mb-3" style={{marginLeft:'-30px'}}>
                                            <table className="table table-borderless text-left">
                                                <tbody className="text-left">
                                                    <tr>
                                                    {/* const areAllValuesSelected = numbers && Object.values(numbers).every((value) => value); */}

                                                        {/* <td><input type='checkbox' className='form-check-input' checked={Object.values(numbers).every((value) => value)}
                                                            onChange={handleSelectAll} /></td>
                                                        <td>Select All</td> */}
                                                        <td><input type='checkbox' className='form-check-input' checked={numbers && Object.values(numbers).every((value) => value)}
                                                            onChange={handleSelectAll} /></td>
                                                        <td>Select All</td>


                                                        {Object.entries(numbers).map(([number, selected]) => (
                                                            <React.Fragment key={number}>

                                                                <td><input type='checkbox' className='form-check-input' checked={selected}
                                                                    onChange={() => handleNumberSelection(number)} /></td>
                                                                <td>{number}</td>
                                                                <td>&nbsp;&nbsp;</td>
                                                            </React.Fragment>
                                                        ))}
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="row pt-2 mx-2">
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="applyRatesToDays" className="form-label" style={{ marginTop: 5 }}>
                                                <b>Rate Source</b>
                                            </label>
                                        </div>
                                        <div className="col-md-2 mb-3 text-center"  style={{marginLeft:'0px'}}>
                                            <table className="table table-borderless text-left">
                                                <tbody className="text-left">
                                                    <tr>
                                                    <td>
                                                    <select style={{width: "75%", textAlign: "center"}}
                                                        value={selectedRateSourceOption}
                                                        onChange={(e) => handleRateSourceOption(e.target.value)}
                                                        >
                                                            {/* options={options3} */}
                                                        {/* <option value="">Select Time</option> */}
                                                        {rateSourceOptions.map((val) => (
                                                            <option value={val.value} key={val.value}>
                                                            {val.value}
                                                            </option>
                                                        ))}
                                                        </select>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="row pt-2 mx-2">
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="applyRatesToDays" className="form-label" style={{ marginTop: 5 }}>
                                                <b>Competition By</b>
                                            </label>
                                        </div>
                                        <div className="col-md-2 mb-3 text-center"  style={{marginLeft:'0px'}}>
                                            <table className="table table-borderless text-left">
                                                <tbody className="text-left">
                                                    <tr>
                                                    <td>
                                                    
                                                    <select style={{width: "75%", textAlign: "center"}}
                                                        value={selectedCompetitionOption}
                                                        onChange={(e) => handleCompetitionOption(e.target.value)}
                                                        >
                                                            {/* options={options3} */}
                                                        {/* <option value="">Select Time</option> */}
                                                        { selectedRateSourceOption === 'Expedia' ?
                                                            competitionOptions.map((val) => (
                                                                <option value={val.value} key={val.value}>
                                                                {val.value}
                                                                </option>
                                                            )) 
                                                        :
                                                        competitionOptions.map((val) => (
                                                            val.label === "SIPP CODE" && (
                                                                <option value={val.value} key={val.value}>
                                                                {val.value}
                                                                </option>
                                                            )
                                                            ))
                                                        }
                                                        </select>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="row pt-2 mx-2">
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="applyRatesToDays" className="form-label" style={{ marginTop: 5 }}>
                                                <b>Range Value Multiplier</b>
                                            </label>
                                        </div>
                                        <div className="col-md-2 mb-3 text-center"  style={{marginLeft:'0px'}}>
                                            <table className="table table-borderless text-left">
                                                <tbody className="text-left">
                                                    <tr>
                                                    <td>
                                                        <select style={{width: "75%", textAlign: "center"}}
                                                            value={selectedRateParity}
                                                            onChange={(e) => handleParity(e.target.value)}
                                                            >
                                                            {parityOptions.map((val) => (
                                                                <option value={val.value} key={val.value}>
                                                                {val.value}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="row pt-4 mx-2">
                                        <div className="col-md-12">
                                            <table className="table table-bordered table-condensed  text-center">
                                                <thead>
                                                    <tr>
                                                        <th style={{backgroundColor:'orange', border: '3px solid grey',}} colSpan="1">SIPP CODES</th>
                                                        <th style={{backgroundColor:'orange', border: '3px solid grey',}} colSpan="3">Competing SIPP CODE Category</th>
                                                        <th style={{backgroundColor:'orange', border: '3px solid grey',}} colSpan="1">Strategy</th>
                                                        <th style={{backgroundColor:'orange', border: '3px solid grey',}} colSpan="1">Select Competitor</th>
                                                        <th style={{backgroundColor:'orange', border: '3px solid grey',}} colSpan="1">Dollar / Percentage</th>
                                                        <th style={{backgroundColor:'orange', border: '3px solid grey',}} colSpan="1">Range Value</th>
                                                    </tr>
                                                </thead>
                                                <tbody style={{ backgroundColor: 'black' }}> 
                                                    {carDetails.map((item, index) => (
                                                        <tr key={index}>
                                                            <td style={{backgroundColor:'#cfe2f3', border: '3px solid grey',}} rowSpan="1">{item}</td>
                                                            <td  style={{backgroundColor:'#cfe2f3', border: '3px solid grey'}}rowSpan="1">
                                                                <select style={{textAlign: "center"}}
                                                                    value={sippValues[index]?.compSippValues || ''}
                                                                    onChange={(e) => handleComptitorSippCodes(index, e.target.value)}
                                                                >
                                                                    <option value="">Select a SIPP</option>
                                                                    {
                                                                        selectedRateSourceOption === 'Expedia' &&  selectedCompetitionOption === "Car Category" ?
                                                                                carDetailsInOption2.map((option, optionIndex) => (
                                                                                    <option key={optionIndex} value={option}>
                                                                    
                                                                                        {option}
                                                                                    </option>
                                                                                ))
                                                                        // : selectedRateSourceOption === 'Priceline' &&  selectedCompetitionOption !== "SIPP CODE" ?  
                                                                        //         carDetailsInOption1.map((option, optionIndex) => (
                                                                        //                 <option key={optionIndex} value={option}>
                                                                        //                     {option}
                                                                        //                 </option>
                                                                        //             ))
                                                                        :       carDetailsInOption2.map((option, optionIndex) => (
                                                                                    <option key={optionIndex} value={option}>
                                                                                        {option}
                                                                                    </option>
                                                                                ))
                                                                                
                                                                    }
                                                                </select>
                                                            </td>
                                                            <td  style={{backgroundColor:'#cfe2f3', border: '3px solid grey',}}rowSpan="1">
                                                                <select style={{textAlign: "center"}}
                                                                    value={sippValues2[index]?.compSippValues2 || ''}
                                                                    onChange={(e) => handleComptitorSippCodes2(index, e.target.value)}
                                                                >
                                                                    <option value="">Select a SIPP</option>
                                                                    {
                                                                        selectedRateSourceOption === 'Expedia' &&  selectedCompetitionOption === "Car Category" ?
                                                                                carDetailsInOption2.map((option, optionIndex) => (
                                                                                    <option key={optionIndex} value={option}>
                                                                                        {option}
                                                                                    </option>
                                                                                ))
                                                                        // : selectedRateSourceOption === 'Priceline' &&  selectedCompetitionOption !== "SIPP CODE" ?  
                                                                        //         carDetailsInOption1.map((option, optionIndex) => (
                                                                        //                 <option key={optionIndex} value={option}>
                                                                        //                     {option}
                                                                        //                 </option>
                                                                        //             ))
                                                                        :       carDetailsInOption2.map((option, optionIndex) => (
                                                                                    <option key={optionIndex} value={option}>
                                                                                        {option}
                                                                                    </option>
                                                                                ))
                                                                    }
                                                                    {/* {fleetStandardCarDetails.map((option, optionIndex) => (
                                                                        <option key={optionIndex} value={option[0]}>
                                                                            {option[0]}
                                                                        </option>
                                                                    ))} */}
                                                                </select>
                                                            </td>
                                                            <td style={{backgroundColor:'#cfe2f3', border: '3px solid grey',}} rowSpan="1">
                                                                <select style={{textAlign: "center"}}
                                                                    value={sippValues3[index]?.compSippValues3 || ''}
                                                                    onChange={(e) => handleComptitorSippCodes3(index, e.target.value)}
                                                                >
                                                                    <option value="">Select a SIPP</option>
                                                                    {
                                                                        selectedRateSourceOption === 'Expedia' &&  selectedCompetitionOption === "Car Category" ?
                                                                                carDetailsInOption2.map((option, optionIndex) => (
                                                                                    <option key={optionIndex} value={option}>
                                                                                        {option}
                                                                                    </option>
                                                                                ))
                                                                        // : selectedRateSourceOption === 'Priceline' &&  selectedCompetitionOption !== "SIPP CODE" ?  
                                                                        //         carDetailsInOption1.map((option, optionIndex) => (
                                                                        //                 <option key={optionIndex} value={option}>
                                                                        //                     {option}
                                                                        //                 </option>
                                                                        //             ))
                                                                        :       carDetailsInOption2.map((option, optionIndex) => (
                                                                                    <option key={optionIndex} value={option}>
                                                                                        {option}
                                                                                    </option>
                                                                                ))
                                                                    }
                                                                    {/* {fleetStandardCarDetails.map((option, optionIndex) => (
                                                                        <option key={optionIndex} value={option[0]}>
                                                                            {option[0]}
                                                                        </option>
                                                                    ))} */}
                                                                </select>
                                                            </td>
                                                            <td  style={{backgroundColor:'#cfe2f3', border: '3px solid grey',}}rowSpan="1">
                                                                <select style={{textAlign: "center"}}
                                                                    value={selectedStrategy[index]?.strategyValues || ''}
                                                                    onChange={(e) => handleStrategy(index, e.target.value)}
                                                                >
                                                                    <option value="">Select Startegy</option>
                                                                    {mainDropdownOptions.map((option, optionIndex) => (
                                                                        <option key={optionIndex} value={option}>
                                                                            {option}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </td>
                                                            {( selectedStrategy[index]?.strategyValues === 'Lowest' ||
                                                            selectedStrategy[index]?.strategyValues === 'Lowest Off-Airport' ||
                                                            selectedStrategy[index]?.strategyValues === 'Lowest In-Terminal' ||
                                                            selectedStrategy[index]?.strategyValues === 'Lowest among all the Car Classes')  && (
                                                                <>
                                                                <td style={{backgroundColor:'#cfe2f3', border: '3px solid grey',}} rowSpan="1">
                                                                </td>
                                                                <td style={{backgroundColor:'#cfe2f3', border: '3px solid grey',}} rowSpan="1">
                                                                </td>
                                                                    <td style={{backgroundColor:'#cfe2f3', border: '3px solid grey',}} rowSpan="1">
                                                                        <RangeSlider
                                                                            min="-100"
                                                                            max="100"
                                                                            step="1"
                                                                            value={selectedAmount[index]?.amountValues || '0'}
                                                                            onChange={(e) => handleAmount(index, e.target.value)}
                                                                        />
                                                                    </td>
                                                                </>
                                                            )}
                                                            {selectedStrategy[index]?.strategyValues === 'Custom' && (
                                                                <>
                                                                    <td  style={{backgroundColor:'#cfe2f3', border: '3px solid grey',}}rowSpan="1">
                                                                        <select style={{textAlign: "center"}}
                                                                            value={selectedCompetitor[index]?.competitorValues || ''}
                                                                            onChange={(e) => handleCompetitor(index, e.target.value)}
                                                                        >
                                                                            <option value="">Select Competitor</option>
                                                                            {competitorsList.map((competitor) => (
                                                                                <option key={competitor.id} value={competitor.value}>
                                                                                    {competitor.label}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </td>
                                                                    <td style={{backgroundColor:'#cfe2f3', border: '3px solid grey',}} rowSpan="1">
                                                                        <select style={{textAlign: "center"}}
                                                                            value={selectedChange[index]?.changeValues || ''}
                                                                            onChange={(e) => handleChange(index, e.target.value)}
                                                                        >
                                                                            <option value="">Select change</option>
                                                                            {changeOptions.map((option, optionIndex) => (
                                                                                <option key={optionIndex} value={option}>
                                                                                    {option}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </td>
                                                                    <td style={{backgroundColor:'#cfe2f3', border: '3px solid grey',}} rowSpan="1">
                                                                        <RangeSlider
                                                                            min="-100"
                                                                            max="100"
                                                                            step="1"
                                                                            value={selectedAmount[index]?.amountValues || '0'}
                                                                            onChange={(e) => handleAmount(index, e.target.value)}
                                                                        />
                                                                    </td>
                                                                </>
                                                            )}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className = "row">
                                        <div className="col-md-5">
                                        </div>
                                        <div className="col-md-2">
                                            <button style={{width: '150px'}} type="submit"  className="btn btn-success" onClick={handleAutomationSetUpSubmit} >Submit </button>
                                        </div>
                                    </div>
                                    </>
            }

                                    {/* {
                                        (automationRecordsShow === '' || companyName === '') ? "" :
                                        <> */}
                                            <div className="row mt-3">
                                                <div className="col-md-12">
                                                    <h5 className="mb-3">Existing Records: </h5>
                                                    <div className="text-center ml-5">
                                                    <MDBDataTable
                                                    className="custom-mdb-table"
                                                    //className="table-bordered"
                                                    bordered
                                                    large={true.toString()}
                                                    data={data}
                                                    style ={{maxHeight: '100px !important', overflow: 'auto'}}
                                                    sortable={false}
                                                    responsiveLg={true}
                                                    noBottomColumns={true}
                                                    />
                                                    </div>
                                                </div>
                                            </div>
                                        {/* </>
                                    } */}
                                </div>
                            </div>
                        </div>  
                    </> : ''
            }
        </>
    )
}