import React, { useState, useEffect, useRef } from "react";
import { Button, Alert } from "@mui/material";
import Select from "react-select";
import { Country, State, City } from "country-state-city";
import IATA from "./IATA.json";
import Swal from 'sweetalert2';
import { baseURL } from '../backend_url';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import axios from 'axios';



export default function UpdateForm() {

  var temp_company_id = "";
  var temp_no_of_location = {};

  const [companyDetail, setcompanyDetail] = useState([{}]);
  const [companyName, setCompanyName] = useState("");
  const [company_id, setcompany_id] = useState("");
  const [locationDetail, setLocationDetail] = useState([{}]);
  const [numOfLoc, setNumOfLoc] = useState(0);
  const [successMsg, setSuccessMsg] = useState("");
  const [successMsg2, setSuccessMsg2] = useState("");
  const [loading, setLoading] = useState(false);
  const [addNewClicked, setAddNewClicked] = useState(false);
  const [locationName, setLocationName] = useState('');
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [city, setCity] = useState("");
  const [airportCodeData, setAirtportCodeData] = useState([]);
  const [airportCode, setAirtportCode] = useState("");
  const [activeLocation, setActiveLocation] = useState(0);
  const [errorNumOfLoc, setErrorNumOfLoc] = useState("");
  const [submitFlag, setSubmitFlag] = useState(false);
  const [updatedFlag, setUpdatedFlag] = useState(false);
  const [maxActiveLocLimit, setMaxActiveLocLimit] = useState("");
  const [locationType, setLocationType] = useState("");
  const [locationTypeData, setLocationTypeData] = useState([]);
  const [validationAlert, setValidationAlert] = useState("");
  const [loggedInUserDetail, setLoggedInUserDetail] = useState({});

  const [updateClicked, setUpdateClicked] = useState(false);
  const [lID, setID] = useState("")

  //This is for scrolling
  const titleRef = useRef()

  function handleBackClick() {
    // titleRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {

    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);
    setLoggedInUserDetail(foundUser);
    
    const requestOptions2 = {
      method: "GET",
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios.get(baseURL + "/get_all_marketInsight_locations")
    // fetch(baseURL + '/get_all_marketInsight_locations/',requestOptions2)
      .then((res) => {
        setLocationDetail(res.data);
        // setActiveLocation(data.active_locs);
      });
  }, [updatedFlag]);


  const submitHandler = (e) => {
    console.log("clicked",loggedInUserDetail)
    e.preventDefault();
    if (locationName.toString().trim() === "") 
    {
        setValidationAlert("Please enter Location Name");
        return;
    }
    else if (airportCode === null || airportCode === '') 
    {
        setValidationAlert("GDS Code can't be empty");
        return;
    }
    else 
    {
        setLoading(true);
        //API call for updating DB data
        const requestOptions = {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            'location_name': locationName.toString().trim(),
            'airport_code': airportCode,
            'created_by': loggedInUserDetail.userEmail,
            'updated_by': loggedInUserDetail.userEmail,
        }),
        };
        fetch(baseURL + "/save_marketInsight_location", requestOptions)
        .then((res) => res.json())
        .then((data) => {
            if (data === 'Already Present') {
            Swal.fire({
                position: 'middle',
                icon: 'error',
                title: 'Location Name Already Exists',
                showConfirmButton: false,
                timer: 3000
            })
            setLoading(false);
            }
            else {
            setSubmitFlag(!submitFlag);
            setLoading(false);
            Swal.fire({
                position: 'middle',
                icon: 'success',
                title: 'A new location has been added successfully',
                showConfirmButton: false,
                timer: 3000
            })
            }
        });
    }
    setCountry(null);
    setState(null);
    setCity(null);
    setAirtportCode("");
    setLocationName("");
    setLocationType("");
    setValidationAlert("");
    // setLoading(false);

  }

  const handleUpdate = (val) => {
    const data1 = Country.getAllCountries();
    let filteredData1 = [];
    for (let item of data1) {
      if (val.country === item?.name) {
        filteredData1.push(item);
        break;
      }
    }

    const data = State.getAllStates();
    let filteredData = [];
    for (let item of data) {
      if (item?.countryCode === filteredData1[0]?.isoCode) filteredData.push(item);
    }
    setStateData(filteredData);
    const data2 = State.getAllStates();
    let filteredData2 = [];
    for (let item of data2) {
      if (val.state === item?.name) {
        filteredData2.push(item);
        break;
      }
    }
    const data3 = City.getAllCities();
    let filteredData3 = [];
    for (let item of data3) {
      if (filteredData1[0]?.isoCode === item?.countryCode &&
        filteredData2[0]?.isoCode === item?.stateCode) filteredData3.push(item);
    }
    setCityData(filteredData3);
    setAddNewClicked(false);
    setUpdateClicked(true);
    setAirtportCode(val.airport_code)
    setCity(val.city)
    setState(val.state)
    setCountry(val.country)
    setLocationName(val.location_name)
    setLocationType(val.location_type)
    setID(val._id)


  }


  const updateData1 = (e) => {

    e.preventDefault();

    if (locationName.toString().trim() == "") {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please enter Location Name',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }

    if (locationType === null || locationType === "") {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please select Location Type',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }
    else {
      //API call for updating DB data
      const requestOptions = {
        method: "POST",
        mode: 'cors',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          '_id' : lID,
          'location_name': locationName,
          'airport_code': airportCode,
          'updated_by': loggedInUserDetail.userEmail,
        }),
      };

      fetch(baseURL + "/update_marketInsight_location", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data === 'Already Present') {
            Swal.fire({
              position: 'middle',
              icon: 'error',
              title: 'Location Name Already Exists',
              showConfirmButton: false,
              timer: 3000
            })

          }
          else {
            Swal.fire({
              position: 'middle',
              icon: 'success',
              title: 'Location has been updated successfully',
              showConfirmButton: false,
              timer: 3000
            })

            setUpdateClicked(false);
            setUpdatedFlag(!updatedFlag);
            setCountry(null);
            setState(null);
            setCity(null);
            setAirtportCode("");
            setLocationName("");
            setLocationType("");
            setValidationAlert("");
          }

        });
    }
  }

  const handleLocationStatusChange = async (location_id, status, location_name) => {
    Swal.fire({
      text: 'You are changing location status of ' + location_name + ' to ' + status + '. Are you sure?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        const requestOptions = {
          method: "PUT",
          mode: 'cors',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            is_active: status,
            location_id: location_id,
            updated_by: loggedInUserDetail.userEmail,
          }),
        };
        // change_marketInsight_location_status
        fetch(baseURL + "/change_marketInsight_location_status", requestOptions)
        .then((res) => res.json())
        .then((data) => {
            setUpdatedFlag(!updatedFlag);
            Swal.fire('Status Changed to ' + status, '', 'success');
        });
        return;
      }
      else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info');
      }
    })
  }


  const handleDelete = (id) => {
    Swal.fire({
      title: 'Do you want to delete this location ?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) 
      {
        axios.delete(baseURL + "/delete_marketInsight_location/" + id)
          .then((res1) => {
            Swal.fire({
              position: 'middle',
              icon: 'success',
              title: "Deleted Successfully",
              showConfirmButton: false,
              timer: 3000
            })

            setUpdatedFlag(!updatedFlag)

          }).catch((e) => { });


      }
    })
  }



    return (
        <>
            <div className="row pt-1">
                <div className="col-md-10 mx-3">
                <Button variant="contained" style={{fontSize:'14px'}} onClick={() => { setAddNewClicked(!addNewClicked); setUpdateClicked(false); handleBackClick(); setLocationName(""); }}>Add New Location</Button>
                </div>
            </div>

            {/* Add New Location starts */}
            {addNewClicked ? 
            <>
                <div className="row pt-2" style={{fontSize:'14px'}} >
                    <div className="col-lg-10 mx-3">
                        {
                            validationAlert ?
                            <Alert severity="error">
                                {validationAlert}
                            </Alert> : ""
                        }
                        <form onSubmit={submitHandler} ref={titleRef}>
                            <h5 className=" mb-1 mt-2">Add New Location </h5>
                            <table className="table" >

                            <thead>
                                <tr>
                                    <th style={{backgroundColor:'orange'}} scope="col">Location Name*</th>
                                    <th style={{backgroundColor:'orange'}} scope="col">GDS Code</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <input
                                        type="text"
                                        className="form-control"
                                        value={locationName}
                                        onChange={(e) => setLocationName(e.target.value)}
                                        id="Location_name"

                                        />
                                    </td>
                                    <td>
                                        <input
                                        type="text"
                                        className="form-control"
                                        value={airportCode}
                                        onChange={(e) => setAirtportCode(e.target.value)}
                                        id="airport_code"

                                        />
                                    </td>
                                </tr>
                            </tbody>
                            </table>

                            <div className="row pt-2">
                            <div className="col-10 d-flex-wrap"></div>

                            <div className="col-2 d-flex justify-content-end">
                                {loading ? (
                                <button className="btn btn-success" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: 5 }}></span>
                                    Submit
                                </button>
                                ) : (
                                <button className="btn btn-success" >
                                    Submit
                                </button>
                                )}
                            </div>
                            </div>

                        </form>
                    </div>
                </div>
            </> :
            updateClicked ?
                <>
                    <div className="row pt-2" style={{fontSize:'14px'}}>
                    <div className="col-lg-10 mx-3">
                        <form ref={titleRef}>
                        <h5 className=" mb-1 mt-2" style={{fontSize:'22px'}}>Edit Location</h5>
                        <table className="table" style={{marginTop:'15px'}}>
                            <thead>
                            <tr>
                                <th style={{backgroundColor:'orange'}} scope="col">Location Name*</th>
                                <th style={{backgroundColor:'orange'}} scope="col">GDS Code</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={locationName}
                                    onChange={(e) => setLocationName(e.target.value)}
                                    id="Location_name"
                                />
                                </td>
                                <td>
                                <td>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={airportCode}
                                    onChange={(e) => setAirtportCode(e.target.value)}
                                    id="airport_code"
                                />
                                </td>
                                </td>
                            </tr>

                            </tbody>
                        </table>

                        <div className="row pt-2">
                            <div className="col-10 d-flex-wrap"></div>

                            <div className="col-2 d-flex justify-content-end">
                            {loading ? (
                                <button className="btn btn-success" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: 5, fontSize:'18px' }}></span>
                                Update
                                </button>
                            ) : (
                                <button className="btn btn-success" style= {{fontSize:'18px'}} onClick={(e) => { updateData1(e); }} >
                                Update
                                </button>
                            )}
                            </div>
                        </div>

                        </form>
                    </div>
                    </div>
                </> : ""
            }
            {/* Add New Location ends */}

            {/* existinng Location starts */}
            <div className="row pt-3" style={{fontSize:'14px'}}>
                <div className="col-md-11 mx-3 ">
                {/* <h5 className="mb-3" style={{fontSize:'14px'}}>Existing Location(s)</h5> */}
                <h5 className=" mb-1 mt-2">Existing Location(s)</h5>
                {successMsg ?
                    (
                    <div className="alert alert-success alert-dismissible fade show" role="alert">
                        {successMsg}
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setSuccessMsg('')}></button>
                    </div>
                    )
                    : ("")}
                {maxActiveLocLimit ? (
                    <Alert severity="error">
                    {maxActiveLocLimit}
                    </Alert>) : ""
                }
                <table className="table pt-2">
                    <thead>
                    <tr>
                        <th style={{backgroundColor:'orange'}} scope="col">#</th>
                        <th style={{backgroundColor:'orange'}} scope="col">Location  Name</th>
                        <th style={{backgroundColor:'orange'}} scope="col">Location ID</th>
                        <th style={{backgroundColor:'orange'}} scope="col">GDS Code</th>
                        <th style={{backgroundColor:'orange'}} scope="col">Location Status</th>
                        <th style={{backgroundColor:'orange'}} scope="col">Edit</th>
                        <th style={{backgroundColor:'orange'}} scope="col">Delete</th>
                    </tr>
                    </thead>
                    <tbody>
                    {locationDetail.length > 0 && locationDetail[0].location_name ? locationDetail.map((value, key) => (
                        <tr>
                            <th scope="row" >{key + 1}</th>
                            <td >{value.location_name}</td>
                            <td >{value.location_id}</td>
                            <td >{value.airport_code}</td>
                            <td>
                                <select className="form-select form-select-sm" value={value.is_active} onChange={(e) => handleLocationStatusChange(value.location_id, e.target.value, value.location_name)}>
                                <option value={value.is_active ? "active" : "inactive"} defaultValue>{value.is_active ? "Active" : "Inactive"}</option>
                                <option value={!value.is_active ? "active" : "inactive"} >{!value.is_active ? "Active" : "Inactive"}</option>
                                </select>
                            </td>
                            <td><span style={{ cursor: "pointer" }} onClick={() => handleUpdate(value)}><EditIcon /></span></td>
                            <td><span style={{ cursor: "pointer" }} onClick={() => handleDelete(value.location_id)}><DeleteForeverIcon /></span></td>
                        </tr>
                    )) : ""}
                    </tbody>
                </table>
                </div>
            </div>
            {/* existinng Location ends */}
            <br /><br />
        </>
    );
}