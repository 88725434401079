import React, { useState, useEffect, useRef } from 'react'
import { Alert } from "@mui/material";
import Select from "react-select";
import Swal from 'sweetalert2';
import { Calendar, Empty } from 'antd';
import './RateUpdate.css'
import Modal from 'react-bootstrap/Modal'
import { baseURL } from '../backend_url';
import axios from 'axios';
import moment from 'moment';
import { ExportToExcel } from './ExportToExcel'
import { ExportToExcelWithRates } from './ExportToExcelWithRates';
import ExcelUpload from './ExcelUpload';
import MultiRangeSlider from "multi-range-slider-react";
import RangeSlider from "react-bootstrap-range-slider";
import { isEmpty } from 'lodash';
import ProgressBar from './ProgressBar';
import './loader.css';


export default function UpdateForm({ accessType }) {

    // const baseURL3 = "http://3.127.168.176/api"
    const baseURL3 = "http://52.57.175.245/api"

    const formatDate = (date) => {
        return [
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
            date.getFullYear(),
        ].join('-');
    }

    const formatDate_Modal = (date) => {
        return [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate())
        ].join('-');
    }

    // const [isEditable, setIsEditable] = useState(true);
    const [editable, setEditable] = useState(true);
    const [error, setError] = useState(null);

    const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    }

    const [loading2, setLoading2] = useState(false); 
    const [competitorsList, setCompetitorsList] = useState([{}]);
    const [selectedPickUpTime, setSelectedPickUpTime] = useState('');
    const [selectedRateParity, setSelectedRateParity] = useState('Static');
    const [numbers, setNumbers] = useState({ "1": false, "2": false, '3': false, '4': false, '5': false, '6': false, 'Weekly': false, 'Monthly': false })
    // const mainDropdownOptions = ['Lowest', '2nd Lowest', '3rd Lowest','Lowest Off-Airport', '2nd Lowest Off-Airport', '3rd Lowest Off-Airport','Lowest In-Terminal', '2nd Lowest In-Terminal', '3rd Lowest In-Terminal','Lowest among all the Car Classes', 'Custom', 'Leave as it is'];
    const [mainDropdownOptions, setMainDropdownOptions] = useState([
        'Lowest', '2nd Lowest', '3rd Lowest',
        'Lowest Off-Airport', '2nd Lowest Off-Airport', '3rd Lowest Off-Airport',
        'Lowest In-Terminal', '2nd Lowest In-Terminal', '3rd Lowest In-Terminal',
        'Lowest among all the Car Classes', 'Custom', 'Leave as it is'
      ]);
    const [mainDropdownOptions2, setMainDropdownOptions2] = useState([
        'Lowest', '2nd Lowest', '3rd Lowest',
        'Lowest Off-Airport', '2nd Lowest Off-Airport', '3rd Lowest Off-Airport',
        'Lowest In-Terminal', '2nd Lowest In-Terminal', '3rd Lowest In-Terminal',
        'Lowest among all the Car Classes', 'Custom', 'Leave as it is'
    ]);  
    const [customOptions, setCustomOptions] = useState([])
    const changeOptions = ['Dollar ($)', 'Percentage (%)']; // Replace this with your additional dropdown options
    const [sippValues, setSelectedSippValues] = useState([]);
    const [sippValues2, setSelectedSippValues2] = useState([]);
    const [sippValues3, setSelectedSippValues3] = useState([]);
    const [selectedStrategy, setSelectedStrategy] = useState([]);
    const [selectedAmount, setSelectedAmount] = useState([]);
    const [selectedCompetitor, setSelectedCompetitor] = useState([]);
    const [selectedChange, setSelectedChange] = useState([]);
    const [timeDuration, setTimeDuration] = useState(0);
    const [selectedRateSourceOption, setSelectedRateSourceOption] = useState('Expedia');
    const [selectedCompetitionOption, setSelectedCompetitionOption] = useState('SIPP CODE');
    const [expediaOptions, setExpediaOptions]  = useState(['Economy', 'Compact','MidSize', 'Standard', 'Full-Size','Premium', 'Luxury', 'Convertible', 'Minivan', 'SUV', 'Compact SUV', 'Midsize SUV', 'Standard SUV', 'Standard Elite SUV', 'Fullsize SUV', 'Premium SUV', 'Luxury SUV', 'Special SUV', 'Van', 'Pickup', 'Sports Car', 'Other']);
    const [pricelineOptions, setPricelineOPtions]  = useState(['Car','Economy', 'Compact','MidSize', 'Standard', 'Full-Size','Premium', 'Luxury Car', 'Sports Car', 'SUV', 'Compact SUV', 'Midsize-SUV', 'Standard SUV', 'Standard Elite SUV', 'Full-Size SUV','Full-Size Elite SUV', 'Premium SUV', 'Premium Crossover', 'Luxury SUV', 'Van', 'Minivan']);
    const [companyDetail, setcompanyDetail] = useState([{}]);
    const [companyName, setCompanyName] = useState("");
    const [company_id, setcompany_id] = useState("");
    const [customStrategy, setCustomStrategy] = useState("");
    const [locationDetail, setLocationDetail] = useState([{}]);
    const [locationName, setLocationName] = useState("");
    const [locationId, setLocationId] = useState("");
    const [listOfRateCodes, setListOfRateCodes] = useState({})
    const [table, setTable] = useState([])
    const [daysNameHash, setDaysNameHash] = useState({ "Monday": false, "Tuesday": false, 'Wednesday': false, 'Thursday': false, 'Friday': false, 'Saturday': false, 'Sunday': false })
    const [carDetails, setCarDetails] = useState([])
    const [carDetailsInOption1, setCarDetailsInOption1] = useState([])
    const [carDetailsInOption2, setCarDetailsInOption2] = useState([])
    const [carDetailsInOptionConstant, setCarDetailsInOptionConstant] = useState([])
    const [fleetStandardCarDetails, setFleetStandardCarDetails] = useState([])
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [selectAllDaysFlag, setSelectAllDaysFlag] = useState(true)
    const [instantAutomationShow, setInstantAutomationShow] = useState("NO")
    const [fromDate, setFromDate] = useState(new Date())
    const [toDate, setToDate] = useState(new Date())
    const [instantFromDate, setInstantFromDate] = useState(new Date());
    const [instantToDate, setInstantToDate] = useState(new Date());
    const [dateArr, setDateArr] = useState([])
    const [selectedDatePicker, setSelectedDatePicker] = useState('')
    const [loggedInUserDetail, setLoggedInUserDetail] = useState({});
    const [rateCodeNames, setRateCodeNames] = useState({})

    const rateSourceOptions = [
        { value: "Expedia", label: "Expedia" },
        { value: "Priceline", label: "Priceline" }
      ];

    const competitionOptions = [
        { value: "SIPP CODE", label: "SIPP CODE" },
        { value: "Car Category", label: "Car Category" }
    ];  

    const parityOptions = [
        { value: "Static", label: "Static" },
        { value: "Multiple of LOR", label: "Multiple of LOR" }
    ];

    const hours = [
        { "name": "00:00" }, { "name": "00:30" }, { "name": "01:00" }, { "name": "01:30" }, { "name": "02:00" }, { "name": "02:30" }, { "name": "03:00" },
        { "name": "03:30" }, { "name": "04:00" }, { "name": "04:30" }, { "name": "05:00" }, { "name": "05:30" }, { "name": "06:00" }, { "name": "06:30" },
        { "name": "07:00" }, { "name": "07:30" }, { "name": "08:00" }, { "name": "08:30" }, { "name": "09:00" }, { "name": "09:30" }, { "name": "10:00" },
        { "name": "10:30" }, { "name": "11:00" }, { "name": "11:30" }, { "name": "12:00" }, { "name": "12:30" }, { "name": "13:00" }, { "name": "13:30" },
        { "name": "14:00" }, { "name": "14:30" }, { "name": "15:00" }, { "name": "15:30" }, { "name": "16:00" }, { "name": "16:30" }, { "name": "17:00" },
        { "name": "17:30" }, { "name": "18:00" }, { "name": "18:30" }, { "name": "19:00" }, { "name": "19:30" }, { "name": "20:00" }, { "name": "20:30" },
        { "name": "21:00" }, { "name": "21:30" }, { "name": "22:00" }, { "name": "22:30" }, { "name": "23:00" }, { "name": "23:30" }, { "name": "23:59" },
      ];
    
    const [toggleChecked, setToggleChecked] = useState(false);

    const handleToggleChange = () => {
        setToggleChecked(!toggleChecked);
        if(toggleChecked == true){
            console.log('im inside')
            setEditable(true)
            setCustomStrategy('')
        }
        console.log('toggleChecked2',toggleChecked)
    };  

    const handleSelectAll = () => {
        const areAllSelected = Object.values(numbers).every((value) => value);
        const updatedNumbers = {};

        for (const key in numbers) {
            updatedNumbers[key] = !areAllSelected;
        }
        setNumbers(updatedNumbers);
    };

    const handleRateSourceOption = (value) => {
        // setMainDropdownOptions(mainDropdownOptions2)
        axios.post(baseURL + "/get_custom_strategy_location_ota", {
            location_id: locationId,
            selectedOTA: value  // Add your second parameter here
        })
        .then(response => {
            console.log(response.data);
            if(response.data !== ''){
                console.log(response.data)
                console.log('strategyData', response.data)
                const newOptions = response.data.map(record => record.strategyName);
                // setCustomOptions(prevOptions => [...prevOptions, ...newOptions]);
                console.log('newOptions',newOptions)
                setCustomOptions(newOptions);
            }
        })
     
        if(value == "Expedia" && selectedCompetitionOption !== "SIPP CODE"){
            setCarDetailsInOption1(expediaOptions)
            setCarDetailsInOption2(expediaOptions)
        }else if(value == "Expedia" && selectedCompetitionOption !== "Car Category"){
            setCarDetailsInOption1(carDetailsInOptionConstant)
            setCarDetailsInOption2(fleetStandardCarDetails)
        }else if(value == "Priceline" && selectedCompetitionOption !== "SIPP CODE"){
            setCarDetailsInOption1(pricelineOptions)
            setCarDetailsInOption2(pricelineOptions)
        }else if(value == "Priceline" && selectedCompetitionOption !== "Car Category"){
            setCarDetailsInOption1(carDetailsInOptionConstant)
            setCarDetailsInOption2(fleetStandardCarDetails)
        }    
        setSelectedRateSourceOption(value);
    };

    const handleCompetitionOption = (value) => {
        setSelectedCompetitionOption(value);
        console.log('value',value)
        console.log('selectedRateSourceOption',selectedRateSourceOption)
        if(value == "SIPP CODE"){
            setCarDetailsInOption1(carDetailsInOptionConstant)
            setCarDetailsInOption2(fleetStandardCarDetails)
        }else if(value == "Car Category"){
            if(selectedRateSourceOption == "Expedia"){
                setCarDetailsInOption1(expediaOptions)
                setCarDetailsInOption2(expediaOptions)
            }else if (selectedRateSourceOption == "Priceline"){
                setCarDetailsInOption1(pricelineOptions)
                setCarDetailsInOption2(pricelineOptions)
                // setSelectedCompetitionOption('SIPP CODE');
            }    
        }
    };

    const handleComptitorSippCodes = (index, value) => {
        setSelectedSippValues((prevValues) => {
            const updatedValues2 = [...prevValues];
            updatedValues2[index] = { compSippValues: value };
            return updatedValues2;
        });
    };

    const handleComptitorSippCodes2 = (index, value) => {
        setSelectedSippValues2((prevValues2) => {
            const updatedValues2 = [...prevValues2];
            updatedValues2[index] = { compSippValues2: value };
            return updatedValues2;
        });
    };

    const handleComptitorSippCodes3 = (index, value) => {
        setSelectedSippValues3((prevValues3) => {
            const updatedValues2 = [...prevValues3];
            updatedValues2[index] = { compSippValues3: value };
            return updatedValues2;
        });
    };

    const handleStrategy = (index, value) => {
        setSelectedStrategy((prevValues3) => {
            const updatedValues3 = [...prevValues3];
            updatedValues3[index] = { strategyValues: value };
            return updatedValues3;
        });

        if (value != 'custom') {
            setSelectedCompetitor((prevValues4) => {
                const updatedValues4 = [...prevValues4];
                updatedValues4[index] = { competitorValues: '' };
                return updatedValues4;
            });

            setSelectedAmount((prevValues6) => {
                const updatedValues6 = [...prevValues6];
                updatedValues6[index] = { amountValues: '' };
                return updatedValues6;
            });

            setSelectedChange((prevValues5) => {
                const updatedValues5 = [...prevValues5];
                updatedValues5[index] = { changeValues: '' };
                return updatedValues5;
            });
        }
    };

    const handleAmount = (index, value) => {
        setSelectedAmount((prevValues6) => {
            const updatedValues6 = [...prevValues6];
            updatedValues6[index] = { amountValues: value };
            return updatedValues6;
        });
    };

    const handleChange = (index, value) => {
        setSelectedChange((prevValues5) => {
            const updatedValues5 = [...prevValues5];
            updatedValues5[index] = { changeValues: value };
            return updatedValues5;
        });
    };

    const handleCompetitor = (index, value) => {
        setSelectedCompetitor((prevValues4) => {
            const updatedValues4 = [...prevValues4];
            updatedValues4[index] = { competitorValues: value };
            return updatedValues4;
        });
    };   

    const handleInputChangeCompanyName = characterEntered => {
        setCompanyName(characterEntered.value);
    };

    const handleInputChangeLocationName = characterEntered => {
        setLocationName(characterEntered.value);
    };

    const handleNumberSelection = (number) => {
        setNumbers({
            ...numbers,
            [number]: !numbers[number], 
        });
    };
        
    const handlePickUpTime = (value) => {
        setSelectedPickUpTime(value);
    };
    const handleParity = (value) => {
        setSelectedRateParity(value);
    };
    const handleCustomStrategy = (value) => {
        setCustomStrategy(value);
        if(value !== ''){
            setEditable(false)
        }else{
            setEditable(true)
        }
    };
  
    // const handleAutomationSubmit = (e) => {
    const handleAutomationSubmit = async (e) => {    

        e.preventDefault();
        if (companyName === "") {
            Swal.fire('Please select Account Name');
            return;
        }
        if (locationName === "") {
            Swal.fire('Please select a Location to proceed');
            return;
        }
        const hasTrueValueForLor = Object.values(numbers).some((value) => value === true);
        if (selectedPickUpTime === "") {
            Swal.fire('Please select a PickUp Time');
            return;
        }
        if (!hasTrueValueForLor) {
            Swal.fire("Please select atleast one Lor");
            return;
        }
        
        let carDetails_d1 = []
        for(let i=0; i<carDetails.length; i++){
            
            carDetails_d1.push(carDetails[i])
        }
        console.log('sippValues',sippValues)
        let sippValues_d1 = []
        for(let i = 0; i < sippValues.length; i++) {
            if (sippValues[i] && sippValues[i].hasOwnProperty('compSippValues')) {
                sippValues_d1.push(sippValues[i]['compSippValues']);
            }else{
                sippValues_d1.push('');
            }
        }
        // for(let i=0; i<sippValues.length; i++){
        //     if(sippValues.length > 0)
        //     {
        //         sippValues_d1.push(sippValues[i]['compSippValues'])
        //     }
        // }

        let sippValues_d2 = []
        for(let i=0; i<sippValues2.length; i++){
            if(sippValues2.length > 0)
            {                 
                if (sippValues2[i] && sippValues2[i].hasOwnProperty('compSippValues2')) { 
                    if (sippValues2[i]['compSippValues2'] != '' && sippValues2[i]['compSippValues2'] != undefined) {   
                        sippValues_d2.push(sippValues2[i]['compSippValues2'])
                    }else{
                        sippValues_d2.push('empty')
                    }
                }else{
                    sippValues_d2.push('empty')
                }
            }
        }

        let sippValues_d3 = []
        for(let i=0; i<sippValues3.length; i++){
            if(sippValues3.length > 0)
            {                 
                if (sippValues3[i] && sippValues3[i].hasOwnProperty('compSippValues3')) { 
                    if (sippValues3[i]['compSippValues3'] != '' && sippValues3[i]['compSippValues3'] != undefined) {   
                        sippValues_d3.push(sippValues3[i]['compSippValues3'])
                    }else{
                        sippValues_d3.push('empty')
                    }
                }else{
                    sippValues_d3.push('empty')
                }
            }
        }

        let modifiedMinor1;
        if (selectedCompetitionOption == "Car Category" && selectedRateSourceOption == 'Expedia'){
            modifiedMinor1 = sippValues_d1.map((minorValue) =>
            expediaOptions.includes(minorValue) ? minorValue : ''
            );
        }else if (selectedCompetitionOption == "Car Category" && selectedRateSourceOption == 'Priceline'){
            modifiedMinor1 = sippValues_d1.map((minorValue) =>
            pricelineOptions.includes(minorValue) ? minorValue : ''
            );
        }else{
            modifiedMinor1 = sippValues_d1.map((minorValue) =>
            expediaOptions.includes(minorValue) ? '' : minorValue
            );
        }
        sippValues_d1 = modifiedMinor1

        let modifiedMinor2;
        if (selectedCompetitionOption == "Car Category"  && selectedRateSourceOption == 'Expedia'){
            modifiedMinor2 = sippValues_d2.map((minorValue) =>
            expediaOptions.includes(minorValue) ? minorValue : ''
            );
        }else if (selectedCompetitionOption == "Car Category" && selectedRateSourceOption == 'Priceline'){
            modifiedMinor2 = sippValues_d2.map((minorValue) =>
            pricelineOptions.includes(minorValue) ? minorValue : ''
            );
        }else{
            modifiedMinor2 = sippValues_d2.map((minorValue) =>
            expediaOptions.includes(minorValue) ? '' : minorValue
            );
        }
        sippValues_d2 = modifiedMinor2

        let modifiedMinor3;
        if (selectedCompetitionOption == "Car Category"  && selectedRateSourceOption == 'Expedia'){
            modifiedMinor3 = sippValues_d3.map((minorValue) =>
            expediaOptions.includes(minorValue) ? minorValue : ''
            );
        }else if (selectedCompetitionOption == "Car Category" && selectedRateSourceOption == 'Priceline'){
            modifiedMinor3 = sippValues_d3.map((minorValue) =>
            pricelineOptions.includes(minorValue) ? minorValue : ''
            );
        }else{
            modifiedMinor3 = sippValues_d3.map((minorValue) =>
            expediaOptions.includes(minorValue) ? '' : minorValue
            );
        }
        sippValues_d3 = modifiedMinor3
   
        let selectedStrategy_d1 = []
        let updatedStrategy;
        if (customStrategy !== ''){
              updatedStrategy = selectedStrategy.map(item => ({
                strategyValues: 'Leave as it is'
              }));
              console.log('updatedStrategy',updatedStrategy)
              
              setSelectedStrategy(updatedStrategy);
        }else{
            updatedStrategy = selectedStrategy
        }
        // return 'success'
        // for(let i=0; i<updatedStrategy.length; i++){
        //     if(updatedStrategy.length > 0)
        //     {
        //         selectedStrategy_d1.push(updatedStrategy[i]['strategyValues'])
        //     }
        // }

        for(let i = 0; i < updatedStrategy.length; i++) {
            if (updatedStrategy[i] && updatedStrategy[i].hasOwnProperty('strategyValues')) {
                selectedStrategy_d1.push(updatedStrategy[i]['strategyValues']);
            }else{
                selectedStrategy_d1.push('');
            }
        }

        let selectedCompetitor_d1 = []
        console.log('selectedCompetitor',selectedCompetitor)
        for(let i = 0; i < selectedCompetitor.length; i++) {
            if (selectedCompetitor[i] && selectedCompetitor[i].hasOwnProperty('competitorValues')) {
                selectedCompetitor_d1.push(selectedCompetitor[i]['competitorValues']);
            }else{
                selectedCompetitor_d1.push('');
            }
        }
        // for(let i=0; i<selectedCompetitor.length; i++){
        //     if(selectedCompetitor.length > 0)
        //     {
        //         selectedCompetitor_d1.push(selectedCompetitor[i]['competitorValues'])
        //     }
        // }
        console.log('selectedChange',selectedChange)

        let selectedChange_d1 = []
        for(let i = 0; i < selectedChange.length; i++) {
            if (selectedChange[i] && selectedChange[i].hasOwnProperty('changeValues')) {
                if ((selectedChange[i]['changeValues']) == ''){
                    selectedChange_d1.push('Dollar ($)');
                }else{
                    selectedChange_d1.push(selectedChange[i]['changeValues']);
                }
                
            }else{
                selectedChange_d1.push('Dollar ($)');
            }
        }
        console.log('selectedChange_d1',selectedChange_d1)
        // for(let i=0; i<selectedChange.length; i++){
        //     if(selectedChange.length > 0)
        //     {
        //         selectedChange_d1.push(selectedChange[i]['changeValues'])
        //     }
        // }

        let selectedAmount_d1 = []
        // for(let i=0; i<selectedAmount.length; i++){
        //     if(selectedAmount.length > 0)
        //     {
        //         if(selectedAmount[i]['amountValues'] == ''){
        //             selectedAmount_d1.push('0')
        //         }else{
        //             selectedAmount_d1.push(selectedAmount[i]['amountValues'])
        //         }
        //     }
        // }

        for (let i = 0; i < selectedAmount.length; i++) {
            if (selectedAmount[i] && selectedAmount[i].hasOwnProperty('amountValues')) {
                if (selectedAmount[i]['amountValues'] === '') {
                    selectedAmount_d1.push('0');
                } else {
                    selectedAmount_d1.push(selectedAmount[i]['amountValues']);
                }
            } else {
                // Handle the case where selectedAmount[i] is undefined or does not have 'amountValues'
                selectedAmount_d1.push('0'); // Or any default value you prefer
            }
        }
        let competitorCount = selectedCompetitor_d1.filter(item => item !== '').length;
        let changeCount = selectedChange_d1.filter(item => item !== '').length;
        let amountCount = selectedAmount_d1.filter(item => item !== '').length;
        const countOfCustom = selectedStrategy_d1.filter(item => item === 'Custom').length;
        const countOfStrategy = selectedStrategy_d1.filter(item => item === '').length;
        const nonEmptySippCount = sippValues_d1.filter(car => car !== '').length;   
        // console.log(carDetails) 
        // console.log(selectedStrategy_d1) 
        // console.log(countOfCustom) 
        // console.log(sippValues_d1) 
        // console.log(carDetails_d1)

        // console.log('sippValues_d1',sippValues_d1)
        // console.log('sippValues_d2',sippValues_d2)
        // console.log('sippValues_d3',sippValues_d3)
        // return 'yyyy'

        if((nonEmptySippCount != carDetails.length) || (selectedStrategy_d1.length != carDetails.length) || (countOfCustom != competitorCount) || (countOfCustom != changeCount) || (countOfCustom != amountCount) || (countOfStrategy > 0)){
            for(let i=0; i<carDetails_d1.length; i++)
            {
                if(sippValues_d1.length > 0)
                {
                    console.log(selectedStrategy_d1[i])
                    if(sippValues_d1[i] == '' || sippValues_d1[i] == undefined)
                    {
                        if (customStrategy  !== ''){
                            selectedStrategy_d1[i] = 'Leave as it is'

                        }
                        if(selectedStrategy_d1[i] === "Leave as it is"){
                            if(selectedCompetitionOption === "SIPP CODE"){
                                sippValues_d1[i] = 'CCAR';
                            }else{
                                sippValues_d1[i] = 'Mini';
                            }                            
                        }else{
                            Swal.fire("Please select Competes sipp code for "+carDetails_d1[i]+"");
                            return;
                        }
                    }    
                }else{
                       Swal.fire("Please select Competes sipp code for "+carDetails_d1[i]+" ");
                       return;
                }
                if(selectedStrategy_d1.length > 0)
                {
                    if(selectedStrategy_d1[i] == '' || selectedStrategy_d1[i] == undefined)
                    {
                        Swal.fire("Please select Strategy for "+carDetails_d1[i]+" ");
                        return;
                    } else if(selectedStrategy_d1[i] == 'Custom'){
                        if(selectedCompetitor_d1[i] == '' || selectedCompetitor_d1[i] == undefined)
                        {
                            Swal.fire("Please select Competitor for "+carDetails_d1[i]+" ");
                            return;
                        }else if(selectedChange_d1[i] == '' || selectedChange_d1[i] == undefined)
                        {
                            Swal.fire("Please select Dollar/Percentage for "+carDetails_d1[i]+" ");
                            return;
                        }else if(selectedAmount_d1[i] == '' || selectedAmount_d1[i] == undefined)
                        {
                            Swal.fire("Please select Range value for "+carDetails_d1[i]+" ");
                            return;
                        }   
                    }
                }else
                {
                        Swal.fire("Please select strategyssss for "+carDetails_d1[i]+" ");
                        return;
                }
            }
        }
        const secondArray = sippValues_d2.slice();
        const thirdArray = sippValues_d3.slice();
        carDetails_d1.forEach((value, index) => {
            if (sippValues_d2[index] ===  undefined) {
                secondArray[index] = 'empty'
            }
        });

        carDetails_d1.forEach((value, index) => {
            if (sippValues_d3[index] ===  undefined ) {
                thirdArray[index] = 'empty'
            }
        });
         
        const date1 = new Date(instantFromDate);
        const date2 = new Date(instantToDate);
        const timeDifference = date2 - date1;
        const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const finalDaysDifference = daysDifference + 1
        const trueCount = Object.values(numbers).filter(value => value === true).length;
        const timeDurationCount = (0.9*finalDaysDifference) * trueCount
        const timeDurationValue = timeDurationCount * 30000

        const truncated_comp_sipp_code1 = sippValues_d1.slice(0, carDetails_d1.length);
        const truncated_comp_sipp_code2 = secondArray.slice(0, carDetails_d1.length);
        const truncated_comp_sipp_code3 = thirdArray.slice(0, carDetails_d1.length);
        const truncated_strategy = selectedStrategy_d1.slice(0, carDetails_d1.length);
        const truncated_competitor = selectedCompetitor_d1.slice(0, carDetails_d1.length);
        const truncated_change = selectedChange_d1.slice(0, carDetails_d1.length);
        const truncated_amount = selectedAmount_d1.slice(0, carDetails_d1.length);
        let truncated_strategy2;
        let truncated_competitor2;
        let truncated_change2;
        let truncated_amount2;
        if (customStrategy !== ''){
            truncated_strategy2 = truncated_strategy.map(() => customStrategy);
            truncated_competitor2 = truncated_strategy.map(() => '');
            truncated_change2 = truncated_strategy.map(() => '');
            truncated_amount2 = truncated_strategy.map(() => '');
        }else{
            truncated_strategy2 = truncated_strategy
            truncated_competitor2 = truncated_competitor
            truncated_change2 = truncated_change
            truncated_amount2 = truncated_amount
        }
        
        const strategy_payload = {
            truncated_strategy2: customStrategy,
            rateSourceSelected: selectedRateSourceOption,

        }
        const strategyResponse = await axios.post(baseURL + "/get_strategy_info_by_name_and_ratesource", strategy_payload);
        console.log('strategyResponse',strategyResponse)
        console.log('strategyResponse2',strategyResponse.data)
        console.log('truncated_strategy2',truncated_strategy2)
        console.log('customStrategy',customStrategy)
        console.log('truncated_competitor2',truncated_competitor2)
        // return 'success'
        setLoading2(true)
        setButtonDisabled(true);
        setTimeDuration(timeDurationValue)
        const body_payload = 
        {
            location_name: locationName,
            account_id: company_id.toString(),
            location_id: locationId.toString(),
            pickup_time: selectedPickUpTime,
            rateSourceSelected: selectedRateSourceOption,
            competitionSelected: selectedCompetitionOption,
            paritySelected: selectedRateParity,
            lor: numbers,
            customStrategy:customStrategy,
            strategyResponseData : strategyResponse.data,
            sipp_code: carDetails_d1,
            comp_sipp_code: truncated_comp_sipp_code1,
            comp_sipp_code2: truncated_comp_sipp_code2,
            comp_sipp_code3: truncated_comp_sipp_code3,
            strategy: truncated_strategy2,
            selected_competitor: truncated_competitor2,
            selected_change: truncated_change2,
            amount: truncated_amount2,
            from_date:new Date(instantFromDate).toDateString(),
            to_date: new Date(instantToDate).toDateString(),
            created_by: loggedInUserDetail.userEmail,
            updated_by: loggedInUserDetail.userEmail,
            created_date: new Date(),
            updated_date: new Date()
        };
        console.log('body_payload',body_payload)
        try {
            // const res2 = await axios.post(baseURL3 + "/save_automation_instant_info_bridge", body_payload);
            const res2 =  await axios.post(baseURL + "/save_automation_instant_info", body_payload);
            setTimeDuration(1)
            setLoading2(false);
            setButtonDisabled(false);
            if (res2.data && res2.data.pStartDate) {
                const tableData = [];
                for (let i = 0; i < res2.data.pStartDate.length; i++) {
                    tableData.push({
                    pStartDate: res2.data.pStartDate[i],
                    pLor: res2.data.pLor[i],
                    pStrategy: res2.data.pStrategy[i],
                    pSippCode: res2.data.pSippCode[i],
                    pCompSippCode: res2.data.pCompSippCode[i],
                    pMinimumRate: res2.data.pMinimumRate[i],
                    pCompetitorName: res2.data.pCompetitorName[i]
                    });
                }
                Swal.fire({
                    position: "middle",
                    icon: "success",
                    title: "Automation Completed Successfully",
                    showConfirmButton: false,
                    showCloseButton:true,
                    html: `
                    <div style="height: 360px;">
                        <table border="1" style="border-collapse: collapse;">
                        <tr>
                            <th style="border: 1px solid #000;width: 120px;">Start Date</th>
                            <th style="border: 1px solid #000;width: 70px;">Lor</th>
                            <th style="border: 1px solid #000;width: 100px;">Routes Sipp Code</th>
                            <th style="border: 1px solid #000;width: 120px;">Competitor Name</th>
                            <th style="border: 1px solid #000;width: 120px;">Competitor Sipp Code</th>
                            <th style="border: 1px solid #000;width: 100px;">Strategy</th>                          
                            <th style="border: 1px solid #000;width: 150px;">Is from Minimum Rate</th>
                        </tr>
                        ${tableData.map((rowData) => `
                            <tr>
                            <td style="border: 1px solid #000;">${rowData.pStartDate}</td>
                            <td style="border: 1px solid #000;">${rowData.pLor}</td>
                            <td style="border: 1px solid #000;">${rowData.pSippCode}</td>
                            <td style="border: 1px solid #000;">${rowData.pCompetitorName}</td>
                            <td style="border: 1px solid #000;">${rowData.pCompSippCode}</td>
                            <td style="border: 1px solid #000;">${rowData.pStrategy}</td>
                            <td style="border: 1px solid #000;">${rowData.pMinimumRate}</td>
                            
                            </tr>
                        `).join('')}
                        </table>
                    </div>
                    `,
                });
            }
            else {
                Swal.fire({
                    position: "middle",
                    icon: "error",
                    title: res2.data,
                    showConfirmButton: false,
                    timer: 8000,
                    });
            }
        } catch (error) {
            setLoading2(false);
            setButtonDisabled(false);
            let errorMessage = 'An error occurred. Please try again later.';
            if (error.code === 'ERR_NETWORK') {
              errorMessage = 'Network error: Please check your internet connection and try again.';
            }
            Swal.fire({
                position: "middle",
                icon: "error",
                title: errorMessage,
                showConfirmButton: false,
                timer: 8000,
              });
      
            // if (error.code === 'ERR_NETWORK') {
            //   setError('Network error: Please check your internet connection and try again.');
            // } else {
            //   setError('An error occurred. Please try again later.');
            // }
            
            // console.error('Error:', error.message);
          }    
        //   });
    };

    useEffect(() => {
        const loggedInUser = sessionStorage.getItem("user_token");
        const foundUser = JSON.parse(loggedInUser);
        setLoggedInUserDetail(foundUser);
        if (foundUser.role === 'SuperAdmin') {
            axios.get(baseURL + "/get_company_details_for_users")
                .then((res) => {
                    setcompanyDetail(res.data);
                });
        }
        else {
            fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['InstantAutomation_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
                .then((res) => res.json())
                .then((data) => {
                    setcompanyDetail(data);
                });
        }

        fetch(baseURL + "/get_all_rate_codes_names")
            .then((res) => res.json())
            .then((data) => {
                if (Object.keys(data).length > 0) {
                    setRateCodeNames(data);
                }
                else {
                    setRateCodeNames({});
                }
            })
    }, []);

    useEffect(() => {
        if (companyDetail && companyName) {
            let obj_company_detail = companyDetail.filter(
                (value) => value.company_name === companyName
            )[0];
            let temp_company_id = obj_company_detail._id;
            let company_type = obj_company_detail.account_type;

            setcompany_id(temp_company_id);
            setLocationName('')
            setLocationId('')
            setInstantAutomationShow('NO')
            // setLocationEnabledForAutomation('No')
            setListOfRateCodes({})
            setTable([])
            setSelectAllDaysFlag(false)

            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            };

            fetch(baseURL + "/get_company_location/" + temp_company_id, requestOptions)
                .then((res) => res.json())
                .then((data) => {
                    let allTemp = data.res;
                    if (loggedInUserDetail.role === 'SuperAdmin') {
                        if (allTemp.length > 0) {
                            setLocationDetail(allTemp);
                        }
                    }
                    else if (company_type === 'Affiliate' && loggedInUserDetail.account_type.account_type === 'Corporate') {

                        if (allTemp.length > 0) {
                            setLocationDetail(allTemp);
                        }
                    }
                    else {
                        axios.post(baseURL + "/get_individual_user_details", { 'user_email': loggedInUserDetail.userEmail })
                            .then((data) => {
                                if (allTemp.length > 0) {
                                    allTemp = allTemp.filter((val) => val !== undefined && data.data[0].location_allocated[val.location_id]);
                                    setLocationDetail(allTemp);
                                }
                            }
                            )
                    }
                });
        }
    }, [companyName]);

    useEffect(() => {
        console.log('selectedStrategy', selectedStrategy);
      }, [selectedStrategy]);

    useEffect(() => {
        let temp_location_id = ''
        if (locationName && locationDetail) {
            temp_location_id = locationDetail.filter((loc) => (loc.location_name === locationName)).map((val) => val.location_id)
            setLocationId(temp_location_id);
            setMainDropdownOptions(mainDropdownOptions2)
            setInstantFromDate(new Date());
            setInstantToDate(new Date());
            setSelectedStrategy([]);
            setSelectedSippValues([]);
            setSelectedSippValues2([]);
            setSelectedSippValues3([]);
            setSelectedCompetitor([]);
            setSelectedChange([]);
            setSelectedAmount([]);
            setNumbers({ "1": false, "2": false, '3': false, '4': false, '5': false, '6': false, 'Weekly': false, 'Monthly': false});
            setSelectedPickUpTime('');
            setToggleChecked(false);
            setCustomStrategy('')
            setCustomOptions([])
            axios
            .get(baseURL + "/get_automation_enabled_info/" + locationName)
            .then((res) => {
                let automationEnabled = false
                if(res.data.length > 0){
                     automationEnabled = res.data[0]['enabled'] ;
                }
                if (automationEnabled === true){
                    // setLocationEnabledForAutomation("Yes")
                }else{
                    // setLocationEnabledForAutomation("No")
                }
            });

            fetch(baseURL + "/get_existing_fleet_setup/" + temp_location_id)
                .then((res) => res.json())
                .then((data) => {
                    if (data.length > 0) {
                        console.log(data)
                        const selectedSippCodeNameOptions = data.map((value) => ({
                            value: value.SIPP_CODE,
                            label: value.SIPP_CODE,
                        }));
                        const selectedSippCodeNamesArray = selectedSippCodeNameOptions.map(option => option.value);
                        console.log(selectedSippCodeNameOptions)
                        console.log(selectedSippCodeNamesArray)
                        setCarDetails(selectedSippCodeNamesArray);
                        setCarDetailsInOption2(selectedSippCodeNamesArray)
                        setCarDetailsInOption1(selectedSippCodeNamesArray)
                        setCarDetailsInOptionConstant(selectedSippCodeNamesArray)
                        setInstantAutomationShow('YES')
                    }
                    else {
                        setCarDetails([])
                        setCarDetailsInOption1([])
                        setCarDetailsInOptionConstant([])
                        setInstantAutomationShow('NO')
                    }
                });
            
            // fetch(baseURL + "/get_existing_car_classes_standard" )
            // .then((res) => res.json())
            // .then((data) => {
            //     if (data.length > 0) {
            //         const standardSippCodeOptions = data.map((value) => ({
            //             value: value.SIPP_CODE
            //         }));
            //         const standardSippCodeArray = standardSippCodeOptions.map(option => option.value);

            //         setFleetStandardCarDetails(standardSippCodeArray);
            //         setCarDetailsInOption2(standardSippCodeArray)
            //     }
            // })
            axios
                .get(baseURL + "/get_all_competitor_setup_info/" + locationName)
                .then(response => { 
                if(response.data !== null){
                    const newArray = response.data.selected_items.map(subArray => subArray[0]);
                    setCompetitorsList(newArray)
                }
                })
                .catch(error => {
                console.error("Second Axios Request Error:", error);
                });
            axios
            .get(baseURL + "/get_automation_instant_info/" + locationName)
            .then((res) => {
                const formattedStrategy2 = {};
                const formattedCompSipp2 = {};
                const formattedCompSipp3 = {};
                const formattedCompSipp4 = {};
                const formattedCompetitor2 = {};
                const formattedChange2 = {};
                const formattedAmount2 = {};
                if(res.data !== null){
                    if(res.data.competitionSelected === undefined || res.data.competitionSelected === null){
                        setSelectedCompetitionOption("SIPP CODE")
                        // console.log('customStrategy',customStrategy)
                        // let formattedStrategy;
                        // formattedStrategy = formattedStrategy.map(item => {
                        //     return { strategyValues: 'Leave as it is' };
                        // });
                        // setCustomStrategy(formattedStrategy)
                    }else{
                        setSelectedCompetitionOption(res.data.competitionSelected)
                    }

                    if(res.data.paritySelected === undefined){
                        setSelectedRateParity("Static")
                    }else{
                        setSelectedRateParity(res.data.paritySelected)
                    }
                    console.log('instnatResponse',res.data)
    
                    if(res.data.rateSourceSelected === undefined || res.data.rateSourceSelected === '' ){
                        res.data.rateSourceSelected = 'Expedia'
                        setSelectedRateSourceOption("Expedia")
                        setCarDetailsInOption1(expediaOptions)
                        setCarDetailsInOption2(expediaOptions)
                    }
                    setCustomOptions([])
                    setSelectedRateSourceOption(res.data.rateSourceSelected)
                    axios.post(baseURL + "/get_custom_strategy_location_ota", {
                        location_id: temp_location_id,
                        selectedOTA: res.data.rateSourceSelected  // Add your second parameter here
                    })
                    .then(response => {
                        if(response.data !== ''){
                            console.log(response.data)
                            console.log('strategyData', response.data)
                            const newOptions = response.data.map(record => record.strategyName);
                            setCustomOptions(prevOptions => [...prevOptions, ...newOptions]);
                            // setMainDropdownOptions(prevOptions => [...prevOptions, ...newOptions]);
                        }
                    })
                    
                    if(res.data.competitionSelected === "Car Category" && res.data.rateSourceSelected == 'Expedia' ){
                        setCarDetailsInOption1(expediaOptions)
                        setCarDetailsInOption2(expediaOptions)
                    }else if(res.data.competitionSelected === "Car Category" && res.data.rateSourceSelected == 'Priceline'){
                        setCarDetailsInOption1(pricelineOptions)
                        setCarDetailsInOption2(pricelineOptions)
                    }

                    res.data.strategy.forEach((item, index) => {
                        formattedStrategy2[index] = { strategyValues: item };
                    });
                    res.data.comp_sipp_code.forEach((item, index) => {
                        formattedCompSipp2[index] = { compSippValues: item };
                    });
                    if (res.data.comp_sipp_code2 && res.data.comp_sipp_code2.length > 0) {
                        res.data.comp_sipp_code2.forEach((item, index) => {
                          formattedCompSipp3[index] = { compSippValues2: item };
                        });
                        const formattedCompSipp2f = Object.keys(formattedCompSipp3).map(key => formattedCompSipp3[key]);
                        setSelectedSippValues2(formattedCompSipp2f);
                    }
                    if (res.data.comp_sipp_code3 && res.data.comp_sipp_code3.length > 0) {
                        res.data.comp_sipp_code3.forEach((item, index) => {
                            formattedCompSipp4[index] = { compSippValues3: item };
                        });
                        const formattedCompSipp3f = Object.keys(formattedCompSipp4).map(key => formattedCompSipp4[key]);
                        setSelectedSippValues3(formattedCompSipp3f);
                    }     
                    
                    res.data.selected_competitor.forEach((item, index) => {
                        formattedCompetitor2[index] = { competitorValues: item };
                    });
                    res.data.selected_change.forEach((item, index) => {
                        formattedChange2[index] = { changeValues: item };
                    });
                    res.data.amount.forEach((item, index) => {
                        formattedAmount2[index] = { amountValues: item };
                    });
                    const formattedCompSipp = Object.keys(formattedCompSipp2).map(key => formattedCompSipp2[key]);
                    let formattedStrategy = Object.keys(formattedStrategy2).map(key => formattedStrategy2[key]);
                    const formattedCompetitor = Object.keys(formattedCompetitor2).map(key => formattedCompetitor2[key]);
                    const formattedChange = Object.keys(formattedChange2).map(key => formattedChange2[key]);
                    const formattedAmount = Object.keys(formattedAmount2).map(key => formattedAmount2[key]);
                    console.log('formattedStrategy',formattedStrategy)
                    console.log('mainDropdownOptions',mainDropdownOptions)
                    setSelectedCompetitor(formattedCompetitor)

                    formattedStrategy = formattedStrategy.map(item => {
                        if (!mainDropdownOptions.includes(item.strategyValues)) {
                          return { strategyValues: 'Leave as it is' };
                        }
                        return item;
                    });
                    console.log('formattedStrategy2',formattedStrategy)
                   
                    setSelectedStrategy(formattedStrategy);
                    setSelectedSippValues(formattedCompSipp);
                    // console.log('formattedCompetitor',formattedCompetitor)
                    // setSelectedCompetitor(formattedCompetitor);
                    setSelectedChange(formattedChange);
                    setSelectedAmount(formattedAmount);
                    setInstantFromDate(res.data.from_date);
                    setSelectedPickUpTime(res.data.pickup_time);
                    setInstantToDate(res.data.to_date);
                    setNumbers(res.data.lor);
                }else{
                    console.log('selectedStrategy000',selectedStrategy)
                    let formattedStrategy = [];
                    formattedStrategy = carDetails.map(() => ({
                        strategyValues: 'Leave as it is'
                      }));
                    // formattedStrategy = carDetails.map(() => {
                    //     return { strategyValues: 'Leave as it is' };
                    // });
                    setSelectedStrategy(formattedStrategy);
                   
                    console.log('formattedStrategy', formattedStrategy)
                }
            });
            // axios
            // .get(baseURL + "/get_custom_strategy/" + temp_location_id)
            // .then(response => {
            //     if(response.data !== ''){
            //         console.log(response.data)
            //         console.log('strategyData', response.data)
            //         const newOptions = response.data.map(record => record.strategyName);
            //         setMainDropdownOptions(prevOptions => [...prevOptions, ...newOptions]);
            //         // setCarData(response.data[0]['carData'])
            //         // setListOfExistingStrategies(response.data)
            //     }
            // })
        }
    }, [locationName])

    useEffect(() => {
        console.log('carDetails', carDetails); // Log carDetails to verify it's not empty or undefined
    
        // Update the selectedStrategy based on carDetails
        if (selectedStrategy == ''){
            let formattedStrategy = []
            formattedStrategy = carDetails.map(() => ({
                strategyValues: 'Leave as it is'
            }));
            setSelectedStrategy(formattedStrategy);
            console.log('formattedStrategy', formattedStrategy);

        }
        // Log the updated formattedStrategy
        
      }, [carDetails]);

    useEffect(() => {
        if(selectedCompetitionOption == "Car Category"  && selectedRateSourceOption == 'Expedia'){
            setCarDetailsInOption1(expediaOptions)
            setCarDetailsInOption2(expediaOptions)
        }else if(selectedCompetitionOption == "Car Category"  && selectedRateSourceOption == 'Priceline'){
            setCarDetailsInOption1(pricelineOptions)
            setCarDetailsInOption2(pricelineOptions)
        }else{
            setCarDetailsInOption1(carDetailsInOptionConstant)
            setCarDetailsInOption2(fleetStandardCarDetails)
        }
      }, [carDetailsInOption1, selectedCompetitionOption]);


    //Option for company name
    const companyNameOptions = companyDetail.map((value) => (
        { value: value.company_name, label: value.company_name }
    ));

    //Option for Location name
    const locationNameOptions = locationDetail.map((value) => (
        { value: value.location_name, label: value.location_name === undefined ? value.location_name : value.location_name + " - " + value.location_id.toString().trim().split("-")[1] }
    ));

    return (
        <>
        <div className="row pt-2 mx-2" style={{fontSize:'12px', color:'black', fontWeight:'600'}}>
            <div className="col-md-2 mb-3">
                <label htmlFor="company_name" className="form-label" style={{ marginTop: 5 }}>
                    Account Name
                </label>
            </div>
            <div className="col-md-4 mb-3">
                <Select
                    value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                    inputId="company_name"
                    name="company_name"
                    options={companyNameOptions}
                    onChange={handleInputChangeCompanyName}
                />
            </div>
        </div>
        <div className="row pt-2 mx-2" style={{fontSize:'12px', color:'black', fontWeight:'600'}}>
            <div className="col-md-2 mb-3">
                <label htmlFor="locationName" className="form-label" style={{ marginTop: 5 }}>
                    Location Name
                </label>
            </div>
            <div className="col-md-4 mb-3">
                <Select
                    value={{ value: locationName, label: (locationName ? locationName : "Select Location Name") }}
                    inputId="locationName"
                    name="locationName"
                    options={locationNameOptions}
                    // isDisabled={true} 
                    onChange={handleInputChangeLocationName}
                />
            </div>
        </div>

            {
                instantAutomationShow == 'YES' ?
                    <form style={{fontSize:'12px', color:'black', fontWeight:'600'}} >
                        <div className="row pt-2 mx-2">
                            <div className="col-md-2 mb-3">
                                <label htmlFor="applyRatesToDays" className="form-label" style={{ marginTop: 5}}>
                                  PickUp Time
                                </label>
                            </div>
                            <div className="col-md-2 mb-3" style={{marginLeft:'-5px'}}>
                                <table className="table table-borderless text-left">
                                    <tbody className="text-left">
                                        <tr>
                                        <td style={{backgroundColor:'#cfe2f3'}}>
                                        <select style={{textAlign: "center"}}
                                            value={selectedPickUpTime}
                                            onChange={(e) => handlePickUpTime(e.target.value)}
                                            >
                                            <option value="">Select Time</option>
                                            {hours.map((val) => (
                                                <option value={val.name} key={val.name}>
                                                {val.name}
                                                </option>
                                            ))}
                                            </select>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row pt-2 mx-2">
                            <div className="col-md-11">
                                <div className="row">
                                    <div className="col-md-2 mb-3">
                                        <label htmlFor="fromDate" className="form-label" style={{ marginTop: 5 }}>
                                            From Date
                                        </label>
                                    </div>
                                    <div className="site-calendar-demo-card col-md-4 mb-3" style={{ marginLeft: '12px', backgroundColor:'#cfe2f3' }}>
                                        <Calendar
                                            fullscreen={false}
                                            value={moment(instantFromDate.valueOf())}
                                            style={{backgroundColor:'#cfe2f3'}}
                                            onChange={(value) => {
                                                setInstantFromDate(value._d);
                                                setInstantToDate(value._d);
                                            }}
                                            disabledDate={(current) => {
                                                if (instantToDate && instantFromDate.valueOf() !== instantToDate.valueOf()) {
                                                    return current && (current.valueOf() > (instantToDate.valueOf() + (1 * 24 * 60 * 60))) || (current.valueOf() < (new Date().valueOf() - (1000 * 24 * 60 * 60)))
                                                }
                                                else {
                                                    return current && (current.valueOf() < (new Date().valueOf() - (1 * 24 * 60 * 60)))
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-1 mb-3"></div>
                                    <div className="col-md-1 mb-3">
                                        <label htmlFor="toDate" className="form-label" style={{ marginTop: 5 }}>
                                           To Date
                                        </label>
                                    </div>
                                    <div className="site-calendar-demo-card col-md-4 mb-3" style={{ marginLeft: '-2%',backgroundColor:'#cfe2f3'}}>
                                        <Calendar
                                            fullscreen={false}
                                            value={moment(instantToDate.valueOf())}
                                            style={{backgroundColor:'#cfe2f3'}}
                                            onChange={(value) => { setInstantToDate(value._d) }}
                                            disabledDate={(current) => {
                                                if (instantFromDate) {
                                                    const startDate = moment(instantFromDate);
                                                    const endDate = moment(instantFromDate).add(6, 'days'); // Allow selection within 7 days of start date
                                                    return current && (current.valueOf() < startDate.valueOf() || current.valueOf() > endDate.valueOf());
                                                }
                                                else {
                                                    return current && current.valueOf() < (new Date().valueOf() - (1 * 24 * 60 * 60));
                                                }
                                            }} 
                                            />
                                    </div>
                                </div>
                             </div>
                        </div>
                        <div className="row pt-2 mx-2">
                            <div className="col-md-2 mb-3">
                                <label htmlFor="applyRatesToDays" className="form-label" style={{ marginTop: 5 }}>
                                    Lor's
                                </label>
                            </div>
                            <div className="col-md-8 mb-3" style={{marginLeft:'-27px'}}>
                                <table className="table table-borderless text-left">
                                    <tbody className="text-left">
                                        <tr>
                                            <td style={{backgroundColor:'#cfe2f3'}}><input type='checkbox' className='form-check-input' checked={Object.values(numbers).every((value) => value)}
                                                onChange={handleSelectAll} /></td>
                                            <td style={{backgroundColor:'#cfe2f3'}}>Select All</td>

                                            {Object.entries(numbers).map(([number, selected]) => (
                                                <React.Fragment key={number}>
                                                    <td style={{backgroundColor:'#cfe2f3'}}><input type='checkbox' className='form-check-input' checked={selected}
                                                        onChange={() => handleNumberSelection(number)} /></td>
                                                    <td style={{backgroundColor:'#cfe2f3'}}>{number}</td>
                                                    <td style={{backgroundColor:'#cfe2f3'}}>&nbsp;&nbsp;</td>
                                                </React.Fragment>
                                            ))}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row pt-2 mx-2">
                            <div className="col-md-2 mb-3">
                                <label htmlFor="applyRatesToDays" className="form-label" style={{ marginTop: 5 }}>
                                    Rate Source
                                </label>
                            </div>
                            <div className="col-md-2 mb-3 text-center"  style={{marginLeft:'-28px', width:'25%'}}>
                                <table className="table table-borderless text-left">
                                    <tbody className="text-left">
                                        <tr>
                                        <td style={{backgroundColor:'#cfe2f3'}}>
                                        <select style={{width: "75%", textAlign: "center"}}
                                            value={selectedRateSourceOption}
                                            onChange={(e) => handleRateSourceOption(e.target.value)}
                                            >
                                            {rateSourceOptions.map((val) => (
                                                <option value={val.value} key={val.value}>
                                                {val.value}
                                                </option>
                                            ))}
                                            </select>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row pt-2 mx-2">
                            <div className="col-md-2 mb-3">
                                <label htmlFor="applyRatesToDays" className="form-label" style={{ marginTop: 5 }}>
                                    Competition By
                                </label>
                            </div>
                            <div className="col-md-2 mb-3 text-center"  style={{marginLeft:'-28px',  width:'25%'}}>
                                <table className="table table-borderless text-left">
                                    <tbody className="text-left">
                                        <tr>
                                        <td style={{backgroundColor:'#cfe2f3'}}>
                                        <select style={{width: "75%", textAlign: "center"}}
                                            value={selectedCompetitionOption}
                                            onChange={(e) => handleCompetitionOption(e.target.value)}
                                            >
                                            {competitionOptions.map((val) => (
                                                <option value={val.value} key={val.value}>
                                                {val.value}
                                                </option>
                                            ))}
                                            </select>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row pt-2 mx-2">
                            <div className="col-md-2 mb-3">
                                <label htmlFor="applyRatesToDays" className="form-label" style={{ marginTop: 5 }}>
                                    Range Value Multiplier
                                </label>
                            </div>
                            <div className="col-md-2 mb-3 text-center"  style={{marginLeft:'-28px',  width:'25%'}}>
                                <table className="table table-borderless text-left">
                                    <tbody className="text-left">
                                        <tr>
                                        <td style={{backgroundColor:'#cfe2f3'}}>
                                            <select style={{width: "75%", textAlign: "center"}}
                                                value={selectedRateParity}
                                                onChange={(e) => handleParity(e.target.value)}
                                                >
                                                {parityOptions.map((val) => (
                                                    <option value={val.value} key={val.value}>
                                                    {val.value}
                                                    </option>
                                                ))}
                                            </select>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row pt-2 mx-2">
                            <div className="col-md-2 mb-3">
                                <label htmlFor="applyRatesToDays" className="form-label" style={{ marginTop: 5 }}>
                                    Use Custom Strategy
                                </label>
                            </div>
                            <div className="col-md-2 mb-3 text-center" style={{ marginLeft: '-28px', width: '25%' }}>
                                <div className="form-check form-switch">
                                    <input
                                        className="form-check-input form-check-input-lg"
                                        style={{ width: '80px', height: '40px' }}
                                        type="checkbox"
                                        id="customStrategyToggle"
                                        checked={toggleChecked}
                                        onChange={handleToggleChange}
                                    />
                                </div>
                            </div>
                            {toggleChecked && (
                                <div className="col-md-2 mb-3 text-center"  style={{marginLeft:'-205px', width:'25%'}}>
                                    <table className="table table-borderless text-left">
                                        <tbody className="text-left">
                                            <tr>
                                            <td style={{backgroundColor:'#cfe2f3'}}>
                                            <select style={{width: "75%", textAlign: "center"}}
                                                value={customStrategy}
                                                onChange={(e) => handleCustomStrategy(e.target.value)}
                                                 >
                                                <option value="">Select Startegy</option>
                                                {customOptions.map((option, optionIndex) => (
                                                    <option key={optionIndex} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                                </select>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                        {/* <div className="row pt-4 mx-2">
                            <div className="col-md-12">
                                <table className="table table-bordered table-condensed  text-center">
                                    <thead>
                                        <tr>
                                            <th style={{backgroundColor:'orange', border: '3px solid grey',}} colSpan="1">SIPP CODES</th>
                                            <th style={{backgroundColor:'orange', border: '3px solid grey',}} colSpan="3">Competing SIPP CODE Category</th>
                                            <th style={{backgroundColor:'orange', border: '3px solid grey',}} colSpan="1">Strategy</th>
                                            <th style={{backgroundColor:'orange', border: '3px solid grey',}} colSpan="1">Select Competitor</th>
                                            <th style={{backgroundColor:'orange', border: '3px solid grey',}} colSpan="1">Dollar / Percentage</th>
                                            <th style={{backgroundColor:'orange', border: '3px solid grey',}} colSpan="1">Range Value</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ backgroundColor: 'black' }}> 
                                        {carDetails.map((item, index) => (
                                            <tr key={index}>
                                                <td style={{backgroundColor:'#cfe2f3', border: '3px solid grey',}} rowSpan="1">{item}</td>
                                                <td  style={{backgroundColor:'#cfe2f3', border: '3px solid grey'}}rowSpan="1">
                                                    <select style={{textAlign: "center"}}
                                                        value={sippValues[index]?.compSippValues || ''}
                                                        onChange={(e) => handleComptitorSippCodes(index, e.target.value)}
                                                    >
                                                        <option value="">Select a SIPP</option>
                                                        {
                                                            selectedRateSourceOption === 'Expedia' &&  selectedCompetitionOption === "Car Category" ?
                                                                    carDetailsInOption2.map((option, optionIndex) => (
                                                                        <option key={optionIndex} value={option}>
                                                           
                                                                            {option}
                                                                        </option>
                                                                    ))
                                                            : selectedRateSourceOption === 'Priceline' &&  selectedCompetitionOption === "Car Category" ?  
                                                                    carDetailsInOption2.map((option, optionIndex) => (
                                                                            <option key={optionIndex} value={option}>
                                                                                {option}
                                                                            </option>
                                                                        ))
                                                            :       carDetailsInOption1.map((option, optionIndex) => (
                                                                        <option key={optionIndex} value={option}>
                                                                            {option}
                                                                        </option>
                                                                    ))
                                                                    
                                                        }
                                                    </select>
                                                </td>
                                                <td  style={{backgroundColor:'#cfe2f3', border: '3px solid grey',}}rowSpan="1">
                                                    <select style={{textAlign: "center"}}
                                                        value={sippValues2[index]?.compSippValues2 || ''}
                                                        onChange={(e) => handleComptitorSippCodes2(index, e.target.value)}
                                                    >
                                                        <option value="">Select a SIPP</option>
                                                        {
                                                            selectedRateSourceOption === 'Expedia' &&  selectedCompetitionOption === "Car Category" ?
                                                                    carDetailsInOption2.map((option, optionIndex) => (
                                                                        <option key={optionIndex} value={option}>
                                                                            {option}
                                                                        </option>
                                                                    ))
                                                            : selectedRateSourceOption === 'Priceline' &&  selectedCompetitionOption === "Car Category"  ?  
                                                            carDetailsInOption2.map((option, optionIndex) => (
                                                                            <option key={optionIndex} value={option}>
                                                                                {option}
                                                                            </option>
                                                                        ))
                                                            :       carDetailsInOption1.map((option, optionIndex) => (
                                                                        <option key={optionIndex} value={option}>
                                                                            {option}
                                                                        </option>
                                                                    ))
                                                        }
                                                    </select>
                                                </td>
                                                <td style={{backgroundColor:'#cfe2f3', border: '3px solid grey',}} rowSpan="1">
                                                    <select style={{textAlign: "center"}}
                                                        value={sippValues3[index]?.compSippValues3 || ''}
                                                        onChange={(e) => handleComptitorSippCodes3(index, e.target.value)}
                                                    >
                                                        <option value="">Select a SIPP</option>
                                                        {
                                                            selectedRateSourceOption === 'Expedia' &&  selectedCompetitionOption === "Car Category" ?
                                                                    carDetailsInOption2.map((option, optionIndex) => (
                                                                        <option key={optionIndex} value={option}>
                                                                            {option}
                                                                        </option>
                                                                    ))
                                                            : selectedRateSourceOption === 'Priceline' &&  selectedCompetitionOption === "Car Category"  ?  
                                                                    carDetailsInOption2.map((option, optionIndex) => (
                                                                            <option key={optionIndex} value={option}>
                                                                                {option}
                                                                            </option>
                                                                        ))
                                                            :       carDetailsInOption1.map((option, optionIndex) => (
                                                                        <option key={optionIndex} value={option}>
                                                                            {option}
                                                                        </option>
                                                                    ))
                                                        }
                                                    </select>
                                                </td>
                                                <td  style={{backgroundColor:'#cfe2f3', border: '3px solid grey',}}rowSpan="1">
                                                    <select style={{textAlign: "center"}}
                                                        value={selectedStrategy[index]?.strategyValues || ''}
                                                        onChange={(e) => handleStrategy(index, e.target.value)}
                                                    >
                                                        <option value="">Select Startegy</option>
                                                        {mainDropdownOptions.map((option, optionIndex) => (
                                                            <option key={optionIndex} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </td>
                                                {( selectedStrategy[index]?.strategyValues === 'Lowest' ||
                                                   selectedStrategy[index]?.strategyValues === 'Lowest Off-Airport' ||
                                                   selectedStrategy[index]?.strategyValues === 'Lowest In-Terminal' ||
                                                   selectedStrategy[index]?.strategyValues === 'Lowest among all the Car Classes')  && (
                                                    <>
                                                       <td style={{backgroundColor:'#cfe2f3', border: '3px solid grey',}} rowSpan="1">
                                                       </td>
                                                       <td style={{backgroundColor:'#cfe2f3', border: '3px solid grey',}} rowSpan="1">
                                                       </td>
                                                        <td style={{backgroundColor:'#cfe2f3', border: '3px solid grey',}} rowSpan="1">
                                                            <RangeSlider
                                                                min="-100"
                                                                max="100"
                                                                step="1"
                                                                value={selectedAmount[index]?.amountValues || '0'}
                                                                onChange={(e) => handleAmount(index, e.target.value)}
                                                            />
                                                        </td>
                                                    </>
                                                )}
                                                {selectedStrategy[index]?.strategyValues === 'Custom' && (
                                                    <>
                                                        <td  style={{backgroundColor:'#cfe2f3', border: '3px solid grey',}}rowSpan="1">
                                                            <select style={{textAlign: "center"}}
                                                                value={selectedCompetitor[index]?.competitorValues || ''}
                                                                onChange={(e) => handleCompetitor(index, e.target.value)}
                                                            >
                                                                <option value="">Select Competitor</option>
                                                                {competitorsList.map((competitor) => (
                                                                    <option key={competitor.id} value={competitor.value}>
                                                                        {competitor.label}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </td>
                                                        <td style={{backgroundColor:'#cfe2f3', border: '3px solid grey',}} rowSpan="1">
                                                            <select style={{textAlign: "center"}}
                                                                value={selectedChange[index]?.changeValues || ''}
                                                                onChange={(e) => handleChange(index, e.target.value)}
                                                            >
                                                                <option value="">Select change</option>
                                                                {changeOptions.map((option, optionIndex) => (
                                                                    <option key={optionIndex} value={option}>
                                                                        {option}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </td>
                                                        <td style={{backgroundColor:'#cfe2f3', border: '3px solid grey',}} rowSpan="1">
                                                            <RangeSlider
                                                                min="-100"
                                                                max="100"
                                                                step="1"
                                                                value={selectedAmount[index]?.amountValues || '0'}
                                                                onChange={(e) => handleAmount(index, e.target.value)}
                                                            />
                                                        </td>
                                                    </>
                                                )}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div> */}
                        <div className={`row pt-4 mx-2`}>
                            <div className="col-md-12">
                                <table className="table table-bordered table-condensed text-center">
                                <thead>
                                    <tr>
                                    <th style={{backgroundColor:'orange', border: '3px solid grey'}} colSpan="1">SIPP CODES</th>
                                    <th style={{backgroundColor:'orange', border: '3px solid grey'}} colSpan="3">Competing SIPP CODE Category</th>
                                    <th style={{backgroundColor:'orange', border: '3px solid grey'}} colSpan="1">Strategy</th>
                                    <th style={{backgroundColor:'orange', border: '3px solid grey'}} colSpan="1">Select Competitor</th>
                                    <th style={{backgroundColor:'orange', border: '3px solid grey'}} colSpan="1">Dollar / Percentage</th>
                                    <th style={{backgroundColor:'orange', border: '3px solid grey'}} colSpan="1">Range Value</th>
                                    </tr>
                                </thead>
                                <tbody style={{ backgroundColor: 'black' }}> 
                                    {carDetails.map((item, index) => (
                                    <tr key={index}>
                                        <td style={{backgroundColor:'#cfe2f3', border: '3px solid grey'}} rowSpan="1">{item}</td>
                                        <td style={{backgroundColor:'#cfe2f3', border: '3px solid grey'}} rowSpan="1">
                                        <select style={{textAlign: "center"}}
                                            value={sippValues[index]?.compSippValues || ''}
                                            onChange={(e) => handleComptitorSippCodes(index, e.target.value)}
                                            disabled={!editable} // Disable select when not editable
                                        >
                                            <option value="">Select a SIPP</option>
                                            {selectedRateSourceOption === 'Expedia' &&  selectedCompetitionOption === "Car Category" ?
                                            carDetailsInOption2.map((option, optionIndex) => (
                                                <option key={optionIndex} value={option}>
                                                {option}
                                                </option>
                                            ))
                                            : selectedRateSourceOption === 'Priceline' &&  selectedCompetitionOption === "Car Category" ?  
                                            carDetailsInOption2.map((option, optionIndex) => (
                                                <option key={optionIndex} value={option}>
                                                {option}
                                                </option>
                                            ))
                                            : carDetailsInOption1.map((option, optionIndex) => (
                                                <option key={optionIndex} value={option}>
                                                {option}
                                                </option>
                                            ))
                                            }
                                        </select>
                                        </td>
                                        <td style={{backgroundColor:'#cfe2f3', border: '3px solid grey'}} rowSpan="1">
                                        <select style={{textAlign: "center"}}
                                            value={sippValues2[index]?.compSippValues2 || ''}
                                            onChange={(e) => handleComptitorSippCodes2(index, e.target.value)}
                                            disabled={!editable} // Disable select when not editable
                                        >
                                            <option value="">Select a SIPP</option>
                                            {selectedRateSourceOption === 'Expedia' &&  selectedCompetitionOption === "Car Category" ?
                                            carDetailsInOption2.map((option, optionIndex) => (
                                                <option key={optionIndex} value={option}>
                                                {option}
                                                </option>
                                            ))
                                            : selectedRateSourceOption === 'Priceline' &&  selectedCompetitionOption === "Car Category"  ?  
                                            carDetailsInOption2.map((option, optionIndex) => (
                                                <option key={optionIndex} value={option}>
                                                {option}
                                                </option>
                                            ))
                                            : carDetailsInOption1.map((option, optionIndex) => (
                                                <option key={optionIndex} value={option}>
                                                {option}
                                                </option>
                                            ))
                                            }
                                        </select>
                                        </td>
                                        <td style={{backgroundColor:'#cfe2f3', border: '3px solid grey'}} rowSpan="1">
                                        <select style={{textAlign: "center"}}
                                            value={sippValues3[index]?.compSippValues3 || ''}
                                            onChange={(e) => handleComptitorSippCodes3(index, e.target.value)}
                                            disabled={!editable} // Disable select when not editable
                                        >
                                            <option value="">Select a SIPP</option>
                                            {selectedRateSourceOption === 'Expedia' &&  selectedCompetitionOption === "Car Category" ?
                                            carDetailsInOption2.map((option, optionIndex) => (
                                                <option key={optionIndex} value={option}>
                                                {option}
                                                </option>
                                            ))
                                            : selectedRateSourceOption === 'Priceline' &&  selectedCompetitionOption === "Car Category"  ?  
                                            carDetailsInOption2.map((option, optionIndex) => (
                                                <option key={optionIndex} value={option}>
                                                {option}
                                                </option>
                                            ))
                                            : carDetailsInOption1.map((option, optionIndex) => (
                                                <option key={optionIndex} value={option}>
                                                {option}
                                                </option>
                                            ))
                                            }
                                        </select>
                                        </td>
                                        <td style={{backgroundColor:'#cfe2f3', border: '3px solid grey'}} rowSpan="1">
                                        <select style={{textAlign: "center"}}
                                            value={selectedStrategy[index]?.strategyValues || ''}
                                            onChange={(e) => handleStrategy(index, e.target.value)}
                                            disabled={!editable} // Disable select when not editable
                                        >
                                            <option value="">Select Startegy</option>
                                            {mainDropdownOptions.map((option, optionIndex) => (
                                            <option key={optionIndex} value={option}>
                                                {option}
                                            </option>
                                            ))}
                                        </select>
                                        </td>
                                        {(
                                            selectedStrategy[index]?.strategyValues === 'Lowest' ||
                                            selectedStrategy[index]?.strategyValues === 'Lowest Off-Airport' ||
                                            selectedStrategy[index]?.strategyValues === 'Lowest In-Terminal' ||
                                            selectedStrategy[index]?.strategyValues === '2nd Lowest Off-Airport' ||
                                            selectedStrategy[index]?.strategyValues === '3rd Lowest Off-Airport' ||
                                            selectedStrategy[index]?.strategyValues === '2nd Lowest In-Terminal' ||
                                            selectedStrategy[index]?.strategyValues === '3rd Lowest In-Terminal' ||
                                            selectedStrategy[index]?.strategyValues === '2nd Lowest' ||
                                            selectedStrategy[index]?.strategyValues === '3rd Lowest' ||
                                            selectedStrategy[index]?.strategyValues === 'Lowest among all the Car Classes') && (
                                            <>
                                            <td style={{backgroundColor:'#cfe2f3', border: '3px solid grey'}} rowSpan="1">
                                            </td>
                                            {/* <td style={{backgroundColor:'#cfe2f3', border: '3px solid grey'}} rowSpan="1">
                                            </td> */}
                                            {/* <td style={{width:'10%'}} rowSpan="1">
                                                <select style={{textAlign: "center"}}
                                                    value={selectedChange[index]?.changeValues || ''}
                                                    onChange={(e) => handleChange(index, e.target.value)}
                                                >
                                                    {changeOptions.map((option, optionIndex) => (
                                                        <option key={optionIndex} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>
                                            </td> */}
                                            <td style={{backgroundColor:'#cfe2f3', border: '3px solid grey'}} rowSpan="1">
                                                <select style={{textAlign: "center"}}
                                                    value={selectedChange[index]?.changeValues || ''}
                                                    onChange={(e) => handleChange(index, e.target.value)}
                                                    disabled={!editable} // Disable select when not editable
                                                >
                                                    {/* <option value="">Select change</option> */}
                                                    {changeOptions.map((option, optionIndex) => (
                                                    <option key={optionIndex} value={option}>
                                                        {option}
                                                    </option>
                                                    ))}
                                                </select>
                                            </td>
                                            <td style={{backgroundColor:'#cfe2f3', border: '3px solid grey'}} rowSpan="1">
                                                <RangeSlider
                                                min="-30"
                                                max="30"
                                                step="1"
                                                value={selectedAmount[index]?.amountValues || '0'}
                                                onChange={(e) => handleAmount(index, e.target.value)}
                                                disabled={!editable} // Disable slider when not editable
                                                />
                                            </td>
                                            </>
                                        )}
                                        {selectedStrategy[index]?.strategyValues === 'Custom' && (
                                        <>
                                            <td style={{backgroundColor:'#cfe2f3', border: '3px solid grey'}} rowSpan="1">
                                            <select style={{textAlign: "center"}}
                                                value={selectedCompetitor[index]?.competitorValues || ''}
                                                onChange={(e) => handleCompetitor(index, e.target.value)}
                                                disabled={!editable} // Disable select when not editable
                                            >
                                                <option value="">Select Competitor</option>
                                                {competitorsList.map((competitor) => (
                                                <option key={competitor.id} value={competitor.value}>
                                                    {competitor.label}
                                                </option>
                                                ))}
                                            </select>
                                            </td>
                                            <td style={{backgroundColor:'#cfe2f3', border: '3px solid grey'}} rowSpan="1">
                                            <select style={{textAlign: "center"}}
                                                value={selectedChange[index]?.changeValues || ''}
                                                onChange={(e) => handleChange(index, e.target.value)}
                                                disabled={!editable} // Disable select when not editable
                                            >
                                                {/* <option value="">Select change</option> */}
                                                {changeOptions.map((option, optionIndex) => (
                                                <option key={optionIndex} value={option}>
                                                    {option}
                                                </option>
                                                ))}
                                            </select>
                                            </td>
                                            <td style={{backgroundColor:'#cfe2f3', border: '3px solid grey'}} rowSpan="1">
                                            <RangeSlider
                                                min="-30"
                                                max="30"
                                                step="1"
                                                value={selectedAmount[index]?.amountValues || '0'}
                                                onChange={(e) => handleAmount(index, e.target.value)}
                                                disabled={!editable} // Disable slider when not editable
                                            />
                                            </td>
                                        </>
                                        )}
                                    </tr>
                                    ))}
                                </tbody>
                                </table>
                            </div>
                            </div>
                        <div className = "row">
                        <div className="col-md-5">
                        {loading2 ? (
                            <div className="overlay">
                                <div className="loading-spinner-container">
                                <ProgressBar duration={timeDuration} />
                                
                                <div className="loading-text">
                                    <p><b>Please wait - Rate Automation is in Progress. Please do not click outside or close this screen in the meantime</b></p>
                                </div>
                            </div>
                            </div>
                            ) 
                            : ("")
                            }
                        </div>
                        { accessType === 'View' ? "" :
                            <>
                                <div className="col-md-2">
                                    <button style={{width: '150px'}} type="submit" disabled={buttonDisabled}  className="btn btn-success" onClick={handleAutomationSubmit} >Submit </button>
                                </div>
                            </>
                        }
                        </div>
                    </form> : ''
            }
        </>
    )
}    